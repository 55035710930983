import React from "react";
import {Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {CategoryTableInterface, CategoryVariables, UniqueCategoryTableInfo} from "../../../interfaces/interfaces";
import CategoryComponent from "./CategoryComponent/CategoryComponent";

type CategoryTableProps = {
    hasAccess : boolean,
    categoriesToShow : CategoryTableInterface[]
    categoryList : CategoryTableInterface[]
    onCreate : (categoryVariables : CategoryVariables) => Promise<void>
    onUpdate : (categoryVariables : CategoryVariables, isRestored? : boolean) => Promise<void>
    onDelete : (categoryVariables : CategoryVariables) => Promise<void>
    nameOfQtyColum : string,
    searchParamBool : boolean,
    uniqueInfo : UniqueCategoryTableInfo
}

const CategoryTable : React.FC<CategoryTableProps> = (props) => {
    const {t: translate} = useTranslation();
  return (
      <div className="overflow-x-auto overflow-y-hidden scrollbar">
          <div className={"d-flex table-header-background table-category-width py-3 ps-4"}>
              <Col xs={3}>
                  {translate('servicesPage.categoryOrSubcategory')}
              </Col>
              <Col xs={5}>
                  {translate('description')}
              </Col>
              <Col xs={2}>
                  {props.nameOfQtyColum}
              </Col>
              <Col xs={2}></Col>
          </div>
          {(props.categoriesToShow && props.categoriesToShow.length !==0) && (
              props.categoriesToShow.map((category) => {
                  return <CategoryComponent
                      key={category.id}
                      category={category}
                      categoryList={props.categoryList}
                      hasAccess={props.hasAccess}
                      onCreate={props.onCreate}
                      onUpdate={props.onUpdate}
                      onDelete={props.onDelete}
                      searchParamBool={props.searchParamBool}
                      uniqueInfo={props.uniqueInfo}
                      />
              })
          ) }
      </div>
  )
}

export default React.memo(CategoryTable)