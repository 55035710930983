/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetTechMapCategoriesDocument = gql`
    query getTechMapCategories($id: Int, $categoryName: String, $itemsPerPage: Int, $serviceDeleted: Boolean, $page: Int) {
  TechMapCategories(
    id: $id
    categoryName: $categoryName
    itemsPerPage: $itemsPerPage
    page: $page
  ) {
    itemsPerPage
    currentPage
    pageCount
    totalCount
    items {
      id
      name
      comment
      deleted
      company {
        id
        name
        address
        phone
        website
        deleted
        bookingQrLink
        services {
          pageCount
        }
        isPremiumOn
        paidFeaturesPaginated {
          itemsPerPage
        }
      }
      techMaps(deleted: $serviceDeleted) {
        id
        name
        deleted
      }
      childCategories(name: $categoryName) {
        id
        name
        comment
        deleted
        company {
          id
          name
          address
          phone
          website
          deleted
          bookingQrLink
          isPremiumOn
          services {
            pageCount
          }
          paidFeaturesPaginated {
            itemsPerPage
          }
        }
        techMaps(deleted: $serviceDeleted) {
          id
          name
          deleted
        }
        parentCategory {
          id
          name
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useGetTechMapCategoriesQuery__
 *
 * To run a query within a React component, call `useGetTechMapCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechMapCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechMapCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      categoryName: // value for 'categoryName'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      serviceDeleted: // value for 'serviceDeleted'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTechMapCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetTechMapCategoriesQuery, GetTechMapCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTechMapCategoriesQuery, GetTechMapCategoriesQueryVariables>(GetTechMapCategoriesDocument, options);
      }
export function useGetTechMapCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTechMapCategoriesQuery, GetTechMapCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTechMapCategoriesQuery, GetTechMapCategoriesQueryVariables>(GetTechMapCategoriesDocument, options);
        }
export function useGetTechMapCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTechMapCategoriesQuery, GetTechMapCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTechMapCategoriesQuery, GetTechMapCategoriesQueryVariables>(GetTechMapCategoriesDocument, options);
        }
export type GetTechMapCategoriesQueryHookResult = ReturnType<typeof useGetTechMapCategoriesQuery>;
export type GetTechMapCategoriesLazyQueryHookResult = ReturnType<typeof useGetTechMapCategoriesLazyQuery>;
export type GetTechMapCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetTechMapCategoriesSuspenseQuery>;
export type GetTechMapCategoriesQueryResult = Apollo.QueryResult<GetTechMapCategoriesQuery, GetTechMapCategoriesQueryVariables>;
export const UpdateTechMapCategoryInTableDocument = gql`
    mutation UpdateTechMapCategoryInTable($id: Int!, $input: TechMapCategoryNewInput!) {
  updateTechMapCategory(id: $id, input: $input) {
    id
    name
    deleted
  }
}
    `;
export type UpdateTechMapCategoryInTableMutationFn = Apollo.MutationFunction<UpdateTechMapCategoryInTableMutation, UpdateTechMapCategoryInTableMutationVariables>;

/**
 * __useUpdateTechMapCategoryInTableMutation__
 *
 * To run a mutation, you first call `useUpdateTechMapCategoryInTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTechMapCategoryInTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTechMapCategoryInTableMutation, { data, loading, error }] = useUpdateTechMapCategoryInTableMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTechMapCategoryInTableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTechMapCategoryInTableMutation, UpdateTechMapCategoryInTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTechMapCategoryInTableMutation, UpdateTechMapCategoryInTableMutationVariables>(UpdateTechMapCategoryInTableDocument, options);
      }
export type UpdateTechMapCategoryInTableMutationHookResult = ReturnType<typeof useUpdateTechMapCategoryInTableMutation>;
export type UpdateTechMapCategoryInTableMutationResult = Apollo.MutationResult<UpdateTechMapCategoryInTableMutation>;
export type UpdateTechMapCategoryInTableMutationOptions = Apollo.BaseMutationOptions<UpdateTechMapCategoryInTableMutation, UpdateTechMapCategoryInTableMutationVariables>;
export const CreateTechMapCategoryInTableDocument = gql`
    mutation CreateTechMapCategoryInTable($companyId: Int!, $input: TechMapCategoryNewInput!) {
  createTechMapCategory(companyId: $companyId, input: $input) {
    id
    name
    deleted
  }
}
    `;
export type CreateTechMapCategoryInTableMutationFn = Apollo.MutationFunction<CreateTechMapCategoryInTableMutation, CreateTechMapCategoryInTableMutationVariables>;

/**
 * __useCreateTechMapCategoryInTableMutation__
 *
 * To run a mutation, you first call `useCreateTechMapCategoryInTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTechMapCategoryInTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTechMapCategoryInTableMutation, { data, loading, error }] = useCreateTechMapCategoryInTableMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTechMapCategoryInTableMutation(baseOptions?: Apollo.MutationHookOptions<CreateTechMapCategoryInTableMutation, CreateTechMapCategoryInTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTechMapCategoryInTableMutation, CreateTechMapCategoryInTableMutationVariables>(CreateTechMapCategoryInTableDocument, options);
      }
export type CreateTechMapCategoryInTableMutationHookResult = ReturnType<typeof useCreateTechMapCategoryInTableMutation>;
export type CreateTechMapCategoryInTableMutationResult = Apollo.MutationResult<CreateTechMapCategoryInTableMutation>;
export type CreateTechMapCategoryInTableMutationOptions = Apollo.BaseMutationOptions<CreateTechMapCategoryInTableMutation, CreateTechMapCategoryInTableMutationVariables>;
export type GetTechMapCategoriesQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  categoryName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  serviceDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTechMapCategoriesQuery = { __typename?: 'Query', TechMapCategories?: { __typename?: 'TechMapCategoryNewPaginated', itemsPerPage?: number | null, currentPage?: number | null, pageCount?: string | null, totalCount?: number | null, items?: Array<{ __typename?: 'TechMapCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, deleted: boolean, bookingQrLink: string, isPremiumOn: boolean, services?: { __typename?: 'ServicePaginated', pageCount?: string | null } | null, paidFeaturesPaginated: { __typename?: 'PaidFeaturePaginated', itemsPerPage?: number | null } } | null, techMaps?: Array<{ __typename?: 'TechnologicalMapProxy', id: number, name: string, deleted: boolean }> | null, childCategories?: Array<{ __typename?: 'TechMapCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, deleted: boolean, bookingQrLink: string, isPremiumOn: boolean, services?: { __typename?: 'ServicePaginated', pageCount?: string | null } | null, paidFeaturesPaginated: { __typename?: 'PaidFeaturePaginated', itemsPerPage?: number | null } } | null, techMaps?: Array<{ __typename?: 'TechnologicalMapProxy', id: number, name: string, deleted: boolean }> | null, parentCategory?: { __typename?: 'TechMapCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null> | null }> | null } | null };

export type UpdateTechMapCategoryInTableMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.TechMapCategoryNewInput;
}>;


export type UpdateTechMapCategoryInTableMutation = { __typename?: 'Mutation', updateTechMapCategory: { __typename?: 'TechMapCategoryNewProxy', id: number, name: string, deleted: boolean } };

export type CreateTechMapCategoryInTableMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.TechMapCategoryNewInput;
}>;


export type CreateTechMapCategoryInTableMutation = { __typename?: 'Mutation', createTechMapCategory: { __typename?: 'TechMapCategoryNewProxy', id: number, name: string, deleted: boolean } };
