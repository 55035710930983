import {ErrorMessage, Field, FieldProps, useField} from 'formik';
import React, {useEffect, useState} from 'react';
import {Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

interface TextInputProps {
  label: string;
  placeholder: string;
  name: string;
  id: string;
  isDisabled?: boolean;
}

const TextInputWithCounter: React.FC<TextInputProps> = ({
                                                          label,
                                                          placeholder,
                                                          name,
                                                          id,
                                                          isDisabled = false
                                                        }) => {
  const {t} = useTranslation();
  const [field, meta, helpers] = useField(name);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setCharCount(field.value?.length || 0);
  }, [field.value]);

  const normalizeInputValue = (value: string): string => {
    return value.trim().replace(/\s+/g, ' ');
  };

  const handleBlur = async () => {
    const normalizedValue = normalizeInputValue(field.value);
    await helpers.setValue(normalizedValue);
    await helpers.setTouched(true, true);
  };

  return (
    <>
      <Col
        md={3}
        className='mb-2 mb-md-0'>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold pt-2'>
          {t(label)}&nbsp;<span className='text-danger'>*</span>
        </label>
      </Col>
      <Col md={9}>
        <div className='position-relative'>
          <Field name={name}>
            {({field, meta}: FieldProps) => (
              <>
                <input
                  {...field}
                  type='text'
                  id={id}
                  placeholder={t(placeholder)}
                  className={`form-control text-dark ${meta.touched && meta.error ? 'is-invalid' : ''}`}
                  onBlur={handleBlur}
                  maxLength={50}
                  disabled={isDisabled}
                />
              </>
            )}
          </Field>
          <ErrorMessage name={name}>
            {msg => <div className='invalid-feedback'>{msg}</div>}
          </ErrorMessage>
        </div>
        <span
          id='countSymbols'
          className='fs-7 text-grey'>
          {charCount}/50
        </span>
      </Col>
    </>
  );
};

export default TextInputWithCounter;
