import React, {useEffect, useState} from "react";
import { Modal, Button, Row, Col, Form as FormCh } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { useTranslation } from "react-i18next";
import { ReactSelect } from "@services/ui-components";
import { WearhousesModalProps } from "../../Warehouse/modals/modalsUtils";
import {
    useMovementProductBetweenStoragesMutation, useUpdateStorageProductRelationMutation,
} from "@me-team/host/main/warehouses_goods/graphql/warehouses_goods.hooks";
import * as Yup from 'yup';
import {useGetUnitsQuery} from "@me-team/host/main/products/graphql/products.hooks";
import QuantityInput from "../../AddNewProductList/AddProductListModal/QuantityInput";
import {StorageProductRelationActionType} from "@me-team/host/main/graphql/types";
interface Option {
    value: string | number | string[];
    label: string;
}
const MoveGoodsModal: React.FC<WearhousesModalProps> = ({
                                                            show,
                                                            handleModal,
                                                            refetch,
                                                            storages,
                                                            warehouse,
                                                            chosenProduct,
                                                            warehouses,
                                                            storageId,
                                                            toggleShowToast
                                                        }) => {
    const { t } = useTranslation();
    const isDisabled = warehouse?.default;
    const initialValues = {
        action: StorageProductRelationActionType?.Movement,
        destinationStorage: '',
        quantity: '',
        unitId: '',
        moveAll: false
    };
    const [unitOfMeasurements, setUnitOfMeasurements] = useState([])
    const {data: unitsData} = useGetUnitsQuery({
        context: {
            errorType: 'local'
        },
    })
    useEffect(() => {
        if (chosenProduct) {
            const optionsUnitOfMeasurements = unitsData?.relations?.units
                ?.filter((unit) => {
                    const unitGroupId = chosenProduct?.product?.unitGroup?.id;
                    const alternativeUnitGroupId = chosenProduct?.product?.alternativeUnit?.unitGroup?.id;
                    return unit?.unitGroup?.id === unitGroupId ||
                        (alternativeUnitGroupId && unit?.unitGroup?.id === alternativeUnitGroupId);
                })
                ?.map((unit) => ({
                    value: unit.id,
                    label: unit.name,
                }));
            setUnitOfMeasurements(optionsUnitOfMeasurements);
        }
    }, [chosenProduct]);
    const validationSchema = () => Yup.object().shape({
        destinationStorage: Yup.string()
            .required(t('Fill this field')),
        quantity: Yup.number().when('moveAll', {
            is: false,
            then: (schema) => {
                if (chosenProduct?.quantity === 0) {
                    return schema.notRequired();
                }
                return schema.required(t('Fill this field'));
            },
            otherwise: (schema) => schema.notRequired(),
        }),
        unitId: Yup.string().when('moveAll', {
            is: false,
            then: (schema) => {
                if (chosenProduct?.quantity === 0) {
                    return schema.notRequired();
                }
                return schema.required(t('Fill this field'));
            },
            otherwise: (schema) => schema.notRequired(),
        }),
    });

    const [movementProductBetweenStorages] = useMovementProductBetweenStoragesMutation({
        context: {
            errorType: 'local',
        },
    });
    const [updateStorageProductRelation] = useUpdateStorageProductRelationMutation({
        context: {
            errorType: 'local',
        },
    })
    const handleSubmit = async (
        values: typeof initialValues,
        { setFieldError }: FormikHelpers<typeof initialValues>
    ) => {
        try {

            const variables = {
                action: StorageProductRelationActionType.Movement,
                storageProductRelationId: +chosenProduct.id,
                destinationStorage: +values.destinationStorage,
                quantity: +values.quantity,
                unitId: +values.unitId
            };
            if (values.moveAll || chosenProduct?.quantity === 0) {
                variables.quantity = +chosenProduct?.quantity;
                variables.unitId = chosenProduct?.product?.unit?.id;
            }
            const { moveAll, unitId, ...updatedValues } = values;
            const input = {
                ...updatedValues,
                quantity: moveAll ? +chosenProduct?.quantity : +values?.quantity,
                unit: +chosenProduct?.product?.unit?.id,
                destinationStorage: +values?.destinationStorage
            }
            const response = await updateStorageProductRelation({
                variables: {
                    storageProductRelationId: +chosenProduct?.id,
                    input: input
                }
            });
            refetch();
            handleModal();
            toggleShowToast(t('Saved'))
        } catch (error) {
            if (error.graphQLErrors?.[0].message === 'Move quantity more than stock quantity') {
                setFieldError('quantity', t("In stock less than") + ' ' +  values.quantity + unitOfMeasurements.find(i => i.value === values?.unitId)?.label);
                return
            } else {
                console.error(error);
            }
        }
    };

    const filteredWarehouses = warehouses?.filter((wh: Option) => +wh.value !== +storageId)

    return (
        <Modal size="lg" show={show} onHide={handleModal} centered>
            <Modal.Header className="border-0" closeButton>
                <h5 className="m-0">{t('Move the product')} {chosenProduct?.product?.name}</h5>
            </Modal.Header>
            <Modal.Body>
                {
                    chosenProduct?.quantity === 0 ?
                        <p className='mb-4 text-center'>
                            {t('The selected product has a zero balance. Select the warehouse to which you are transferring the products.')}
                        </p>
                    : ""
                }

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, setFieldValue }) => (
                        <Form>
                            <Row className={'mx-0'}>
                                <Col lg={2} xs={4} className="d-flex pr-0">
                                    <label htmlFor="destinationStorage" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">{t('Warehouse name')}</span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>

                                <Col lg={10} xs={8}>
                                    <ReactSelect
                                        name='destinationStorage'
                                        options={filteredWarehouses}
                                        onChange={(option) => setFieldValue('destinationStorage', option.value)}
                                        disabled={isDisabled}
                                        placeholder={t('Select a warehouse')}
                                        isSearchable
                                    />
                                    <ErrorMessage name="destinationStorage" component="div"  className="fs-7 m-0 p-1 text-danger" />
                                    {chosenProduct?.quantity === 0 ? '' :
                                        <FormCh.Check
                                            className=' mt-3'
                                            type={"checkbox"}
                                            label={<span className="fs-7 text-dark">{t('Move All')}</span>}
                                            checked={values?.moveAll}
                                            onChange={(e) => setFieldValue('moveAll', e.target.checked)}
                                        />
                                    }
                                </Col>
                            </Row>
                            {chosenProduct?.quantity === 0 ? '' :
                                <>
                                    <Row className="pt-3 mx-0">
                                        <Col lg={2} xs={4} className="d-flex pr-0">
                                            <label htmlFor="quantity" className="mb-0 text-dark form-label required text-break">
                                                <span className="text-dark fw-bold mt-1 fs-7">{t('Amount to be moved')}</span>
                                                <span className="text-danger ms-1 text-break fs-7">*</span>
                                            </label>
                                        </Col>
                                        <Col lg={10} xs={8}>
                                            <QuantityInput
                                                name='quantity'
                                                id='addsProduct-quantity-field'
                                                placeholder={t('Amount to be moved')}
                                                maxLength={15}
                                                isDisabled={values?.moveAll}
                                            />
                                            <ErrorMessage name="quantity" component="div"  className="fs-7 m-0 p-1 text-danger"  />
                                        </Col>
                                    </Row>
                                    <Row className="pt-3 mb-4 mx-0">
                                        <Col lg={2} xs={4} className="d-flex pr-0">
                                            <label htmlFor="unitId" className="mb-0 text-dark form-label required text-break">
                                                <span className="text-dark fw-bold mt-1 fs-7">{t('Unit of measurements')}</span>
                                                <span className="text-danger ms-1 text-break fs-7">*</span>
                                            </label>
                                        </Col>
                                        <Col lg={10} xs={8}>
                                            <ReactSelect
                                                id='addProduct-unit-field'
                                                name='unitId'
                                                placeholder={t('Select an unit')}
                                                options={unitOfMeasurements}
                                                // value={unitsOptions?.find((option: Option) => option.value === values.unit)}
                                                onChange={(selectedOption: Option) => setFieldValue('unitId', selectedOption.value )}
                                                isSearchable={false}
                                                disabled={values.moveAll}
                                            />

                                            <ErrorMessage name='unitId'>{msg => <p
                                                className="fs-7 m-0 p-1 text-danger">{msg}</p>}
                                            </ErrorMessage>
                                        </Col>
                                    </Row>
                                </> }



                            <Row className="d-flex justify-content-end pt-6">
                                <Col xs={6} sm="auto" className="mb-sm-0 pe-2">
                                    <Button
                                        variant="outline-primary"
                                        className="fw-normal custom-btn rounded-1"
                                        onClick={handleModal}
                                    >
                                        {t("Cancel")}
                                    </Button>
                                </Col>
                                <Col xs={6} sm="auto" className="mb-sm-0 ps-2">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="fw-bold custom-btn rounded-1"
                                    >
                                        {t('servicesPage.moveModal.moveBtn')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default MoveGoodsModal;
