import {ProductCategoryNewInput} from '@me-team/host/main/graphql/types';

export enum StockStatus {
  InStock = 'In stock',
  OutOfStock = 'Out of stock',
  NotAdded = 'Not added',
}

export interface ProductsFilterI {
  nameSkuBarcode : string,
  categoryId: number,
  brand: string,
  stockStatus?: string,
}

export interface ProductsFilter extends ProductsFilterI {
  stockStatus: string;
}

export interface Option {
  value: number | string | string[];
  label: string;
}

export interface unitGroupI {
  id: number,
  name: string
}

export interface CategoryInterface {
  id: number;
  name: string;
  isOpen: boolean;
  isPicked: boolean;
  isSubcategoryPiked: boolean;
  subcategoryList: SubcategoryInterface[];
}

export interface SubcategoryInterface {
  id: number;
  name: string;
  isPicked: boolean;
}

export interface DuplicateProductData {
  name?: string;
  brand?: string;
  productId?: string;
}

export interface CreateProductCategoryVariables {
  companyId: number;
  input: ProductCategoryNewInput;
}