import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import style from "./CategoryComponent.module.scss";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import {SimpleModal} from "@services/ui-components/src/SimpleModal/SimpleModal";
import {
    CategoryTableInterface,
    CategoryVariables,
    ChildCategoriesTableInterface,
    delModalCategoryContentType, UniqueCategoryTableInfo
} from "../../../../interfaces/interfaces";
import {CreateEditModalComponent, RequestInputService} from "@me-pos/service-modal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import i18next from "i18next";
import {Link} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {currentCompanyIdVar} from "@me-team/host/src/apollo/globalVar/state";
import {ServiceCategoryNewInput} from '@me-team/host/main/graphql/types';
import {MoveModalComponent} from "../../../Services/MoveModalComponent/MoveModalComponent";
import CategoryValidationService from "../../../../services/CategoryTableServices/CategoryValidationService";
import SubcategoryComponent from "../SubcategoryComponent/SubcategoryComponent";
import CategoryTableService from "../../../../services/CategoryTableServices/CategoryTableService";


type CategoryComponentProps = {
    category: CategoryTableInterface
    categoryList: CategoryTableInterface[]
    hasAccess: boolean
    onCreate: (categoryVariables: CategoryVariables) => Promise<void>
    onUpdate: (categoryVariables: CategoryVariables, isRestored?: boolean) => Promise<void>
    onDelete: (categoryVariables: CategoryVariables) => Promise<void>
    searchParamBool : boolean
    uniqueInfo : UniqueCategoryTableInfo
}

const CategoryComponent: React.FC<CategoryComponentProps> = (props) => {
    const {t: translate} = useTranslation();
    const [isSubcategoryOpen, setIsSubcategoryOpen] = useState<boolean>(CategoryTableService.checkSearchValue(props.category, props.searchParamBool));
    const [isDelModalOpen, setIsDelModalOpen] = useState(false);
    const [isMoveModalOpen, setIsMoveModalOpen] = useState<boolean>(false);
    const [delModalContent, setDelModalContent] = useState<delModalCategoryContentType>({
        title: '',
        description: '',
        withoutSubcatAndServices: true,
        withSubcat: false,
        withServiceInCategory: false,
        withServiceInSubcat: false
    })
    const [isUpdateCategoryModalOpen, setIsUpdateCategoryModalOpen] = useState<boolean>(false);
    const [isCreateAction, setIsCreateAction] = useState<boolean>(false);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    const openSubcategoryHandler = () => {
        setIsSubcategoryOpen(!isSubcategoryOpen);
    }

    const handleOpenDelModal = () => {
        CategoryValidationService.deleteCategoryValidator(props.category, setDelModalContent, translate, props.uniqueInfo.delErrorWithCategoryServices)
        setIsDelModalOpen(true);
    }

    const handleCloseDelModal = () => {
        setIsDelModalOpen(false)
    }

    const deleteCategoryHandler = () => {
        const input: ServiceCategoryNewInput = RequestInputService.createServiceCategoryInput(props.category.id, null, props.categoryList)
        input.deleted = true;
        props.onDelete({categoryId: props.category.id, categoryInput: input}).finally(() => {
            setIsDelModalOpen(false);
            setIsMoveModalOpen(false)
        })
    }

    const onOpenMoveModal = () => {
        setIsMoveModalOpen(true)
    }

    const onCloseMoveModal = () => {
        setIsMoveModalOpen(false)
    }

    const moveCategoryHandler = async (inputForReq: ServiceCategoryNewInput) => {
        await props.onUpdate({categoryId: props.category.id, categoryInput: inputForReq});
        onCloseMoveModal();
    }

    const onUpdateCategoryHandler = (isCreateAction: boolean) => {
        setIsCreateAction(isCreateAction);
        setIsUpdateCategoryModalOpen(true);
    }

    return (
        <>
            <Container className="border-bottom table-category-width">
                <Row
                    className={`gx-3 ${props.hasAccess ? 'py-3' : 'py-4'} flex-nowrap ps-4 ${props.category.deleted && 'text-secondary'}`}>
                    <Col xs={3} className={"d-flex align-items-center"}>
                        {props.category.childCategories.length !== 0 &&
                            <button className={`btn p-1 me-2 ${props.category.deleted && 'text-secondary'}`}
                                    onClick={openSubcategoryHandler}>
                                <i className={`bi bi-chevron-right ${isSubcategoryOpen ? 'rotated90' : ''} d-flex`}></i>
                            </button>}
                        {props.category.name}
                    </Col>
                    <Col xs={5} className={"d-flex align-items-center"}>
                        {props.category.comment ? props.category.comment : '—'}
                    </Col>
                    <Col xs={2} className={"d-flex align-items-center"}>
                        {props.category.categoryServices.length !== 0 ?
                            <Link to={{
                                pathname: `/warehouse/product/${currentCompanyId}`,
                                search: `?id=${encodeURIComponent(props.category.id)}`
                            }}
                                  className={"text-decoration-none"}>{props.category.categoryServices.length}</Link>
                            : 0}
                    </Col>
                    {props.hasAccess &&
                        <>
                            {props.category.deleted ?
                                <Col xs={2} className={"d-flex align-items-center justify-content-end pe-0"}>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip
                                            id="tooltip-top">{translate('servicesPage.categoryTooltip')}</Tooltip>}
                                    >
                                        <Button variant="secondary" className={"px-3 py-2 me-2 fw-normal rounded-1"}>
                                            {translate('servicesPage.archive')}
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="tooltip-top">{translate('Restore')}</Tooltip>}
                                    >
                                        <Button className={"btn rounded border text-dark bg-white rounded-1"}
                                                onClick={() => {
                                                    onUpdateCategoryHandler(false)
                                                }}>
                                            <i className="bi bi-arrow-counterclockwise d-flex fs-normal"></i>
                                        </Button>
                                    </OverlayTrigger>

                                </Col> :
                                <Col xs={2}
                                     className={"d-flex align-items-center justify-content-end flex-wrap gap-2 pe-0"}>
                                    {props.category.childCategories.length === 0 &&
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip
                                                id="tooltip-top">{translate('Move category')}</Tooltip>}
                                        >
                                            <Button className={"btn rounded text-dark bg-white border"}
                                                    onClick={onOpenMoveModal}>
                                                <i className="bi bi-box-arrow-right d-flex fs-normal"></i>
                                            </Button>
                                        </OverlayTrigger>}
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip
                                        className="tooltip-category">{translate('Add subcategory')}</Tooltip>}>
                                        <Button className={"btn rounded border text-dark bg-white rounded-1"}
                                                onClick={() => {
                                                    onUpdateCategoryHandler(true)
                                                }}>
                                            <i className="bi bi-plus-lg d-flex fs-normal"></i>
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip
                                        className="tooltip-category">{translate('Edit category')}</Tooltip>}>
                                        <Button className={"btn rounded border text-dark bg-white rounded-1"}
                                                onClick={() => {
                                                    onUpdateCategoryHandler(false)
                                                }}>
                                            <i className="bi bi-pencil d-flex fs-normal"></i>
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom"
                                                    overlay={<Tooltip>{translate('Delete category')}</Tooltip>}>
                                        <Button className={"btn rounded border text-dark bg-white rounded-1"}
                                                onClick={handleOpenDelModal}>
                                            <i className="bi bi-trash3 d-flex fs-normal"></i>
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            }
                        </>
                    }
                </Row>
            </Container>
            {props.category.childCategories.length !== 0 &&
                <div className={`${style.servicesSubcategoryList} ${isSubcategoryOpen ? style.open : ''}`}>
                    {props.category.childCategories.map((subcategory: ChildCategoriesTableInterface, index) => (
                        <SubcategoryComponent
                            key={index}
                            subcategory={subcategory}
                            parentCategory={props.category}
                            categoryList={props.categoryList}
                            hasAccess={props.hasAccess}
                            onCreate={props.onCreate}
                            onUpdate={props.onUpdate}
                            onDelete={props.onDelete}
                            uniqueInfo={props.uniqueInfo}
                        />
                    ))}
                </div>
            }
            <SimpleModal isOpen={isDelModalOpen} onClose={handleCloseDelModal} title={delModalContent.title}
                         description={delModalContent.description}>
                <Container>
                    <Row>
                        {(delModalContent.withoutSubcatAndServices || delModalContent.withSubcat) &&
                            <>
                                <Col xs={6} className={"ps-0"}>
                                    <Button variant="outline-primary" className={"w-100 fw-normal"}
                                            onClick={handleCloseDelModal}>
                                        {translate('servicesPage.deleteModal.cancelBtn')}
                                    </Button>
                                </Col>
                                <Col xs={6} className={"pe-0"}>
                                    <Button variant="primary" className={"w-100 fw-normal"}
                                            onClick={deleteCategoryHandler}>
                                        {translate('servicesPage.deleteModal.applyBtn')}
                                    </Button>
                                </Col>
                            </>
                        }

                        {delModalContent.withServiceInSubcat &&
                            <Col xs={12} className={"px-0"}>
                                <Button variant="primary" className={"w-100 fw-normal"} onClick={handleCloseDelModal}>
                                    {translate('servicesPage.deleteModal.okBtn')}
                                </Button>
                            </Col>
                        }

                        {delModalContent.withServiceInCategory &&
                            <Col xs={12} className={"px-0"}>
                                <Link
                                    to={{
                                        pathname: `${props.uniqueInfo.delModalRefToService}`,
                                        search: `?id=${encodeURIComponent(props.category.id)}`
                                    }}
                                    className={"btn btn-primary w-100 fw-normal"}
                                >
                                    {translate(`${props.uniqueInfo.delModalBtnName}`)}
                                </Link>
                            </Col>

                        }
                    </Row>
                </Container>
            </SimpleModal>

            {isMoveModalOpen &&
                <MoveModalComponent isMoveModalOpen={isMoveModalOpen} onCloseMoveModal={onCloseMoveModal}
                                    moveCategoryHandler={moveCategoryHandler} currentCategory={props.category}
                                    categoryList={props.categoryList}/>}
            {isUpdateCategoryModalOpen &&
                <CreateEditModalComponent
                    i18n={i18next}
                    categoryId={props.category.id}
                    subcategoryId={null}
                    isOpen={isUpdateCategoryModalOpen}
                    categoryInput={RequestInputService.createServiceCategoryInput(props.category.id, null, props.categoryList)}
                    categoryList={props.categoryList}
                    isCreateAction={isCreateAction}
                    onClose={() => {
                        setIsUpdateCategoryModalOpen(false);
                    }}
                    onSubmit={() => {
                    }}
                    onSubmitCreate={async (inputVariables) => {
                        await props.onCreate({categoryId: props.category.id, categoryInput: inputVariables.input});

                    }}
                    onSubmitUpdate={async (inputVariables) => {
                        await props.onUpdate({
                            categoryId: props.category.id,
                            categoryInput: inputVariables.input
                        }, props.category.deleted);
                    }}
                    requestInputService={RequestInputService}
                    currentCategory={null}
                    categoryService={null}/>
            }

        </>
    )
}

export default React.memo(CategoryComponent)
