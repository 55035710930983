import React from 'react';
import { useTranslation } from 'react-i18next';
import bookingStatusService from '../services/bookingStatusService';
import { formatDate, getTimeFromHistory, calculateTimeDifference } from '../utils/utils';
import { BookingHistoryDataProxy } from '@me-team/host/main/graphql/types';
import { useGetServiceByIdQuery } from '@me-team/host/main/appointment/graphql/appointment.hooks';
import { useGetEmployeeByIdQuery } from '@me-team/host/main/addEmployee/graphql/addEmployee.hooks';


interface BookingHistoryDataItemProps {
    data: BookingHistoryDataProxy;
    currency: string;
    historyDataArray: BookingHistoryDataProxy[];
}

const BookingHistoryDataItem: React.FC<BookingHistoryDataItemProps> = ({ data, currency, historyDataArray }) => {
    const { t, i18n } = useTranslation();
    const renderField = (data: BookingHistoryDataProxy) => {
        switch (data.field) {
            case 'status':
                const statusText = bookingStatusService.getStatusDetails(+data.value);
                return (
                    <>
                        <i className="bi bi-clipboard me-3 text-secondary "></i>
                        <span className="text-secondary ">{t('Status')} :</span>
                        <span className="ms-1 " dangerouslySetInnerHTML={{ __html: statusText }}></span>
                    </>
                );
            case 'date':
                return (
                    <>
                        <i className="bi bi-calendar4-week me-3 text-secondary"></i>
                        <span className="text-secondary">{t('Booking date')} :</span>
                        <span className="ms-1">{formatDate(data.value, i18n.language)}</span>
                    </>
                );
            case 'clientData':
                return (
                    <>
                        <i className="bi bi-person-circle me-3 text-secondary"></i>
                        <span className="text-secondary">{t('Client')} : </span>
                        <span className="ms-1">{data.value}</span>
                    </>
                );
            case 'service':
                const { data: serviceById, loading } = useGetServiceByIdQuery({
                    variables: { id: +data.value }
                });

                const serviceName = serviceById?.user.company.services.services[0].name

                if (loading) return <>{' '}</>

                return (
                    <>
                        <i className="bi bi-bookmark me-3 text-secondary "></i>
                        <span className="text-secondary">{t('Service')} : </span>
                        <span className="ms-1">{serviceName}</span>
                    </>
                );
            case 'time':
                return (
                    <>
                        <i className="bi bi-clock me-3 text-secondary "></i>
                        <span className="text-secondary">{t('TimeHistoryChange')} : </span>
                        <span className="ms-1">
                            {data.value}
                        </span>
                    </>
                );
            case 'price':
                return (
                    <>
                        <i className="bi text-secondary  me-3 bi-cash-coin"></i>
                        <span className="text-secondary">{t('Price')}: </span>
                        <span className="ms-1">{data.value} {currency} </span>
                    </>
                );
            case 'notificationTime':
                return (
                    <>
                        <i className="bi bi-clock-history me-3 text-secondary "></i>
                        <span className="text-secondary">{t('Notification time')} : </span>
                        <span className="ms-1">
                            {data.value}
                        </span>
                    </>
                );
            case 'notes':
                return (
                    <>
                        <i className="bi bi-pen me-3 text-secondary "></i>
                        <span className="text-secondary">{t('Notes')} : </span>
                        <span className="ms-1">{data?.value}</span>
                    </>
                );
            case 'employee':
                const { data: employees } = useGetEmployeeByIdQuery({
                    variables: {
                        employeeId: +data?.value
                    },
                    context: {
                        errorType: 'local',
                    },
                });
                const employee = employees?.user.company.employees.employees[0]
                return (
                    <>
                        <i className="bi bi-person me-3 text-secondary "></i>
                        <span className="text-secondary">{t('EmployeeHistoryChange')} :</span>
                        <span className="ms-1">
                            {employee?.name} {employee?.surname}
                        </span>
                    </>
                )
            case  'endTime':
                const startTime = getTimeFromHistory(historyDataArray);
                const duration = startTime ? calculateTimeDifference(data.value, startTime) : 'N/A';
                return (
                    <>
                        <i className="bi bi-clock-history me-3 text-secondary "></i>
                        <span className="text-secondary">{t('Duration')} : </span>
                        <span className="ms-1">
                            {duration}
                        </span>
                    </>
                );
            default:
                return null;
        }
    }

    return <p className="mb-1 d-flex fs-7">
        {renderField(data)}
    </p>;
};

export default BookingHistoryDataItem;