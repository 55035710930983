import {Formik, Form, Field, ErrorMessage, FormikHelpers, useFormikContext} from 'formik';
import React, {useState, useRef, useEffect, ChangeEvent} from 'react';
import {Col, Row, Modal, Button, Form as FormCh} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {CategoryPickerCategory, CategoryPickerComponent} from "@me-pos/category-picker";
import i18n from "@me-team/host/src/i18n";
import {normalizeCategoryData} from "../../Products/services/normalizeCategoryData";
import transformProductCategories from "@me-team/host/src/utils/transformProductCategories";
import {ApolloError, useReactiveVar} from "@apollo/client";
import ErrorService from "../../../services/ErrorService";
import {CategoryInterface, Option, unitGroupI} from "../../Products/types";
import {ReactSelect} from "@services/ui-components";
import {currentCompanyIdVar} from "@me-team/host/src/apollo/globalVar/state";
import {productFormValidationSchema} from "./utils";
import {ProductInput, ProductProxy, UnitGroupProxy} from '@me-team/host/main/graphql/types';
import {
    useCreateProductToWarehouseMutation
} from '@me-team/host/main/addNewProductList/graphql/addNewProductList.hooks';
import {
    useCreateStorageProductRelationMutation
} from '@me-team/host/main/warehouses_goods/graphql/warehouses_goods.hooks';
import {
    useGetProductCategoriesQuery,
    useGetUnitsQuery
} from '@me-team/host/main/products/graphql/products.hooks';
import CriticalResidueInput from "../../Products/inputs/CriticalResidueInput";
import QuantityInput from "./QuantityInput";

type ProductInputWithQuantity = ProductInput & {
    quantity: string;
};

const AddProductListModal: React.FC<{ show: boolean, onHide: () => void, refetch?: () => void, handleModal: (arg?: any) => void, existingProduct: ProductProxy, setExistingProduct: (arg?: any) => void, storageId: number, toggleShowToast?: (text: string) => void;  }> = ({ show, onHide, refetch, handleModal, existingProduct, setExistingProduct, storageId, toggleShowToast }) => {
    const { t } = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const dropdownRef = useRef(null);
    const [selectedCategory, setSelectedCategory] = useState<CategoryInterface[]>(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [currentIdFilter, setCurrentIdFilter] = useState({categoryId: null, subCategoryId: null});
    const [currentNameFilter, setCurrentNameFilter] = useState({
        categoryName: '',
        subCategoryName: '',
    });
    const [unitOfMeasurements, setUnitOfMeasurements] = useState([])

    const initialValues: ProductInputWithQuantity = {
        name: '',
        brand: '',
        category: null,
        sku: '',
        barcode: '',
        unitGroup: null,
        unit: null,
        deleted: false,
        quantity: ''
    }
    const existingInitialValues: ProductInputWithQuantity = {
        name: existingProduct?.name,
        brand: existingProduct?.brand,
        category: existingProduct?.productCategoryNew?.name,
        sku: existingProduct?.sku,
        barcode: existingProduct?.barcode,
        unitGroup: existingProduct?.unitGroup?.name,
        unit: null,
        deleted: false,
        quantity: ''
    };
    const [createProduct] = useCreateProductToWarehouseMutation()
    const [createStorageProductRelation] = useCreateStorageProductRelationMutation({
        context: {
            errorType: 'local',
        },
    });
    const {data: productCategoriesData, refetch: refetchCategories, loading: productCategoryLoading} = useGetProductCategoriesQuery({
        variables: {
            itemsPerPage: 1000
        },
        context: {
            errorType: 'local'
        },
        onCompleted: (data) => {
            const normalizedItems = normalizeCategoryData(data);
            setSelectedCategory(
                transformProductCategories(normalizedItems)
            );
        },
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
    });
    const {data: unitsData} = useGetUnitsQuery({
        context: {
            errorType: 'local'
        },
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        }
    });
    const uniqueUnitGroups: UnitGroupProxy[] = unitsData?.relations?.units?.reduce((acc, unit) => {
        if (!acc.some(group => group.id ===  unit.unitGroup.id)) {
            acc.push(unit.unitGroup);
        }
        return acc;
    }, []);
    const handleCategoryNameChange = (categoryName: string) => {
        setCurrentNameFilter({categoryName, subCategoryName: ''});
    };
    const handleCategoryIdChange = (categoryId: number,  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        setFieldValue('category', categoryId)
        setCurrentIdFilter({categoryId, subCategoryId: null});
    };
    const handleSubCategoryNameChange = (subCategoryName: string, categoryName: string = '') => {
        setCurrentNameFilter({categoryName, subCategoryName});
    };
    const handleSubCategoryIdChange = (categoryId: number, subCategoryId: number, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        setFieldValue('category', subCategoryId)
        setCurrentIdFilter((prevState) => ({
            ...prevState,
            categoryId: categoryId,
            subCategoryId: subCategoryId,
        }));
    };
    const unitGroupOptions = uniqueUnitGroups?.map((unitGroup: unitGroupI) => {
        return {
            value: unitGroup.id,
            label: unitGroup.name
        }
    }) || [];
    useEffect(() => {
        if (existingProduct) {
            const optionsUnitOfMeasurements = unitsData?.relations?.units
                ?.filter((unit) => {
                    const unitGroupId = existingProduct?.unitGroup?.id;
                    const alternativeUnitGroupId = existingProduct.alternativeUnit?.unitGroup?.id;
                    return unit.unitGroup.id === unitGroupId ||
                        (alternativeUnitGroupId && unit.unitGroup.id === alternativeUnitGroupId);
                })
                ?.map((unit) => ({
                    value: unit.id,
                    label: unit.name,
                }));
            setUnitOfMeasurements(optionsUnitOfMeasurements);
        }
    }, [existingProduct]);

    const handleUnitGroupChange = (selectedOption: Option, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        const selectedGroupId = selectedOption.value;
        setFieldValue('unit', '')
        setFieldValue('unitGroup', selectedGroupId, true )
        const optionsUnitOfMeasurements = unitsData?.relations?.units
            ?.filter((unit) => unit.unitGroup.id === selectedGroupId)
            ?.map((unit) => ({
                value: unit.id,
                label: unit.name,
            }))
        setUnitOfMeasurements(optionsUnitOfMeasurements)
    };
    const handleSubmit = async (
        values: typeof initialValues,
        { setFieldError }: FormikHelpers<typeof initialValues>
    ) => {
        try {
            if (existingProduct) {
                const createProductRelation = await createStorageProductRelation({
                    variables: {
                        storageId: +storageId,
                        productId: existingProduct.id,
                        input: {
                            quantity: +values?.quantity,
                            unit: values.unit,
                        },
                    },
                })
            } else {
                const { quantity, ...rest } = values;
                const filterId = currentIdFilter.subCategoryId
                    ? currentIdFilter.subCategoryId
                    : currentIdFilter.categoryId || null;
                const input = { ...rest, category: filterId }
                const response = await createProduct({
                    variables: { companyId: +currentCompanyId, input },
                })
                if (response?.data?.createProduct?.id) {
                    const createProductRelation = await createStorageProductRelation({
                        variables: {
                            storageId: +storageId,
                            productId: response.data.createProduct.id,
                            input: { quantity: +quantity, unit: values.unit },
                        },
                    })
                }
            }
            refetch();
            setIsPickerOpen(false)
            handleModal();
            setExistingProduct(null)
            toggleShowToast(existingProduct ? t('Saved')  :  t('Created'))
        } catch (error) {
            console.error(error);
        }
    };

    return (
            <Modal
                size="lg"
                show={show}
                onHide={() => {
                    setExistingProduct()
                    setIsPickerOpen(false)
                    handleModal()
                }}
                centered
            >
                <Modal.Header className='border-0' closeButton>
                    <h5 className="m-0">
                        {existingProduct ?
                            t('Add products to the warehouse')
                            :
                            t('Create & Add product to the warehouse')
                        }
                    </h5>
                </Modal.Header>
                <Formik
                    initialValues={ existingProduct ? existingInitialValues : initialValues}
                    validationSchema={productFormValidationSchema(t, !!existingProduct)}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, values , setFieldError, setFieldTouched, errors, touched, setFieldValue  }) => (
                        <Form>
                            <Modal.Body style={{ padding: '16px 28px 16px 28px' }}>
                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 d-flex align-items-center" >
                                        <label htmlFor="name-addProduct">
                                            <strong className="text-dark fs-7">
                                                {t('Product name')}
                                                {existingProduct ? '' :  <span className="text-danger"> *</span>}
                                            </strong>
                                        </label>
                                    </Col>
                                    <Col xs={9} className="text-dark ">
                                        {existingProduct ? (
                                            <div className="pt-2">
                                                <span className="fs-7">{values?.name}</span>
                                            </div>
                                        ) : (
                                            <>
                                            <Field
                                                    name="name"
                                                    id="name-addProduct"
                                                    className={`form-control fs-7 ${touched.name && errors.name ? 'is-invalid' : ''}`}
                                                    placeholder={t('Enter the Product Name')}
                                                    maxLength={50}
                                                />
                                            </>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="p-0">
                                    <Col xs={3} className="pe-0 d-flex align-items-center" >
                                    </Col>
                                    <Col xs={9} className="text-dark ">
                                        {existingProduct ? '' : (
                                            <>
                                                <span
                                                    className={`fs-7 ${values?.name?.length > 50 ? "text-danger" : "text-grey"}`}
                                                >
                                                    {values?.name?.length}/50
                                                </span>
                                                <ErrorMessage name="name">
                                                    {msg => <div className="invalid-feedback d-block">{msg}</div>}
                                                </ErrorMessage>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 d-flex align-items-center">
                                        <label htmlFor="brand-addProduct">
                                            <strong className="text-dark fs-7">
                                                {t('ProductsList.Brand')}
                                                {existingProduct ? '' :  <span className="text-danger"> *</span>}
                                            </strong>
                                        </label>
                                    </Col>
                                    <Col xs={9} className="text-dark" >
                                        {existingProduct ? (
                                                <div className="pt-2">
                                                    <span className="fs-7">{values?.brand}</span>
                                                </div>
                                        ) : (
                                            <>
                                                <Field
                                                    name="brand"
                                                    id="brand-addProduct"
                                                    className={`form-control fs-7 ${touched.brand && errors.brand ? 'is-invalid' : ''}`}
                                                    placeholder={t('Enter a Brand')}
                                                    maxLength={50}
                                                />
                                            </>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="p-0">
                                    <Col xs={3} className="pe-0 d-flex align-items-center" >
                                    </Col>
                                    <Col xs={9} className="text-dark ">
                                        {existingProduct ? '' : (
                                            <>
                                                <span className={`fs-7 ${values?.brand?.length > 50 ? "text-danger" : "text-grey"} `}>
                                                    {values.brand.length}/50
                                                </span>
                                                <ErrorMessage name='brand'>
                                                    {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                                                </ErrorMessage>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 d-flex align-items-center">
                                        <label htmlFor="category-addProduct">
                                            <strong className="text-dark fs-7">
                                                {t('ProductsList.Category')}
                                                {existingProduct ? '' :  <span className="text-danger"> *</span>}
                                            </strong>
                                        </label>
                                    </Col>
                                    <Col xs={9} className="text-dark">
                                        {existingProduct ? (
                                            <div className="pt-2">
                                                <span className="fs-7">{values?.category}</span>
                                            </div>
                                        ) : (
                                            <>
                                                <div className='position-relative mb-3 mb-lg-0'>
                                                    <input
                                                        name="category"
                                                        type='text'
                                                        id='productsList-categoryId-field'
                                                        className={`form-control fs-7 ${touched.category && errors.category ? 'is-invalid' : ''} cursor-pointer`}
                                                        placeholder={t('Category')}
                                                        value={
                                                            currentNameFilter?.categoryName || currentNameFilter?.subCategoryName
                                                                ? `${currentNameFilter?.categoryName}  ${currentNameFilter?.subCategoryName}`
                                                                : ''
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setIsPickerOpen(!isPickerOpen);
                                                        }}
                                                        readOnly
                                                    />
                                                    {
                                                        touched.category && errors.category ?
                                                            <i
                                                                className="bi bi-exclamation-circle fs-20 position-absolute top-50 end-0 translate-middle-y text-danger p-1 pe-3 me-4 fw-bold bg-white"
                                                                style={{backgroundColor: 'inherit'}}
                                                            >
                                                            </i> : ''
                                                    }
                                                    <i className='bi bi-chevron-down position-absolute top-50 end-0 translate-middle-y text-dark me-2 bg-light bg-white'
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           setIsPickerOpen(!isPickerOpen);
                                                       }}
                                                    ></i>

                                                    <div
                                                        ref={dropdownRef}
                                                        className='dropdown position-absolute opacity-1 z-2 top-10  start-0 end-0 mt-2 bg-white shadow'>
                                                        {productCategoriesData && selectedCategory && (
                                                            <CategoryPickerComponent
                                                                isOpen={isPickerOpen}
                                                                locale={i18n.language}
                                                                onSearchChange={(searchVealue: string) => {
                                                                }}
                                                                onChangeCategory={(currentCategory: CategoryPickerCategory) => {
                                                                    if (currentCategory.isPicked) {
                                                                        handleCategoryNameChange(currentCategory?.name);
                                                                        handleCategoryIdChange(currentCategory?.id, setFieldValue);
                                                                        setIsPickerOpen(false);
                                                                    } else {
                                                                        setCurrentNameFilter({
                                                                            categoryName: '',
                                                                            subCategoryName: ''
                                                                        });
                                                                    }
                                                                }}
                                                                onChangeSubcategory={(currentSubcategory, currentCategory) => {
                                                                    if (currentSubcategory?.isPicked) {
                                                                        handleSubCategoryNameChange(currentSubcategory?.name);
                                                                        handleSubCategoryIdChange(currentCategory?.id, currentSubcategory?.id, setFieldValue);
                                                                        setIsPickerOpen(false);
                                                                    } else {
                                                                        setCurrentNameFilter({
                                                                            categoryName: '',
                                                                            subCategoryName: ''
                                                                        });
                                                                    }
                                                                }}
                                                                serviceCategories={selectedCategory}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    </Col>
                                </Row>
                                <Row className="p-0">
                                    <Col xs={3} className="pe-0 d-flex align-items-center" >
                                    </Col>
                                    <Col xs={9} className="text-dark ">
                                        {existingProduct ? '' :
                                            <ErrorMessage name='category'>
                                                {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                                            </ErrorMessage>
                                        }

                                    </Col>
                                </Row>
                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 d-flex align-items-center">
                                        <label htmlFor="sku-addProduct">
                                            <strong className="text-dark fs-7">
                                                {t('ProductsList.SKU')}
                                            </strong>
                                        </label>
                                    </Col>
                                    <Col xs={9} className="text-dark">
                                        {existingProduct ? (
                                            <div className="pt-2">
                                                <span className="fs-7">{values?.brand}</span>
                                            </div>
                                        ) : (
                                            <>
                                                <Field
                                                    name="sku"
                                                    id="sku-addProduct"
                                                    className={`form-control fs-7 ${touched.sku && errors.sku ? 'is-invalid' : ''}`}
                                                    placeholder={t('ProductsList.SKU')}
                                                    maxLength={50}
                                                />

                                            </>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="p-0">
                                    <Col xs={3} className="pe-0 d-flex align-items-center" >
                                    </Col>
                                    <Col xs={9} className="text-dark ">
                                        {existingProduct ? "" :
                                            <>
                                            <span
                                                className={`fs-7 ${values?.sku?.length > 50 ? "text-danger" : "text-grey"} `}>
                                                    {values?.sku?.length}/50
                                            </span>
                                            <ErrorMessage name='sku'>
                                                {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                                            </ErrorMessage>
                                            </>
                                        }
                                    </Col>
                                </Row>
                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 d-flex align-items-center">
                                        <label htmlFor="barcode-addProduct">
                                            <strong className="text-dark fs-7">
                                                {t('ProductsList.Barcode')}
                                            </strong>
                                        </label>
                                    </Col>
                                    <Col xs={9} className="text-dark">
                                        {existingProduct ? (
                                            <div className="pt-2">
                                                <span className="fs-7">{values?.category}</span>
                                            </div>
                                        ) : (
                                            <>
                                                <Field
                                                    name="barcode"
                                                    id="barcode-addProduct"
                                                    className={`form-control fs-7 ${touched.barcode && errors.barcode ? 'is-invalid' : ''}`}
                                                    placeholder={t('ProductsList.Barcode')}
                                                    maxLength={50}
                                                />

                                            </>
                                        )}

                                    </Col>
                                </Row>
                                <Row className="p-0">
                                    <Col xs={3} className="pe-0 d-flex align-items-center" >
                                    </Col>
                                    <Col xs={9} className="text-dark ">
                                        {existingProduct ? "" :
                                            <>
                                                <span
                                                    className={`fs-7 ${values?.barcode?.length > 50 ? "text-danger" : "text-grey"} `}>
                                                    {values?.barcode?.length}/50
                                                </span>
                                                <ErrorMessage name='barcode'>
                                                    {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                                                </ErrorMessage>
                                            </>
                                        }

                                    </Col>
                                </Row>
                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 d-flex align-items-center">
                                        <label htmlFor="sku-addProduct">
                                            <strong className="text-dark fs-7">
                                            {t('Unit group')}
                                                {existingProduct ? '' :  <span className="text-danger"> *</span>}
                                            </strong>
                                        </label>
                                    </Col>
                                    <Col xs={9} className="text-dark">
                                        {existingProduct ? (
                                            <div className="pt-2">
                                                <span className="fs-7">{values?.unitGroup}</span>
                                            </div>
                                        ) : (
                                            <>
                                                <ReactSelect
                                                    id='addProduct-unitGroup-field'
                                                    name='unitGroup'
                                                    placeholder={t('Select an unit group')}
                                                    options={unitGroupOptions}
                                                    onChange={(selectedOption: Option) => handleUnitGroupChange(selectedOption, setFieldValue, )}
                                                    isSearchable={false}
                                                />

                                            </>
                                        )}

                                    </Col>
                                </Row>
                                <Row className="p-0">
                                    <Col xs={3} className="pe-0 d-flex align-items-center" >
                                    </Col>
                                    <Col xs={9} className="text-dark ">
                                        {existingProduct ? "" :
                                            <ErrorMessage name='unitGroup'>
                                                {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                                            </ErrorMessage>
                                        }
                                    </Col>
                                </Row>
                                {existingProduct ?
                                    <Row className="py-2 d-flex align-items-center">
                                        <Col xs={3} className="pe-0 pt-2">
                                        <label htmlFor="quantity-addProduct">
                                            <strong className="text-dark fs-7">
                                                {t('Remnants')}
                                                {existingProduct ? '' :  <span className="text-danger"> *</span>}
                                            </strong>
                                        </label>
                                    </Col>
                                        <Col xs={9} className="text-dark">
                                            <div className="pt-2">
                                                <span className="fs-7">
                                                    {existingProduct?.storageProductRelations?.reduce((total, relation) => total + relation?.quantity, 0)}
                                                        {' '}{existingProduct?.unit?.name}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row> : ' '}

                                <Row className="py-2">
                                    <Col xs={3} className="pe-3 d-flex  align-items-center">
                                        <label htmlFor="quantity-addProduct ">
                                            <strong className="text-dark fs-7">
                                                {t('Amount to be added')}
                                                <span className="text-danger"> *</span>
                                            </strong>
                                        </label>
                                    </Col>
                                    <Col xs={9} className="text-dark" >

                                        <QuantityInput
                                            name='quantity'
                                            id='addsProduct-quantity-field'
                                            placeholder={t('Amount to be added')}
                                            maxLength={10}
                                        />
                                        <ErrorMessage name='quantity'>
                                            {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                                        </ErrorMessage>
                                    </Col>
                                </Row>
                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 d-flex align-items-center">
                                        <Col xs={7}>
                                            <label htmlFor="quantity-addProduct">
                                                <strong className="text-dark fs-7">
                                                    {t('Unit of measurements')}&nbsp;<span className="text-danger">*</span>
                                                </strong>
                                            </label>
                                        </Col>

                                    </Col>
                                    <Col xs={9} className="text-dark">
                                        <ReactSelect
                                            id='addProduct-unit-field'
                                            name='unit'
                                            placeholder={t('Select an unit')}
                                            options={unitOfMeasurements}
                                            // value={unitsOptions?.find((option: Option) => option.value === values.unit)}
                                            onChange={(selectedOption: Option) =>setFieldValue('unit', selectedOption.value )}
                                            isSearchable={false}
                                        />
                                        <ErrorMessage name='unit'>
                                            {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                                        </ErrorMessage>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <div style={{padding: ' 12px'}}>
                                <Row className="gx-3  justify-content-end">
                                    <Col xs={3}>
                                        <Button
                                            variant="outline-primary"
                                            className="w-100 h-100 fw-normal"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIsPickerOpen(false)
                                                handleModal(null)
                                            }}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                    </Col>
                                    <Col xs={3}>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="w-100 text-truncate"

                                        >
                                            {t('Add')}
                                        </Button>
                                    </Col>

                                </Row>
                            </div>

                        </Form>
                    )}
                </Formik>
            </Modal>

    );
};

export default AddProductListModal;
