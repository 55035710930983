import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import { Col, Row, Button, Container, Form } from "react-bootstrap";
import { FiltersButton, TableHeader } from '@services/ui-components';
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import EmployeesTable from './EmployeesTable/EmployeesTable';
import AccessService from '@me-team/host/services/AccessService';
import CustomReactSelect from "@services/ui-components/src/select/CustomReactSelect";
import ToastComponent from "../ui-components/ToastComponent/ToastComponent";
import { useToast } from "../../hooks/useToast";
import { requirementsInfoType } from "../../interfaces/interfaces";
import RequirementsFileInfo from "../../services/importFiles/RequirementsFileInfo";
import i18next from "i18next";
import { useReactiveVar } from "@apollo/client";
import { currentCompanyIdVar } from "@me-team/host/src/apollo/globalVar/state";
import { ImportFiles } from "../ImportFiles/ImportFiles";
import { ExportBtn } from "../ExportBtn/ExportBtn";
import { EntityType } from "../../utils/enums";
import { useGetEmployeesPositionsQuery } from '@me-team/host/main/addEmployee/graphql/addEmployee.hooks';
import { useGetStatusQuery } from '@me-team/host/main/employeeList/graphql/employeeList.hooks';

const EmployeesList: React.FC = () => {
    const { t } = useTranslation();
    const { position, userId } = useParams();
    const navigate = useNavigate();
    const runJoy = localStorage.getItem('run') === 'true';
    const [run, setRun] = useState(runJoy);
    const step = localStorage.getItem('step') || '0';
    const [stepIndex, setStepIndex] = useState(+step);

    const accessService = AccessService.getInstance().isPremium
    const role = AccessService.getInstance().currentUserRole;
    const { showToast, toastText, setShowToast } = useToast();
    const selectPositionRef = useRef<{ reset: () => void }>(null);
    const selectStatusRef = useRef<{ reset: () => void }>(null);

    const handleResetClick = (ref: React.RefObject<{ reset: () => void }>) => {
        if (ref.current) {
            ref.current.reset();
        }
    };
    const inputNameRef = useRef<HTMLInputElement>(null);
    const [showFilters, setShowFilters] = useState(false)
    const [filter, setFilter] = useState({ name: '', positionId: position || '', statusId: '' })
    const [currentFilter, setCurrentFilter] = useState({ positionId: '', statusId: '' })
    const [currentPage, setCurrentPage] = useState(1);
    const { data: poss } = useGetEmployeesPositionsQuery()
    const { data: statuses } = useGetStatusQuery()
    const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
    const [employeeRequirementsInfo, setEmployeeRequirementsInfo] = useState<requirementsInfoType>(null);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    const positions = poss?.user?.company?.employeePositions?.employeePositions
    const status = statuses?.employeeStatus
    const toggleFilters = () => setShowFilters(!showFilters);

    useEffect(() => {
        if (currentCompanyId) {
            setEmployeeRequirementsInfo(RequirementsFileInfo.employeeFileRequirements(parseInt(currentCompanyId), t, i18next.language));
        }
    }, [i18next.language, currentCompanyId]);

    const onOpenImportModalHandler = () => {
        setIsImportModalOpen(true);
    };
    const onCloseImportModalHandler = () => {
        setIsImportModalOpen(false);
    };

    const handlePositionChange = (name: string, id: string) => {
        setCurrentFilter(prevFilter => ({
            ...prevFilter,
            positionId: id
        }));
    };

    const handleStatusChange = (name: string, id: string) => {
        setCurrentFilter(prevFilter => ({
            ...prevFilter,
            statusId: id
        }));
    };
    const applyFilters = () => {
        setFilter({ name: inputNameRef.current.value, ...currentFilter })
        setCurrentPage(1)

    };
    const clearFilters = () => {
        handleResetClick(selectPositionRef);
        handleResetClick(selectStatusRef);
        setCurrentFilter({ positionId: '', statusId: '' });
        setFilter({ name: '', positionId: '', statusId: '' });
        if (inputNameRef.current) {
            inputNameRef.current.value = '';
        }
    };
    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            applyFilters();
        }
    };
    const goToNextStep = () => {
        const nextStepIndex = stepIndex + 1;
        setStepIndex(nextStepIndex);
        localStorage.setItem('step', nextStepIndex.toString());
    };
    const goToStep = (direction: string, newStep?: number) => {
        let newStepIndex = newStep ?? stepIndex;
        if (direction === 'next') {
            newStepIndex += 1;
        } else if (direction === 'back' && stepIndex > 0) {
            newStepIndex -= 1;
        }
        setStepIndex(newStepIndex);
        localStorage.setItem('step', newStepIndex.toString());
    };

    return (
        <>

            <VioletBadge>
                {t('On this page are all the employees of your company. To see more information, use the hints in the table header. To start work - configure the work schedule of employees ')}
                <i className="bi  bi-calendar4-week"></i>
                {t('and choose for each service they perform')}
                <i className="bi bi-bookmark"></i>
                {t('Enable online recording if you need it')}
                <i className="bi bi-gear"></i> .
            </VioletBadge>

            <TableHeader
                title={t('List of employees')}
                paths={[{ path: location?.pathname, label: t('Personal') }, { path: location?.pathname, label: t('List of employees') }]}
                buttons={

                    < div >
                        <Row className="gx-3 justify-content-end mt-3 mt-lg-0">
                            {
                                role === 5 ||
                                <>
                                    <Col>
                                        <ExportBtn companyId={parseInt(currentCompanyId)} exportType={EntityType.EMPLOYEES} classes="d-none d-xl-inline-block"/>
                                    </Col>
                                    <Col>
                                        <button
                                            className='btn btn-outline-violet border-primary text-primary fw-normal d-flex align-items-center justify-content-center w-100 mb-3 mb-lg-0  '
                                            onClick={onOpenImportModalHandler}>
                                            <i className='bi bi-box-arrow-in-down'></i>
                                            <span className="d-none d-xl-inline-block ms-1">{t('imports')}</span>
                                        </button>
                                    </Col>
                                    <Col xs={6} xl={5} xxl={4}>
                                        <Link
                                        onClick={() => {
                                            navigate('/employees-list/add-employee');
                                            goToNextStep();
                                        }}
                                        className="btn btn-primary fw-bold w-100 text-truncate start-step my-first-step"
                                        >
                                            <i className='bi bi-plus-lg me-2'></i>{t('Add Employee')}
                                        </Link>
                                    </Col>
                                </>

                            }
                            <Col className={`${role === 5 ? "col-sm-5 col-xl-3" : "col-lg"}`}>
                                <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters}
                                               classesText={`${role === 5 ? "d-inline-block" : "d-none d-xxl-inline-block"}`} />
                            </Col>
                        </Row >
                    </div>


                }
                showFilters={showFilters}
                colSize={role === 5 ? 6 : 5}
                btnColSize={role === 5 ? 6 : 7}
            >
                <Row >
                    <Col xs={12} xl={8} xxl={9}>
                        <Row className="gx-3">
                            <Col xs={12} lg={4} className="pt-3 pt-xl-0">
                                <div className="position-relative">
                                    <Form>
                                        <Form.Control type="text" name="name" className="form-control pe-5 text-truncate"
                                            placeholder={t('Search by Name, Surname')}
                                            id='employeeList-search-field'
                                            ref={inputNameRef} onKeyDown={handleSearchKeyDown}
                                        />
                                    </Form>
                                    <div className='position-absolute top-50 end-0 translate-middle-y '>
                                        <i className='bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-3'></i>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={4} className="pt-3 pt-xl-0">
                                <CustomReactSelect
                                    ref={selectPositionRef}
                                    optionsArray={positions}
                                    placeholder={t('Select Position')}
                                    setField={handlePositionChange}
                                    fieldName={t('Select Position')}
                                    value="id"
                                    name={"employeeList-position-field"}
                                    id={"employeeList-position-field"}
                                />
                            </Col>
                            <Col xs={12} lg={4} className="pt-3 pt-xl-0">
                                <CustomReactSelect
                                    ref={selectStatusRef}
                                    optionsArray={status}
                                    placeholder={t('Status')}
                                    setField={handleStatusChange}
                                    fieldName={t('Status')}
                                    value="id"
                                    name={"employeeList-status-field"}
                                    id={"employeeList-status-field"}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} lg={4} xxl={3} className="pt-3 pl-0 pt-xl-0 ms-lg-auto" >
                        <Row className="gx-3 h-100">
                            <Col xs={6} >
                                <Button
                                    variant="outline-dark" className=" border-grey fw-normal btn-block w-100 h-100" onClick={clearFilters}
                                >
                                    {t('clear')}
                                </Button>
                            </Col>
                            <Col xs={6} >
                                <Button variant="primary" className="fw-normal btn-block w-100 btn-block h-100 " onClick={applyFilters}
                                >
                                    {t('Apply')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </TableHeader >
            <EmployeesTable filter={filter} setCurrentPage={setCurrentPage} currentPage={currentPage} accessService={accessService} position={position} goToNextStep={goToNextStep} goToStep={goToStep}  run={run} stepIndex={stepIndex} setStepIndex={setStepIndex}/>
            {showToast && <ToastComponent show={showToast} setShow={setShowToast} text={toastText} />}
            {employeeRequirementsInfo && <ImportFiles currentRequirementsInfo={employeeRequirementsInfo} isOpen={isImportModalOpen} onClose={onCloseImportModalHandler} />}
        </>
    );
};

export default EmployeesList;
