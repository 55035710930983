import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {ReactSelect, Spinner} from '@services/ui-components';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../../services/ErrorService';
import i18next from 'i18next';
import {ErrorModalComponent} from '@me-pos/error-modal';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import i18n from '@me-team/host/src/i18n';
import {CategoryPickerCategory, CategoryPickerComponent} from '@me-pos/category-picker';
import transformProductCategories from '@me-team/host/src/utils/transformProductCategories';
import {CategoryInterface, Option, ProductsFilterI, StockStatus} from '../types';
import {normalizeCategoryData} from '../services/normalizeCategoryData';
import {
  useGetBrandsQuery,
  useGetOneProductCategoryByIdQuery,
  useGetProductCategoriesQuery
} from '@me-team/host/main/products/graphql/products.hooks';
import {CompanyBrandsProxy, ProductCategoryNewProxy} from '@me-team/host/main/graphql/types';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getCategoryAndSubcategoryIds} from '@me-team/host/src/utils/utils';
import {CategoryAndSubcategoryId} from '@me-team/host/src/utils/type';

interface ProductsFilterWrapperProps {
  setFilters: React.Dispatch<React.SetStateAction<ProductsFilterI>>;
  stylesAddNewProductList?: boolean
}

const ProductsFilterWrapper: React.FC<ProductsFilterWrapperProps> = ({setFilters, stylesAddNewProductList}) => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const dropdownRef = useRef(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const categoryId: number = Number(searchParams.get('id')) || null;

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [currentNameFilter, setCurrentNameFilter] = useState<string>('');
  const [currentIdFilter, setCurrentIdFilter] = useState({categoryId: null, subCategoryId: null});
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<CategoryInterface[]>(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedStockStatus, setSelectedStockStatus] = useState(null);

  const {data: categoryByIdData, loading: categoryByIdDataLoading} = useGetOneProductCategoryByIdQuery({
    skip: !categoryId,
    variables: {
      id: categoryId
    },
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const categoryData: ProductCategoryNewProxy = categoryByIdData?.user?.company?.productCategories?.[0];
  const categoryIds: CategoryAndSubcategoryId = getCategoryAndSubcategoryIds(categoryData);
  const initCategoryName: string = categoryData?.name

  const {data: productCategoriesData, refetch: refetchCategories, loading: productCategoryLoading} = useGetProductCategoriesQuery({
    variables: {
      itemsPerPage: 1000
    },
    context: {
      errorType: 'local'
    },
    onCompleted: (data) => {
      const normalizedItems = normalizeCategoryData(data);
      const argCategory = categoryIds?.categoryId ? categoryIds?.categoryId : null
      const argSubcategory = categoryIds?.subcategoryId ? categoryIds?.subcategoryId : null

      setSelectedCategory(
        transformProductCategories(normalizedItems, argCategory, argSubcategory)
      );
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  useEffect(() => {
    if (!categoryByIdDataLoading && !productCategoryLoading && categoryByIdData) {
      if (categoryIds) {
        const normalizedCategories = normalizeCategoryData(productCategoriesData);
        const transformedCategories: CategoryInterface[] = transformProductCategories(
          normalizedCategories,
          categoryIds.categoryId,
          categoryIds.subcategoryId
        );

        setFilters((prevFilters) => ({
          ...prevFilters,
          categoryId: categoryIds?.subcategoryId
            ? categoryIds.subcategoryId
            : categoryIds?.categoryId,
        }));

        setSelectedCategory(transformedCategories);
        setCurrentNameFilter(initCategoryName)
      }
    }
  }, [categoryByIdDataLoading, productCategoryLoading, categoryByIdData, productCategoriesData]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsPickerOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const {data: brandsData, loading: brandsDataLoading} = useGetBrandsQuery({
    variables: {
      companyId: +currentCompanyId,
    },
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const brandsOptions = brandsData?.companyBrands?.map((brand: CompanyBrandsProxy) => {
    return {
      value: brand?.name,
      label: brand?.name
    }
  }) || [];

  const stockStatusOptions = Object.values(StockStatus).map((status) => {
    return {
      value: status,
      label: t(status),
    };
  });

  const handleBrandChange = (selectedOption: Option) => {
    setSelectedBrand(selectedOption);
  };
  const handleStockStatusChange = (selectedOption: Option) => {
    setSelectedStockStatus(selectedOption);
  };
  const handleCategoryIdChange = (categoryId: number) => {
    setCurrentIdFilter({categoryId, subCategoryId: null});
  };
  const handleSubCategoryIdChange = (categoryId: number, subCategoryId: number) => {
    setCurrentIdFilter((prevState) => ({
      ...prevState,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
    }));
  };

  const removeQueryParam = (searchParams: URLSearchParams) => {
    if (categoryId !== null) {
      searchParams.delete('id');
      navigate(`/warehouse/product/${currentCompanyId}`, { replace: true });
    }
  };

  const handleApplyFilter = () => {
    setFilters({nameSkuBarcode: '', categoryId: null, brand: null, stockStatus: null});
    setFilters({
      nameSkuBarcode: search ?  search.trim() : '',
      categoryId: currentIdFilter?.subCategoryId ? currentIdFilter?.subCategoryId : currentIdFilter?.categoryId,
      brand: selectedBrand?.value ? selectedBrand?.value : null,
      stockStatus: selectedStockStatus?.value ? selectedStockStatus?.value : null,
    });
    setIsPickerOpen(false);
    removeQueryParam(searchParams);
  };

  const resetSelectedCategories = (categories: CategoryInterface[]) => {
    return categories?.map((category) => ({
      ...category,
      isPicked: false,
      isSubcategoryPiked: false,
      subcategoryList: category?.subcategoryList?.map((subcategory) => ({
        ...subcategory,
        isPicked: false,
      })),
    }));
  };

  const handleClearFilter = () => {
    setFilters({
      nameSkuBarcode: null,
      categoryId: null,
      brand: null,
      stockStatus: null,
    });
    setCurrentNameFilter('');
    setSearch('');
    setSelectedCategory(null);
    setSelectedBrand(null);
    setSelectedStockStatus(null);
    setCurrentIdFilter({ categoryId: null, subCategoryId: null })
    if (selectedCategory) {
      setSelectedCategory(resetSelectedCategories(selectedCategory));
    }
    setIsPickerOpen(false);
    removeQueryParam(searchParams);
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleApplyFilter();
    }
  };

  if (categoryByIdDataLoading || productCategoryLoading) return <Spinner />

  return (
    <>
      <Row className='gx-3'>
        <Col lg={10}>
          <Row className='gx-3 h-100'>
            <Col
              lg={4}
            >
              <div className='position-relative mb-3 mb-lg-0'>
                <input
                  name='nameSkuBarcode'
                  id="productsList-nameSkuBarcode-field"
                  type='text'
                  className='form-control px-6 pe-4'
                  placeholder={t('Search by name, sku or barcode')}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={handleSearchKeyDown}
                />
                <i
                  className='bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-2'></i>
              </div>
            </Col>
            <Col
                lg={stylesAddNewProductList ? 4 : 3}
            >
              <div className='position-relative mb-3 mb-lg-0'>
                <input
                  type='text'
                  id='productsList-categoryId-field'
                  className='form-control px-4 cursor-pointer'
                  placeholder={t('Category')}
                  value={currentNameFilter}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsPickerOpen(!isPickerOpen);
                  }}
                  readOnly
                />
                <i
                  className='bi bi-chevron-down position-absolute top-50 end-0 translate-middle-y text-dark me-2 cursor-pointer'
                  onClick={(e) => {
                    e.preventDefault();
                    setIsPickerOpen(!isPickerOpen);
                  }}
                ></i>
                <div
                  ref={dropdownRef}
                  className='dropdown position-absolute opacity-1 z-2 top-10  start-0 end-0 mt-2 bg-white shadow'>
                  {productCategoriesData && selectedCategory && (
                    <CategoryPickerComponent
                      isOpen={isPickerOpen}
                      locale={i18n.language}
                      onSearchChange={(searchVealue: string) => {
                      }}
                      onChangeCategory={(currentCategory: CategoryPickerCategory) => {
                        if (currentCategory.isPicked) {
                          setCurrentNameFilter(currentCategory?.name)
                          handleCategoryIdChange(currentCategory?.id);
                          setIsPickerOpen(false);
                        } else {
                          setCurrentNameFilter('')
                        }
                      }}
                      onChangeSubcategory={(currentSubcategory, currentCategory) => {
                        if (currentSubcategory?.isPicked) {
                          setCurrentNameFilter(currentSubcategory?.name)
                          handleSubCategoryIdChange(currentCategory?.id, currentSubcategory?.id);
                          setIsPickerOpen(false);
                        } else {
                          setCurrentNameFilter('')
                        }
                      }}
                      serviceCategories={selectedCategory}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col
                lg={stylesAddNewProductList ? 4 : 2}
            >
              <ReactSelect
                id="productsList-brand-field"
                value={selectedBrand}
                options={brandsOptions}
                placeholder={t('Brand')}
                onChange={handleBrandChange}
                isSearchable={true}
                onKeyDown={handleSearchKeyDown}
              />
            </Col>
            {stylesAddNewProductList ? '' :
                <Col lg={3} >
                  <ReactSelect
                      id='productsList-stockStatus-field'
                      value={selectedStockStatus}
                      options={stockStatusOptions}
                      placeholder={t('Product Availability')}
                      onChange={handleStockStatusChange}
                      isSearchable={false}
                      onKeyDown={handleSearchKeyDown}
                  />
                </Col>
            }
          </Row>
        </Col>
        <Col lg={2}>
          <Row className='gx-3 h-100'>
            <Col
              lg={6}
              className='mb-3 mb-lg-0'>
              <Button
                type='button'
                variant='outline-dark'
                className='border-grey w-100 h-100 px-1 fw-normal'
                onClick={handleClearFilter}
              >
                {t('clear')}
              </Button>
            </Col>
            <Col
              lg={6}
              className='mb-3 mb-lg-0'>
              <Button
                variant='primary'
                className='w-100 h-100 px-1 fw-normal'
                onClick={handleApplyFilter}
              >
                {t('apply')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>

  );
};

export default ProductsFilterWrapper;