import React, {ChangeEvent, useEffect, useState} from 'react';
import {Col} from 'react-bootstrap';
import {ErrorMessage, Field, useField, useFormikContext} from 'formik';
import {TooltipCustom} from '@services/ui-components';
import {useTranslation} from 'react-i18next';

interface CriticalResidueInputProps {
  name: string,
  placeholder: string,
  label: React.ReactNode,
  maxLength?: number,
  textTooltip?: string,
  id: string,
  isDisabled?: boolean;
}

const CriticalResidueInput: React.FC<CriticalResidueInputProps> = ({
                                          name,
                                          placeholder,
                                          label,
                                          maxLength,
                                          textTooltip,
                                          id,
                                          isDisabled
                                        }) => {
  const {t} = useTranslation();
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);
  const [criticalResidue, setCriticalResidue] = useState(
    field.value != null ? String(field.value) : ''
  );

  useEffect(() => {
    setCriticalResidue(field.value != null ? String(field.value) : '');
  }, [field.value]);

  const handleCriticalResidueChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value.trim();
    const regex = /^(0|[1-9]\d*)?$/;

    if (regex.test(value) || value === '') {
      setCriticalResidue(value);
      setFieldValue(name, value === '' ? null : parseInt(value, 10));
      setFieldTouched(name, true);
    }

  };

  const handleBlurCriticalResidue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value === '0') {
      setFieldValue('criticalResidue', 0);
      setFieldTouched(name, true);
    }
    else if (value === '') {
      setCriticalResidue('');
      setFieldValue('criticalResidue', null);
    } else {
      const numeric = parseInt(value, 10);
      if (!isNaN(numeric)) {
        setFieldValue('criticalResidue', numeric);
      } else {
        setCriticalResidue('');
        setFieldValue('criticalResidue', null);
      }
    }
    setFieldTouched(name, true);
  };


  return (
    <>
      <Col md={2} className='mb-2 mb-md-0 text-start d-flex align-items-center'>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold'>
          {label}
        </label>
      </Col>
      <Col md={10} className='text-start'>
        <div className='position-relative'>
          <Field
            name={name}
            id={id}
            type='text'
            placeholder={t(placeholder)}
            className={`form-control fs-7 text-dark ${meta.touched && meta.error ? 'is-invalid' : ''}`}
            value={criticalResidue}
            onChange={handleCriticalResidueChange}
            onBlur={handleBlurCriticalResidue}
            disabled={isDisabled}
            maxLength={maxLength || 10}
          />
          {textTooltip &&
            <TooltipCustom placement='right' text={textTooltip}>
              <i className={`bi bi-info-circle fs-16 position-absolute top-50 end-0 translate-middle-y text-dark me-6 
               ${meta.error ? 'd-none' : ''}`}>
              </i>
            </TooltipCustom>
          }
        </div>
        <ErrorMessage name={name}>
          {msg => <div className='invalid-feedback d-block'>{msg}</div>}
        </ErrorMessage>
      </Col>
    </>

  );
};

export default CriticalResidueInput;