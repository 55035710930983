import React, {useState} from 'react';
import {FiltersButton, InfoBanner, TableHeader} from '@services/ui-components';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import {TechMapsFilterI} from '../types';
import {Button, Col, Row} from 'react-bootstrap';
import {UserRole} from '../../../utils/enums';
import TechnologicalMapsFilterWrapper from './TechnologicalMapsFilterWrapper';
import TechnologicalMapsTable from './TechnologicalMapsTable';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import CreateTechnologicalMap from '../CreateTechnologicalMap/CreateTechnologicalMap';

const TechnologicalMapsList: React.FC = () => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const [showFilters, setShowFilters] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [showCreateTechMapModal, setShowCreateTechMapModal] = useState(false);
  const [refetchTechMaps, setRefetchTechMaps] = useState(null);

  const handleSetRefetch = (refetchTechMaps: any) => {
    setRefetchTechMaps(() => refetchTechMaps);
  };

  const initialValueFilter: TechMapsFilterI = {
    search: null,
    categoryId: null
  }

  const [filters, setFilters] = useState<TechMapsFilterI>(initialValueFilter);

  const toggleFilters = () => setShowFilters(!showFilters);
  const handleCreateTechMap = () => setShowCreateTechMapModal(prevState => !prevState);

  return (
    <div className='mb-9 px-2 px-lg-0'>
      <InfoBanner>
        <p className='fs-7 mb-0'>{t('List of technological maps. To set products, click (INSERT ICON) “Products of the technological map”. To link with services, click (INSERT ICON) “Related services\".')}</p>
      </InfoBanner>
      <TableHeader
        title={t('List of Technological Maps')}
        paths={[
          {path: `/warehouse/technological-maps/${currentCompanyId}`, label: t('Warehouse accounting')},
          {path: `/warehouse/technological-maps/${currentCompanyId}`, label: t('List of Technological Maps')},
        ]}

        colSize={6}
        btnColSize={6}
        buttons={
          <Row
            className='gx-1 w-100  d-flex flex-lg-nowrap justify-content-lg-end gap-3'>
            {
              !isEmployeeRole
                ?
                <Col
                  xs={12}
                  md={6}
                  lg={9}
                  xl={6}
                  xxl={5}
                >
                  <Button
                    variant='primary'
                    className='w-100 text-white mb-3 mb-lg-0 '
                    onClick={handleCreateTechMap}
                  >
                    <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                    {t('Create New Technological Map')}
                  </Button>
                </Col>
                : null
            }
            <Col
              xs={12}
              md={6}
              lg={3}
              xl={2}
              xxl={2}
              className='px-0'
            >
              <FiltersButton filter={filters} showFilters={showFilters} onClick={toggleFilters}
                             classes="mb-3 mb-lg-0"/>
            </Col>
          </Row>
        }
        showFilters={showFilters}
      >
        <TechnologicalMapsFilterWrapper setFilters={setFilters}/>
      </TableHeader>
      <TechnologicalMapsTable
        filters={filters}
        showCreateTechMapModal={showCreateTechMapModal}
        handleCreateTechMap={handleCreateTechMap}
        setRefetchTechMaps={handleSetRefetch}
      />

      <CreateTechnologicalMap
        show={showCreateTechMapModal}
        onClose={handleCreateTechMap}
        refetchTechMaps={refetchTechMaps}
      />

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default TechnologicalMapsList;