import React, {useState} from 'react';
import {FiltersButton, InfoBanner, Spinner, TableHeader} from '@services/ui-components';
import {Col, Row} from 'react-bootstrap';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {TechMapsFilterI} from '../types';
import {useParams} from 'react-router-dom';
import {
  useGetOneTechMapQuery
} from '@me-team/host/main/technologicalMaps/graphql/technologicalMaps.hooks';
import ErrorService from '../../../services/ErrorService';
import TechMapServicesTable from './TechMapServicesTable';
import TechMapServicesFilterWrapper from './TechMapServicesFilterWrapper';

const TechMapServices: React.FC = () => {
  const {t} = useTranslation();
  const {techMapId} = useParams();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const [showFilters, setShowFilters] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const {
    data: oneTechMapData,
    loading: oneTechMapLoading
  } = useGetOneTechMapQuery({
    variables: {
      techMapId: +techMapId,
    },
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });
  const techMapName = oneTechMapData?.user?.company?.technologicalMaps?.technologicalMaps?.[0].name

  const initialValueFilter: TechMapsFilterI = {
    search: null,
    categoryId: null
  }

  const [filters, setFilters] = useState<TechMapsFilterI>(initialValueFilter);

  const toggleFilters = () => setShowFilters(!showFilters);

  if (oneTechMapLoading || !currentCompanyId) return <Spinner/>;

  return (
    <div className='mb-9 px-2 px-lg-0'>
      <InfoBanner>
        <p className='fs-7 mb-0'>
          {t('Select the services for which you want to use the technological card is to be used (the products from the technological card will be offered for write-off after the service is completed).')}
        </p>
      </InfoBanner>
      <TableHeader
        title={`${t('titleTechCartServices.Add Service to')} ${techMapName}`}
        paths={[
          {
            path: `/warehouse/technological-map/${currentCompanyId}/${techMapId}/services`,
            label: t('Warehouse accounting')
          },
          {
            path: `/warehouse/technological-maps/${currentCompanyId}`,
            label: t('List of Technological Maps')
          },
          {
            path: `/warehouse/technological-map/${currentCompanyId}/${techMapId}/services`,
            label: `${t('Add service to')} ${techMapName}`
          },
        ]}
        colSize={8}
        btnColSize={4}
        buttons={
          <Row className='gx-1 w-100  d-flex flex-lg-nowrap justify-content-lg-end gap-3'>
            <Col
              xs={12}
              md={6}
              lg={5}
              xxl={4}
              className='px-0'
            >
              <FiltersButton
                filter={filters} showFilters={showFilters}
                onClick={toggleFilters}
                classes="my-3 my-lg-0"
              />
            </Col>
          </Row>
        }
        showFilters={showFilters}
      >
        <TechMapServicesFilterWrapper setFilters={setFilters}/>
      </TableHeader>
      <TechMapServicesTable
        filters={filters}
      />

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default TechMapServices;