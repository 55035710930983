import React, {useState} from 'react';
import {CalendarWrapperComponent} from './CalendarWrapperComponent';
import {CalendarFilterI, getLogbookDates} from './constants';
import {useGetInfoCompanyQuery} from '@me-team/host/main/company/graphql/company.hooks';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {currentBranchIdVar, currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import EmptyList from '@services/ui-components/src/EmptyList/EmptyList';
import {useGetBookingsListQuery} from '@me-team/host/main/logbook/graphql/logbook.hooks';
import {Spinner} from '@services/ui-components';

type CalendarWrapperProps = {
  filters: CalendarFilterI,
};

const CalendarWrapper: React.FC<CalendarWrapperProps> = ({filters}) => {
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const currentBranchId = useReactiveVar(currentBranchIdVar);
  const [showPreloader, setShowPreloader] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const getDates = currentBranchId && getLogbookDates(currentBranchId.toString())
  const [isBookingRecords, setIsBookingRecords] = useState<boolean>(false);

  const {data: infoCompanyData, loading} = useGetInfoCompanyQuery({
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })

  const {data: bookingListData, loading: loadingBookingList} = useGetBookingsListQuery({
    variables: {
      branchId: +currentBranchId,
      isDeleted: false,
      startDate: getDates?.startDate && getDates?.startDate,
      endDate: getDates?.endDate && getDates?.endDate,
      statusId: filters?.statusId && filters?.statusId,
      serviceId: filters?.serviceId && filters?.serviceId,
    },
    skip: !filters?.statusId && !filters?.serviceId,
    onCompleted: (data) => {
      const isBooking = data?.user?.company?.branches?.[0].bookingsList?.length > 0
      setIsBookingRecords(isBooking)
    },
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })

  const timeZone = infoCompanyData?.user?.company?.timeZone?.name

  const showPreloaderHandler = () => {
    setShowPreloader(true)
  }
  const hidePreloaderHandler = () => {
    setShowPreloader(false);
  }

  const secondComponent = React.useMemo(() => <CalendarWrapperComponent
    currentCompanyId={+currentCompanyId}
    currentBranchId={+currentBranchId}
    filters={filters}
    timeZone={timeZone}
    showPreloaderHandler={showPreloaderHandler}
    hidePreloaderHandler={hidePreloaderHandler}
  />, [
    currentCompanyId,
    currentBranchId,
    JSON.stringify(filters)]);

  return (<>
    {
      !isBookingRecords && (filters.serviceId || filters.statusId) ?
        <EmptyList title='No data found for the given values' customClass='mb-5' />
        : null
    }
    {showPreloader && <Spinner/>}
    {secondComponent}
    {isErrorModalOpen ?
        <ErrorModalComponent
            i18n={i18next}
            onClose={() => {
              setIsErrorModalOpen(null)
            }}
            isOpen={!!isErrorModalOpen}
        currentError={isErrorModalOpen}
      /> : null
    }
  </>)
}

export default CalendarWrapper;
