import React, {ChangeEvent} from 'react';
import {Field, useFormikContext} from 'formik';

interface QuantityInputProps {
  unitGroup: string;
  id: string;
  name: string;
}

const QuantityInput:React.FC<QuantityInputProps> = ({unitGroup, id, name}) => {
  const {setFieldValue, setFieldTouched} = useFormikContext();

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9]/g, '').trim();
    const regex = /^[1-9]\d{0,9}$/;

    if (value.startsWith('0')) {
      value = value.replace(/^0+/, '');
    }

    if (regex.test(value) || value === '') {
      await setFieldValue(name, value === '' ? '' : parseInt(value, 10));
      setFieldTouched(name, true);
    }
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value === '' || parseInt(value, 10) < 1) {
      setFieldValue(name, 1);
    } else {
      setFieldValue(name, parseInt(value, 10));
    }
    setFieldTouched(name, true);
  };

  const formattedUnitGroup = unitGroup.charAt(0).toUpperCase() + unitGroup.slice(1);

  return (
    <>
      <div
        className='position-relative d-inline-block'
        style={{height: '50px'}}
      >
        <span
          className='position-absolute text-grey fs-8 pe-none'
          style={{left: '0.75rem', top: '0.25rem'}}
        >
          {formattedUnitGroup}
        </span>
        <Field
          name={name}
          id={id}
          type='text'
          className='form-control fs-16 text-dark pb-1 pt-3 h-100'
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={10}
        />
      </div>
    </>
  );
};

export default QuantityInput;