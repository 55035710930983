import { Modal, Button, Col } from 'react-bootstrap';
import { Formik, Form,  FormikHelpers } from 'formik';
import { useTranslation } from "react-i18next";
import {WearhousesModalProps} from "./modalsUtils"
import React from 'react';
import {useUpdateStorageMutation} from '@me-team/host/main/warehouses/graphql/warehouses.hooks';

const DeleteWarehouseModals: React.FC<WearhousesModalProps> = ({ show, handleModal, refetch, warehouse, toggleShowToast  }) => {
    const { t } = useTranslation();
    const initialValues = {
        name: warehouse?.name,
        storageType: warehouse?.type?.id,
        description: warehouse?.description || '',
        deleted: true
    };
    const [updateStorage] = useUpdateStorageMutation({
        context: {
            errorType: 'local',
        },
    });
    const countProductsQuantity = data => data.reduce((count, item) => item.quantity > 0 ? count + 1 : count, 0)
    const handleSubmit = async (values: typeof initialValues, { setFieldError }: FormikHelpers<typeof initialValues>) => {
        try {
            const inputValues = {...values, description: values?.description.length > 0 ? values.description : null}
            const response = await updateStorage({
                variables: {
                    storageId: warehouse?.id,
                    input: inputValues
                }
            });
            refetch()
        } catch (error) {
            console.error('Error creating position:', error);
        }
        handleModal()
        toggleShowToast(t('Deleted'))
    };
    const hasRemainingProducts = warehouse?.storageProductRelations?.length > 0 &&
        countProductsQuantity(warehouse?.storageProductRelations || []) > 0;
    return (
        <>
            {hasRemainingProducts ? (
                <Modal
                    show={show}
                    onHide={() => handleModal()}
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header className="border-0" closeButton>
                        <h5 className='text-danger mb-0'>{t('Warehouse Deletion Impossible')}</h5>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        {t('Action Cannot Be Completed. Please delete or transfer the contents to another warehouse.')}
                    </Modal.Body>
                    <Modal.Footer className="border-0">
                        <Col>
                            <Button
                                className="btn btn-danger border-grey w-100 h-100 fw-normal"
                                onClick={() => handleModal()}
                            >
                                OK
                            </Button>
                        </Col>
                    </Modal.Footer>
                </Modal>
            ) : (
                <Modal
                    show={show}
                    onHide={() => handleModal()}
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header className="border-0" closeButton>
                        <h5 className=' mb-0'>{t('Deleting of warehouse')}</h5>
                    </Modal.Header>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {() => (
                            <Form>
                                <Modal.Body className="text-center">
                                    <p className='mb-1'>
                                        {t('Selected warehouse will be deleted. Do you confirm this operation?')}
                                    </p>
                                </Modal.Body>
                                <Modal.Footer className="border-0">
                                    <Col>
                                        <Button
                                            variant="outline-primary"
                                            className="btn btn-outline-primary border-grey w-100 h-100 fw-normal"
                                            onClick={() => handleModal(null)}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            className="btn btn-primary w-100 text-truncate"
                                            type="submit"
                                        >
                                            {t('Delete')}
                                        </Button>
                                    </Col>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            )}
        </>
    );
};

export default DeleteWarehouseModals;




