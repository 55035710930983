import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'react-bootstrap';
import {EmptyList} from '../index';

interface EmptyListComponentProps {
  isFilterNotEmpty: boolean;
  buttonName: React.ReactNode;
  emptyFilterTitle: string;
  emptyListTitle: string;
  onClickButton?: () => void;
  isEmployeeRole?: boolean;
  visibilityBtn?: boolean;
}

const RenderEmptyList: React.FC<EmptyListComponentProps> = ({
                                                              isFilterNotEmpty,
                                                              emptyFilterTitle,
                                                              emptyListTitle,
                                                              buttonName,
                                                              onClickButton,
                                                              isEmployeeRole,
                                                              visibilityBtn
                                                            }) => {
  const {t} = useTranslation();

  return (
    !isFilterNotEmpty ? (
      <EmptyList title={emptyListTitle}>
        <Button
          variant='primary'
          className={`mb-lg-0 ${isEmployeeRole ? 'd-none' : ''}`}
          onClick={onClickButton}
        >
          {buttonName}
        </Button>
      </EmptyList>
    ) : (
      <EmptyList title={emptyFilterTitle}>
        <Button
          variant='primary'
          className={`mb-lg-0  ${!isEmployeeRole && visibilityBtn ? 'd-block' : 'd-none'}`}
          onClick={onClickButton}
        >
          {buttonName}
        </Button>
      </EmptyList>
    )
  )
    ;
};

export default RenderEmptyList;
