import React from 'react';
import {ServiceCategoryNewProxy, TechMapCategoryNewProxy} from '@me-team/host/main/graphql/types';

interface RenderCategoryNameProps {
  category: ServiceCategoryNewProxy | TechMapCategoryNewProxy;
}

const RenderCategoryName: React.FC<RenderCategoryNameProps> = ({category}) => {
  if (!category) return null;

  const isSubCategory = !!category?.parentCategory?.name;
  const categoryName = category?.parentCategory?.name ? category?.parentCategory?.name : category?.name;
  const subCategoryName = category?.parentCategory?.name ? category?.name : '';

  return (
    <div>
      <span className='text-dark d-block'>
        {isSubCategory ? subCategoryName : categoryName}
      </span>
      {
        isSubCategory
          ?
          <span className='fs-7 text-grey'>
            {`(${categoryName})`}
          </span>
          : null
      }
    </div>
  );
};

export default RenderCategoryName;