import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {ErrorMessage, Form, Formik, FormikHelpers} from 'formik';
import {useTranslation} from "react-i18next";
import {ReactSelect, SpinnerLoader} from "@services/ui-components";
import {WearhousesModalProps} from "../../Warehouse/modals/modalsUtils";
import {
    useUpdateStorageProductRelationMutation
} from "@me-team/host/main/warehouses_goods/graphql/warehouses_goods.hooks";
import {StorageProductRelationActionType, UpdateStorageProductRelationInput} from "@me-team/host/main/graphql/types";
import {useGetUnitsQuery} from "@me-team/host/main/products/graphql/products.hooks";
import {Option} from "../../Products/types";
import {writeOffSchema} from "../constants";
import QuantityInput from "../../AddNewProductList/AddProductListModal/QuantityInput";

const WriteOffModal: React.FC<WearhousesModalProps> = ({ show, handleModal, refetch, storages, warehouse, storageProductRelationId, chosenProduct, writeOffReasons, toggleShowToast}) => {
    const { t } = useTranslation();
    const isDisabled = warehouse?.default
    const initialValues: UpdateStorageProductRelationInput = {
        quantity: null,
        unit: null,
        writeOffReason: null
    }
    const [unitOfMeasurements, setUnitOfMeasurements] = useState([])
    const {data: unitsData} = useGetUnitsQuery({
        context: {
            errorType: 'local'
        },
    })

    const [updateStorageProductRelation] = useUpdateStorageProductRelationMutation({
        context: {
            errorType: 'local',
        },
    });
    const handleSubmit = async (values: typeof initialValues, { setFieldError }: FormikHelpers<typeof initialValues>) => {
        try {
            const response = await updateStorageProductRelation({
                variables: {
                    storageProductRelationId: +chosenProduct?.id,
                    input: {... values, quantity:  +values?.quantity, action: StorageProductRelationActionType.WriteOff}
                }
            });
            refetch()
            handleModal()
            toggleShowToast(t('Saved'))
        } catch (error) {
            if (error.graphQLErrors?.[0].message.startsWith('Write off quantity')) {
                setFieldError('quantity', t("In stock less than") + ' ' + values?.quantity + unitOfMeasurements.find(i => i.value === values?.unit)?.label );
                return
            } else {
                console.error(error);
            }
        }
    };
    useEffect(() => {
        if (chosenProduct) {
            const optionsUnitOfMeasurements = unitsData?.relations?.units
                ?.filter((unit) => {
                    const unitGroupId = chosenProduct?.product?.unitGroup?.id;
                    const alternativeUnitGroupId = chosenProduct?.product?.alternativeUnit?.unitGroup?.id;
                    return unit.unitGroup.id === unitGroupId ||
                        (alternativeUnitGroupId && unit.unitGroup.id === alternativeUnitGroupId);
                })
                ?.map((unit) => ({
                    value: unit.id,
                    label: unit.name,
                }))
            setUnitOfMeasurements(optionsUnitOfMeasurements);
        }
    }, [chosenProduct]);

    return (
        <Modal size="lg" show={show} onHide={handleModal} centered>
            <Modal.Header className="border-0" closeButton>
                <h5 className="m-0">{t('Write-off the product')}  {chosenProduct?.product?.name} </h5>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={writeOffSchema(t)}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                >
                    {({  setFieldValue, isSubmitting }) => (
                        <Form>
                            <Row className={'mx-0'}>
                                <Col lg={2} xs={4} className="d-flex pr-0">
                                    <label htmlFor="createWarehouseModal-name-field" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">{t('Quantity for write-off')}</span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>
                                <Col lg={10} xs={8}>
                                    <QuantityInput
                                        name='quantity'
                                        id='addsProduct-quantity-field'
                                        placeholder={t('Add product amount')}
                                        maxLength={15}
                                    />
                                    <ErrorMessage name="quantity" component="div" className="fs-7 m-0 p-1 text-danger"  />
                                </Col>
                            </Row>
                            <Row className="pt-3 mx-0">
                                <Col lg={2} xs={4} className="d-flex pr-0">
                                    <label htmlFor="unit" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">{t('Unit of measurements')}</span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>
                                <Col lg={10} xs={8}>
                                    <ReactSelect
                                        id='addProduct-unit-field'
                                        name='unit'
                                        placeholder={t('Select an unit')}
                                        options={unitOfMeasurements}
                                        onChange={(selectedOption: Option) => setFieldValue('unit', selectedOption.value, true )}
                                        isSearchable={false}
                                    />

                                    <ErrorMessage name="unit" component="div" className="fs-7 m-0 p-1 text-danger"  />
                                </Col>
                            </Row>
                            <Row className="pt-3 mb-3 mx-0">
                                <Col lg={2} xs={4} className="d-flex pr-0">
                                    <label htmlFor="writeOffReason" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">{t('Write-off reason')}</span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>
                                <Col lg={10} xs={8}>
                                    <ReactSelect
                                        options={writeOffReasons}
                                        name='writeOffReason'
                                        onChange={(option) => setFieldValue('writeOffReason', option.value, true)}
                                        disabled={isDisabled}
                                        placeholder={t('Select write-off reason')}
                                        isSearchable={false}
                                    />
                                    <ErrorMessage name="writeOffReason" component="div" className="fs-7 m-0 p-1 text-danger"  />
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-end pt-6">
                                <Col xs={6} sm="auto" className="mb-sm-0 pe-2">
                                    <Button
                                        variant="outline-primary"
                                        className="fw-normal custom-btn rounded-1"
                                        onClick={handleModal}
                                    >
                                        {t("Cancel")}
                                    </Button>
                                </Col>
                                <Col xs={6} sm="auto" className="mb-sm-0 ps-2">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="fw-bold custom-btn rounded-1"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <SpinnerLoader variant="light" loadingText={t("Saving...")} /> : t('Write-Off')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default WriteOffModal;