import {
    CategoryServicesTableInterface,
    CategoryTableInterface,
    ChildCategoriesTableInterface,
} from "../../interfaces/interfaces";
import {ProductCategoryNewProxy, TechMapCategoryNewProxy} from "@me-team/host/main/graphql/types";

class CategoryTableInnerService {
    public static ProductCategoryTableInner = (productCategories: ProductCategoryNewProxy[] | null): CategoryTableInterface[] => {
        return this.mapCategory(
            productCategories || [],
            (category) => category.products || [],
            (category) => category.childCategories || []
        );
    };

    public static TechMapCategoryTableInner = (techMapCategories: TechMapCategoryNewProxy[] | null): CategoryTableInterface[] => {
        return this.mapCategory(
            techMapCategories || [],
            (category) => category.techMaps || [],
            (category) => category.childCategories || []
        );
    };

    private static mapCategory<
        TCategory extends {
            id: number;
            name: string;
            comment?: string | null;
            deleted: boolean;
            childCategories?: TCategory[] | null;
            parentCategory?: TCategory | null;
        },
        TService extends {
            id: number;
            name: string;
            deleted: boolean;
        }
    >(
        categories: TCategory[],
        serviceAccessor: (category: TCategory) => TService[] | null,
        childAccessor: (category: TCategory) => TCategory[] | null
    ): CategoryTableInterface[] {
        return categories.map((category: TCategory): CategoryTableInterface => {
            return {
                id: category.id,
                name: category.name,
                comment: category.comment || "",
                deleted: category.deleted,
                categoryServices: (serviceAccessor(category) || []).map((service: TService): CategoryServicesTableInterface => ({
                    id: service.id,
                    name: service.name,
                    deleted: service.deleted
                })),
                childCategories: (childAccessor(category) || []).map((childCategory: TCategory): ChildCategoriesTableInterface => ({
                    id: childCategory.id,
                    name: childCategory.name,
                    comment: childCategory.comment || "",
                    parentCategory: childCategory.parentCategory || null,
                    deleted: childCategory.deleted,
                    categoryServices: (serviceAccessor(childCategory) || []).map((service: TService): CategoryServicesTableInterface => ({
                        id: service.id,
                        name: service.name,
                        deleted: service.deleted
                    }))
                }))
            };
        });
    }
}

export default CategoryTableInnerService;