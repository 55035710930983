import {DateTime} from 'luxon';
import {TFunction} from 'i18next';
import {CategoryObject} from './type';

export const currentDate = DateTime.now().toFormat('dd.LL.yyyy');

// yyyy-MM-dd HH:mm:ss -> dd.MM.yy HH:mm
export const formatDateTime = (dateTime: string, timeZone: string): string => {
  const date = DateTime.fromFormat(dateTime, 'yyyy-MM-dd HH:mm:ss', { zone: 'UTC' })
    .setZone(timeZone);
  return date.toFormat('dd.MM.yy HH:mm')
};

export const formatDate = (initialDate: string) => {
  const date = DateTime.fromISO(initialDate);
  return date.toFormat('dd.MM.yyyy');
};

export const formatTime = (initialTime: string) => {
  const time = DateTime.fromFormat(initialTime, 'HH:mm:ss');
  return time.toFormat('HH:mm');
};

export const convertDateString = (dateString: string) => {
  const parsedDate = DateTime.fromISO(dateString);
  return parsedDate.toFormat("yyyy-MM-dd");
};

// 2024-07-05T16:38:08+00:00 --> 29.01.2024 15:00
export const convertDateFormat = (inputDate: string, format="dd.LL.yyyy HH:mm" ): string => {
  const parsedDate = DateTime.fromISO(inputDate, { zone: 'utc' });
  if (!parsedDate.isValid) {
    throw new Error("Invalid date format");
  }
  return parsedDate.toFormat(format);
};

export const formatTimeZone = (tz: string) => {
  const offset = DateTime.local().setZone(tz).offset;
  const sign = offset >= 0 ? '+' : '-';
  const hours = Math.floor(Math.abs(offset) / 60);
  return `GMT${sign}${hours}`;
};

export const ratingOptions = [
  {value: 1, label: '1'},
  {value: 2, label: '2'},
  {value: 3, label: '3'},
  {value: 4, label: '4'},
  {value: 5, label: '5'}
];

export const getReviewTypeOptions = (t: TFunction) => [
  {value: 'original', label: t('Original')},
  {value: 'edited', label: t('ToolTypeForFeedbackType.Edited')}
];

export const getSortOptions = (t: TFunction) => [
  {
    value: ['createdAt', 'DESC'],
    label: t('New first')
  },
  {
    value: ['createdAt', 'ASC'],
    label: t('Old first')
  },
  {
    value: ['rating', 'DESC'],
    label: t('Good first')
  },
  {
    value: ['rating', 'ASC'],
    label: t('Bad first')
  }
];

export const downloadQRCode = async (urlQRCode: string) => {
  try {
    const response = await fetch(urlQRCode, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const buffer = await response.arrayBuffer();
    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "QR_Code.png");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    console.error('Failed to download image', err);
  }
};

export const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// getting category and subcategory IDs using only the main ID
export const getCategoryAndSubcategoryIds = <T extends CategoryObject<TParentCategory>, TParentCategory extends { id: number } | null = { id: number }>(
  inputCategoryObject: T
) => ({
  categoryId: inputCategoryObject?.parentCategory?.id ?? inputCategoryObject?.id,
  subcategoryId: inputCategoryObject?.parentCategory ? inputCategoryObject?.id : null,
});