import React, { ChangeEvent, useEffect, useState } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

interface CriticalResidueInputProps {
    name: string;
    placeholder: string;
    label?: React.ReactNode;
    maxLength?: number;
    textTooltip?: string;
    id: string;
    isDisabled?: boolean;
}

const QuantityInput: React.FC<CriticalResidueInputProps> = ({
                                                                name,
                                                                placeholder,
                                                                maxLength ,
                                                                id,
                                                                isDisabled,
                                                            }) => {
    const resolvedMaxLength = maxLength ?? 15;
    const { t } = useTranslation();
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field, meta] = useField(name);

    const handleCriticalResidueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();
        const regex = /^[1-9]\d*$/;

        if (regex.test(value) || value === '') {
            setFieldValue(name, value === '' ? null : value);
        }
    };

    const handleBlurCriticalResidue = () => {
        const numeric = parseInt(field.value, 10);

        if (!field.value || isNaN(numeric) || numeric <= 0) {
            setFieldValue(name, null);
        } else {
            setFieldValue(name, numeric);
        }
        setFieldTouched(name, true);
    };
    return (
        <>
            <Field
                name={name}
                id={id}
                type="text"
                placeholder={t(placeholder)}
                className={`form-control fs-7 text-dark ${
                    meta.touched && meta.error ? 'is-invalid' : ''
                }`}
                value={field.value || ''}
                onChange={handleCriticalResidueChange}
                onBlur={handleBlurCriticalResidue}
                disabled={isDisabled}
                maxLength={resolvedMaxLength}
            />
        </>
    );
};


export default QuantityInput;
