/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetJobsDocument = gql`
    query GetJobs($id: Int, $companyId: Int) {
  job(companyId: $companyId) {
    importJobs(id: $id) {
      id
      uuid
      status
      createdAt(format: "Y-m-d H:i:s")
      filename
      type
      validationFilename
      jobType
      company {
        id
        name
        deleted
        address
        phone
        bookingQrLink
        isPremiumOn
        paidFeaturesPaginated {
          itemsPerPage
        }
        bookingQrLink
        isPremiumOn
      }
    }
    exportJobs {
      id
      status
      createdAt(format: "Y-m-d H:i:s")
      filename
      type
      company {
        id
        name
        deleted
        address
        phone
        bookingQrLink
        isPremiumOn
        paidFeaturesPaginated {
          itemsPerPage
        }
        bookingQrLink
        isPremiumOn
      }
      jobType
    }
  }
}
    `;

/**
 * __useGetJobsQuery__
 *
 * To run a query within a React component, call `useGetJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
      }
export function useGetJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
        }
export function useGetJobsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
        }
export type GetJobsQueryHookResult = ReturnType<typeof useGetJobsQuery>;
export type GetJobsLazyQueryHookResult = ReturnType<typeof useGetJobsLazyQuery>;
export type GetJobsSuspenseQueryHookResult = ReturnType<typeof useGetJobsSuspenseQuery>;
export type GetJobsQueryResult = Apollo.QueryResult<GetJobsQuery, GetJobsQueryVariables>;
export type GetJobsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  companyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetJobsQuery = { __typename?: 'Query', job: { __typename?: 'JobContainerProxy', importJobs?: Array<{ __typename?: 'ImportJobProxy', id: number, uuid: string, status?: number | null, createdAt?: string | null, filename?: string | null, type?: string | null, validationFilename?: string | null, jobType: Types.JobType, company: { __typename?: 'CompanyProxy', id: number, name: string, deleted: boolean, address: string, phone: string, bookingQrLink: string, isPremiumOn: boolean, paidFeaturesPaginated: { __typename?: 'PaidFeaturePaginated', itemsPerPage?: number | null } } }> | null, exportJobs?: Array<{ __typename?: 'ExportJobProxy', id: number, status?: number | null, createdAt?: string | null, filename?: string | null, type?: string | null, jobType: Types.JobType, company: { __typename?: 'CompanyProxy', id: number, name: string, deleted: boolean, address: string, phone: string, bookingQrLink: string, isPremiumOn: boolean, paidFeaturesPaginated: { __typename?: 'PaidFeaturePaginated', itemsPerPage?: number | null } } }> | null } };
