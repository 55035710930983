import React from 'react';
import { Step, Placement } from 'react-joyride';
import MyFirstStepTooltip from './MyFirstStepTooltip';
import JoyrideTooltip from './JoyrideTooltip/JoyrideTooltip';
import StartStep from './StepsComponents/StartStep';
import AddEmployeeStep from './StepsComponents/AddEmployeeStep';
import AccessSwitcherStep from './StepsComponents/AccessSwitcherStep';
import BookingModalStep from './StepsComponents/BookingModalStep';
import ScheduleOpen from './StepsComponents/ScheduleOpenStep';
import BookingStep from './StepsComponents/BookingStep';
import WorkScheduleStep from './StepsComponents/WorkScheduleStep';
import WorkScheduleModalStep from './StepsComponents/WorkScheduleModalStep';
import ScheduleStepCheck from './StepsComponents/ScheduleStepCheck';
import ProvideServicesStep from './StepsComponents/ProvideServicesStep';
import AddServiceStep from './StepsComponents/AddServiceStep';
import ServicePerformer from './StepsComponents/ServicePerformer';
import ServiceProvidersStep from './StepsComponents/ServiceProvidersStep';
import OnlineCallStep from './StepsComponents/OnlineCallStep';
import OnlineRecordStep from './StepsComponents/OnlineRecordStep';
import UrlStep from './StepsComponents/UrlStep';
import GenerateQr from './StepsComponents/GenerateQr';

interface CustomStep extends Step {
    target: string;
    content: JSX.Element;
    disableBeacon?: boolean;
    showSkipButton?: boolean;
    hideCloseButton?: boolean;
    placement?: Placement;
    spotlightClicks?: boolean;
    disableOverlay?: boolean;
    disableOverlayClose?: boolean
    styles?: {
        options?: {
            zIndex?: number;
        };
    };
}

export type StepFunctions = {
    setShowStartStep?: React.Dispatch<React.SetStateAction<boolean>>;
    goToStep?: (direction?: string, newStep?: number) => void;
    goToNextStep?: () => void;
    booleanValue?: boolean
    setRun?: (run?: boolean) => void;
    setModalJoyride?:  (boolean: boolean) => void;
};

export const getJoyrideSteps = ({ goToStep, goToNextStep, booleanValue, setRun, setModalJoyride }: StepFunctions = {}): CustomStep[] => {
    const windowWidth = window?.innerWidth
    return  [
        {
            // Index 0
            target: '.start-step',
            content: (
                <JoyrideTooltip>
                    <StartStep goToNextStep={goToNextStep} goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlayClose: true,

        },
        {
            // Index 1
            target: '.my-first-step',
            content: (
                <JoyrideTooltip>
                    <MyFirstStepTooltip goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlayClose: true,
            disableScrolling: true,
        },
        {
            // Index 2
            target: '.add-employee-step',
            content: (
                <JoyrideTooltip adaptiveStyle>
                    <AddEmployeeStep goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            placement: windowWidth > 790 ? 'left-start' : "top",
            disableOverlayClose: true,
            disableScrolling: true,
        },
        {
            // Index 3
            target: '.access-switcher',
            content: (
                <JoyrideTooltip adaptiveStyle>
                    <AccessSwitcherStep goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            placement: windowWidth > 630 ? 'bottom' : 'right',
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlayClose: true,
        },
        {
            // Index 4
            target: '.booking-btn-step',
            content: (
                <JoyrideTooltip adaptiveStyle>
                    <BookingStep goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            placement: windowWidth > 970 ? 'bottom' : 'left',
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlayClose: true,
        },
        {
            // Index 5
            target: '.booking-modal-step',
            content: (
                <JoyrideTooltip>
                    <BookingModalStep goToNextStep={goToNextStep} />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlay: true,
            disableOverlayClose: true,
            spotlightPadding: 10,
            placement:  windowWidth > 745 ?  'right' : 'bottom' ,
            styles: {
                options: {
                    zIndex: 2001,
                }
            }
        },
        {
            // Index 6
            target: '.schedule-link-step',
            content: (
                <JoyrideTooltip adaptiveStyle >
                    <ScheduleOpen goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            placement: windowWidth > 970 ? 'bottom' : 'left',
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlayClose: true,
        },
        {
            // Index 7
            target: '.work-schedule-step',
            content: (
                <JoyrideTooltip >
                    <WorkScheduleModalStep  />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlayClose: true,
            placement: 'right',
            styles: {
                options: {
                    zIndex: 2001,
                }
            }

        },
        {
            // Index 8
            target: '.work-schedule-modal',
            content: (
                <JoyrideTooltip >
                    <WorkScheduleStep  />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlay: true,
            disableOverlayClose: true,
            placement: 'right',
            spotlightPadding : 0,
            styles: {
                options: {
                    zIndex: 2001,
                    overlayColor: 'rgba(0, 0, 0, 0)'
                },
            }
        },
        {
            // Index 9
            target: '.calendar-wrapper',
            content: (
                <JoyrideTooltip>
                    <ScheduleStepCheck goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlayClose: true,
        },
        {
            // Index 10
            target: '.provide-services',
            content: (
                <JoyrideTooltip>
                    <ProvideServicesStep goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlayClose: true,
            disableScrolling: true,
        },
        {
            // Index 11
            target: '.add-service-step',
            content: (
                <JoyrideTooltip >
                    < AddServiceStep goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            placement: windowWidth > 790 ? 'left-start' : "top",
            disableOverlayClose: true,
            disableScrolling: true,
            styles: {
                options: {
                    zIndex: 5001,
                }
            }
        },
        {
            // Index 12
            target: '.service-performer',
            content: (
                <JoyrideTooltip adaptiveStyle>
                    <ServicePerformer goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            placement: windowWidth > 970 ? 'bottom' : 'left',
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlayClose: true,
            disableScrolling: true,
        },
        {
            // Index 13
            target: '.service-providers',
            content: (
                <JoyrideTooltip>
                    <ServiceProvidersStep goToStep={goToStep} booleanValue={booleanValue} />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlayClose: true,
        },
        {
            // Index 14
            target: '.online-registration-сall',
            content: (
                <JoyrideTooltip >
                    <OnlineCallStep goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            placement: windowWidth > 1030 ? 'right' : 'bottom',
            disableOverlayClose: true,
        },
        {
            // Index 15
            target: '.online-record',
            content: (
                <JoyrideTooltip >
                    <OnlineRecordStep goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            placement: windowWidth > 1030 ? 'right' : 'bottom',
            disableOverlayClose: true,
            disableScrolling: true,
        },
        {
            // Index 16
            target: '.url-step',
            content: (
                <JoyrideTooltip >
                    <UrlStep goToStep={goToStep} />
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlayClose: true,
            disableScrolling: true,
        },
        {
            // Index 17
            target: '.generate-qr',
            content: (
                <JoyrideTooltip>
                    <GenerateQr goToStep={goToStep} setRun={setRun} setModalJoyride={setModalJoyride}/>
                </JoyrideTooltip>
            ),
            disableBeacon: true,
            showSkipButton: false,
            hideCloseButton: true,
            disableOverlayClose: true,
        },
    ];
}
