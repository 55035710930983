import React, {useEffect, useRef, useState} from 'react';
import {Form} from 'react-bootstrap';
import {Pagination, RenderEmptyList, Spinner, TooltipCustom} from '@services/ui-components';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {truncateText} from '../constants';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {
  useGetTechMapServicesQuery,
  useUpdateTechMapServicesMutation
} from '@me-team/host/main/technologicalMaps/graphql/technologicalMaps.hooks';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../../services/ErrorService';
import {TechMapServicesFilter} from '../types';
import {
  TechnologicalMapServiceRelationProxy
} from '@me-team/host/main/graphql/types';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import RenderCategoryName from '../RenderComponents/RenderCategoryName';

interface TechMapServicesTableProps {
  filters: TechMapServicesFilter;
}

const TechMapServicesTable: React.FC<TechMapServicesTableProps> = ({filters}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const {techMapId} = useParams();
  const [connectedServiceIds, setConnectedServiceIds] = useState<number[]>([]);
  const parentCheckboxRef = useRef<HTMLInputElement>(null);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  const isFilterNotEmpty = Object.values(filters).some(value => value !== '' && value !== null)

  const resetCurrentPage = () => {
    setCurrentPage(1);
  };
  useEffect(() => {
    resetCurrentPage();
  }, [filters?.search, filters?.categoryId]);

  const {
    data: techMapServicesData,
    loading: techMapServicesLoading,
    refetch: refetchTechMapServices
  } = useGetTechMapServicesQuery({
    variables: {
      techMapId: +techMapId,
      page: currentPage,
      itemsPerPage: itemsPerPage,
      search: filters?.search ? filters?.search : null,
      categoryId: filters?.categoryId ? filters?.categoryId : null,
    },
    fetchPolicy: 'network-only',
    context: {
      errorType: 'global'
    },
    onCompleted: (data) => {
      const arrConnectedServiceIds: number[] = data?.user?.company?.technologicalMaps?.technologicalMaps?.[0]?.techMapServices?.techMapServices
          ?.filter((relation) => relation?.connect)
          ?.map((relation) => relation?.service?.id) || [];

      setConnectedServiceIds(arrConnectedServiceIds);
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const [updateServiceConnection] = useUpdateTechMapServicesMutation({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    },
  })

  const company = techMapServicesData?.user?.company
  const techMapServicesPaginated = company?.technologicalMaps?.technologicalMaps?.[0].techMapServices
  const techMapServices: TechnologicalMapServiceRelationProxy[] = techMapServicesPaginated?.techMapServices
  const pageCount = parseInt(techMapServicesPaginated?.pageCount || '0');
  const totalItems = techMapServicesPaginated?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

  useEffect(() => {
    if (parentCheckboxRef.current) {
      const allChecked = connectedServiceIds?.length === techMapServices.length;
      const noneChecked = connectedServiceIds?.length === 0;
      parentCheckboxRef.current.indeterminate = !allChecked && !noneChecked;
      setIndeterminate(parentCheckboxRef.current.indeterminate)
      parentCheckboxRef.current.checked = allChecked;
    }
}, [connectedServiceIds, techMapServices]);

  const handleParentCheckboxChange = async (e: React.ChangeEvent<HTMLInputElement>)=> {
    const isChecked = e.target.checked;
    const serviceIds = techMapServices.map((service) => service.service.id);
    if (indeterminate) {
      await updateServiceConnection({
        variables: {
          techMapId: +techMapId,
          serviceIds: serviceIds,
          connect: false,
        }
      });
      parentCheckboxRef.current.indeterminate = false;
      setConnectedServiceIds([]);
    } else {
      setConnectedServiceIds(isChecked ? serviceIds : []);
      await updateServiceConnection({
        variables: {
          techMapId: +techMapId,
          serviceIds: serviceIds,
          connect: isChecked,
        }
      });
    }
  };

  const handleServiceCheckboxChange = async (serviceId: number) => {
    const isCurrentlyConnected = connectedServiceIds.includes(serviceId);
    const updatedServiceIds = isCurrentlyConnected
      ? connectedServiceIds.filter((id: number) => id !== serviceId)
      : [...connectedServiceIds, serviceId];

    setConnectedServiceIds(updatedServiceIds);
    await updateServiceConnection({
      variables: {
        techMapId: +techMapId,
        serviceIds: [serviceId],
        connect: !isCurrentlyConnected
      }
    });
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (techMapServicesLoading) return <Spinner/>;

  return (
    <>
      <div className='col-12 table-responsive scrollbar position-relative'>
        {
          !!techMapServices?.length ? (
            <table className='table table-borderless'>
              <thead>
              <tr className='fs-7'>
                <th className='py-2 col-2 ps-4 pe-2 align-middle' style={{minWidth: '106px'}}>
                  <div className='d-flex align-items-center gap-2'>
                    <Form.Check
                      type="checkbox"
                      ref={parentCheckboxRef}
                      checked={connectedServiceIds.length === techMapServices.length}
                      onChange={handleParentCheckboxChange}
                    />
                    <span className='fs-7 text-dark d-flex align-items-center gap-2'>
                      {t('Choose Service')}
                      <TooltipCustom placement='bottom' text={t('This technological map will be used for selected services')}>
                        <i className="bi bi-info-circle fs-7 text-dark d-flex align-items-center d-flex align-items-center"></i>
                      </TooltipCustom>
                    </span>
                  </div>
                </th>
                <th className='py-3 col-3 px-2 align-middle line-height-140'>
                  {t('ProductsList.Name')}
                </th>
                <th className='py-3 px-2 col-2 align-middle line-height-140'>
                  {t('ProductsList.Category')}
                </th>
                <th className='py-3 px-2 col-5 align-middle line-height-140'>
                  {t('Description')}
                </th>
              </tr>
              </thead>
              <tbody>
              {techMapServices.map((service) => (
                <tr
                  key={service.service?.id}
                  className='border-bottom'
                >
                  <td className='align-middle ps-4 py-4 pe-2'>
                    <Form.Check
                      type="checkbox"
                      checked={connectedServiceIds.includes(service.service?.id)}
                      onChange={() => handleServiceCheckboxChange(service.service?.id)}
                    />
                  </td>
                  <td className='align-middle py-4 px-2'>
                    <Link
                      to={`/services/service/${currentCompanyId}/service-list/${service?.service?.id}/edit`}
                      className={`text-decoration-none text-primary`}
                    >
                      {service?.service?.name}
                    </Link>
                  </td>
                  <td className='align-middle py-4 px-2'>
                    <RenderCategoryName
                      category={service?.service?.serviceCategoryNew}
                    />
                  </td>
                  <td className='align-middle py-4 px-2'>
                    <span className='text-dark'>
                      {truncateText(service?.service?.description, 100)}
                    </span>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          ) : null
        }
      </div>

      {!!totalItems ? (
          <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
            <Pagination
              t={t}
              pages={Number(pageCount)}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              firstItemIndex={firstItemIndex}
              lastItemIndex={lastItemIndex}
              totalItems={totalItems}
            />
          </div>
        ) :
        <RenderEmptyList
          isFilterNotEmpty={isFilterNotEmpty}
          visibilityBtn={!isFilterNotEmpty}
          emptyFilterTitle='No services were found for the specified values in the filters or search.'
          emptyListTitle='No services have been created yet. Add a service.'
          onClickButton={() => navigate(`/services/service/${currentCompanyId}/new`)}
          buttonName={
            <span>
              <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
              {t('addService')}
            </span>
          }
        />
      }

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default TechMapServicesTable;