import {Button, Col, Modal, Row} from 'react-bootstrap';
import {ErrorMessage, Form, Formik, FormikHelpers} from 'formik';
import {useTranslation} from "react-i18next";
import {ReactSelect} from "@services/ui-components"
import {
    useUpdateStorageProductRelationMutation
} from "@me-team/host/main/warehouses_goods/graphql/warehouses_goods.hooks";
import React, {useEffect, useState} from "react";
import {useGetUnitsQuery} from "@me-team/host/main/products/graphql/products.hooks";
import {Option} from "../../Products/types";
import * as Yup from "yup";
import {AddGoodsWarehouseModalProps} from "../types";
import QuantityInput from "../../AddNewProductList/AddProductListModal/QuantityInput";
import {StorageProductRelationActionType} from "@me-team/host/main/graphql/types";

const AddGoodsWarehouseModal: React.FC<AddGoodsWarehouseModalProps> = ({ show, handleModal, refetch, chosenProduct, toggleShowToast }) => {
    const { t } = useTranslation();
    const [unitOfMeasurements, setUnitOfMeasurements] = useState([])
    const {data: unitsData} = useGetUnitsQuery({
        context: {
            errorType: 'local'
        },

    });
    const initialValues = {
        quantity: null,
        unit : null
    };
    const [updateStorageProductRelation] = useUpdateStorageProductRelationMutation({
        context: {
            errorType: 'local',
        },
    });
    const handleSubmit = async (values: typeof initialValues, { setFieldError }: FormikHelpers<typeof initialValues>) => {
        try {
            const response = await updateStorageProductRelation({
                variables: {
                    storageProductRelationId: +chosenProduct?.id,
                    input: {... values, quantity:  +values?.quantity, action: StorageProductRelationActionType.Arrival}
                }
            });
            refetch();
            handleModal()
            toggleShowToast(t('Saved'))
        } catch (error) {
            console.error(error)
        }

    }
    useEffect(() => {
        if (chosenProduct) {
            const optionsUnitOfMeasurements = unitsData?.relations?.units
                ?.filter((unit) => {
                    const unitGroupId = chosenProduct?.product?.unitGroup?.id;
                    const alternativeUnitGroupId = chosenProduct?.product?.alternativeUnit?.unitGroup?.id;
                    return unit.unitGroup.id === unitGroupId ||
                        (alternativeUnitGroupId && unit.unitGroup.id === alternativeUnitGroupId);
                })
                ?.map((unit) => ({
                    value: unit.id,
                    label: unit.name,
                }));
            setUnitOfMeasurements(optionsUnitOfMeasurements);
        }
    }, [chosenProduct])
    const validationSchema = () =>
        Yup.object().shape({
            quantity: Yup.number().required(t('validation.required')),
            unit: Yup.number().required(t('validation.required')),
    })
    return (
        <Modal size="lg" show={show} onHide={handleModal} centered>
            <Modal.Header className="border-0" closeButton>
                <h5 className="m-0">{t('Add products')} {chosenProduct?.product?.name} {t('to the warehouse')}</h5>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                >
                    {({ values, errors, setFieldValue }) => (
                        <Form>
                            <Row className={'mx-0'}>
                                <Col lg={2} xs={4} className="d-flex pr-0">
                                    <label htmlFor="addWeight-field" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">{t('Amount to be added')}</span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>
                                <Col lg={10} xs={8}>
                                    <QuantityInput
                                        name='quantity'
                                        id='addsProduct-quantity-field'
                                        placeholder={t('Amount to be added')}
                                        maxLength={15}
                                    />
                                    <ErrorMessage name="quantity" component="div" className="fs-7 m-0 p-1 text-danger"  />
                                </Col>
                            </Row>
                            <Row className="pt-3 mb-3 mx-0">
                                <Col lg={2} xs={4} className="d-flex pr-0">
                                    <label htmlFor="storageType" className="mb-0 text-dark form-label required text-break">
                                        <span className="text-dark fw-bold mt-1 fs-7">{t('Unit of measurements')}</span>
                                        <span className="text-danger ms-1 text-break fs-7">*</span>
                                    </label>
                                </Col>
                                <Col lg={10} xs={8}>
                                    <ReactSelect
                                        id='addProduct-unit-field'
                                        name='unit'
                                        placeholder={unitOfMeasurements[0]?.label}
                                        options={unitOfMeasurements}
                                        onChange={(selectedOption: Option) => setFieldValue('unit', selectedOption.value )}
                                        isSearchable={false}
                                    />
                                    <ErrorMessage name="unit" component="div" className="fs-7 m-0 p-1 text-danger"  />
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-end pt-6">
                                <Col xs={6} sm="auto" className="mb-sm-0 pe-2">
                                    <Button
                                        variant="outline-primary"
                                        className="fw-normal custom-btn rounded-1"
                                        onClick={handleModal}
                                    >
                                        {t("Cancel")}
                                    </Button>
                                </Col>
                                <Col xs={6} sm="auto" className="mb-sm-0 ps-2">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="fw-bold custom-btn rounded-1"
                                    >
                                        {t('Add')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default AddGoodsWarehouseModal;