import React from "react";
import { Modal, Button, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { useTranslation } from "react-i18next";
import { WearhousesModalProps } from "../../Warehouse/modals/modalsUtils";
import {
    useDeleteStorageProductRelationMutation
} from "@me-team/host/main/warehouses_goods/graphql/warehouses_goods.hooks";

const DeleteGoodsModals: React.FC<WearhousesModalProps> = ({ handleModal, refetch, modalState, productsForDeleting, toggleShowToast  }) => {
    const { t } = useTranslation();
    const initialValues = productsForDeleting
    const [deleteProduct] = useDeleteStorageProductRelationMutation({
        context: {
            errorType: 'local',
        },
    })
    const handleSubmit = async () => {
        try {
            await Promise.all(
                productsForDeleting.map((id) =>
                    deleteProduct({
                        variables: { id: id }
                    })
                )
            )
            await refetch();
        } catch (error) {
            console.error('Error while deleting product', error);
        }
        handleModal()
        toggleShowToast(t('Deleted'))
    };

    return (
        <>
            {(() => {
                switch (modalState?.type) {
                    case 'deleteNotPossible':
                        return (
                            <Modal
                                show={true}
                                onHide={() => handleModal()}
                                centered
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header className="border-0" closeButton>
                                    <h5 className="text-danger mb-0">
                                        {t('It is not possible to delete product(s) from warehouse.')}
                                    </h5>
                                </Modal.Header>
                                <Modal.Body className="text-center pb-4">
                                    {t('You cannot delete the selected products. Please either write-off the product(s) or transfer them to another warehouse to complete the deletion process.')}
                                </Modal.Body>
                                <Modal.Footer className="border-0 pt-0">
                                    <Col>
                                        <Button
                                            className="btn btn-danger border-grey w-100 h-100 fw-normal"
                                            onClick={() => handleModal()}
                                        >
                                            OK
                                        </Button>
                                    </Col>
                                </Modal.Footer>
                            </Modal>
                        );

                    case 'partialDelete':
                        return (
                            <Modal
                                show={true}
                                onHide={() => handleModal()}
                                centered
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header className="border-0" closeButton>
                                    <h5 className="mb-0">{t('It is not possible to delete product(s) from warehouse.')}</h5>
                                </Modal.Header>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                >
                                    {() => (
                                        <Form>
                                            <Modal.Body className="text-center">
                                                <p className="mb-1">
                                                    {modalState?.text}
                                                </p>
                                            </Modal.Body>
                                            <Modal.Footer className="border-0">

                                                <Col>
                                                    <Button
                                                        variant="outline-primary"
                                                        className="btn btn-outline-primary border-grey w-100 h-100 fw-normal"
                                                        onClick={() => handleModal()}
                                                    >
                                                        {t('Cancel')}
                                                    </Button>
                                                </Col>
                                                <Col>
                                                    <Button
                                                        className="btn btn-primary w-100 text-truncate"
                                                        type="submit"
                                                    >
                                                        {t('Delete')}
                                                    </Button>
                                                </Col>
                                            </Modal.Footer>
                                        </Form>
                                    )}
                                </Formik>
                            </Modal>
                        );

                    case 'confirmDelete':
                        return (
                            <Modal
                                show={true}
                                onHide={() => handleModal()}
                                centered
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header className="border-0" closeButton>
                                    <h5 className="mb-0">{t('Delete product(s) from warehouse')}</h5>
                                </Modal.Header>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                >
                                    {() => (
                                        <Form>
                                            <Modal.Body className="text-center">
                                                <p className="mb-1">
                                                    {t('Do you really want to remove the item(s) from the warehouse?')}

                                                </p>
                                            </Modal.Body>
                                            <Modal.Footer className="border-0">
                                                <Col>
                                                    <Button
                                                        variant="outline-primary"
                                                        className="btn btn-outline-primary border-grey w-100 h-100 fw-normal"
                                                        onClick={() => handleModal()}
                                                    >
                                                        {t('Cancel')}
                                                    </Button>
                                                </Col>
                                                <Col>
                                                    <Button
                                                        className="btn btn-primary w-100 text-truncate"
                                                        type="submit"
                                                    >
                                                        {t('Delete')}
                                                    </Button>
                                                </Col>
                                            </Modal.Footer>
                                        </Form>
                                    )}
                                </Formik>
                            </Modal>
                        );

                    default:
                        return null;
                }
            })()}
        </>
    );
};

export default DeleteGoodsModals