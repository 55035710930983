import {ErrorMessage, Field, FieldProps, useField} from 'formik';
import React, {useEffect, useState} from 'react';
import {Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {CompanyBrandsProxy} from '@me-team/host/main/graphql/types';

interface TextInputProps {
  label: string;
  placeholder: string;
  name: string;
  id: string;
  suggestions?: CompanyBrandsProxy[];
  isDisabled?: boolean;
}

const TextInputWithCounter: React.FC<TextInputProps> = ({
                                                          label,
                                                          placeholder,
                                                          name,
                                                          id,
                                                          suggestions = [],
                                                          isDisabled= false
                                                        }) => {
  const {t} = useTranslation();
  const [field, meta, helpers] = useField(name);
  const [charCount, setCharCount] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  useEffect(() => {
    setCharCount(field?.value ? field.value.length : 0);
  }, [field.value]);

  const normalizeInputValue = (value: string): string => {
    return value.trim().replace(/\s+/g, ' ');
  };

  const handleBlur = async () => {
    const normalizedValue = normalizeInputValue(field.value);

    const matchingSuggestion = suggestions.find(
      (suggestion) => suggestion.name.toLowerCase() === normalizedValue.toLowerCase()
    );
    const finalValue = matchingSuggestion ? matchingSuggestion.name : normalizedValue;

    await helpers.setValue(finalValue);
    await helpers.setTouched(true, true);
    setTimeout(() => {
      setShowSuggestions(false);
      setActiveSuggestionIndex(-1);
    }, 100);
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.currentTarget.value;
    await helpers.setValue(userInput);

    if (suggestions.length > 0) {
      const names = suggestions.map((suggestion) => suggestion.name);
      const filtered = names.filter((suggestion) =>
        suggestion?.toLowerCase().includes(userInput.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
      setActiveSuggestionIndex(-1);
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
      setActiveSuggestionIndex(-1);
    }
  };

  const handleSuggestionClick = async (suggestion: string) => {
    await helpers.setValue(suggestion);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setActiveSuggestionIndex(-1);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (filteredSuggestions.length > 0) {
        setActiveSuggestionIndex((prevIndex) =>
          prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : prevIndex
        );
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (filteredSuggestions.length > 0) {
        setActiveSuggestionIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : 0
        );
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (
        activeSuggestionIndex >= 0 &&
        activeSuggestionIndex < filteredSuggestions.length
      ) {
        handleSuggestionClick(filteredSuggestions[activeSuggestionIndex]);
      }
      else if (filteredSuggestions.length === 1) {
        handleSuggestionClick(filteredSuggestions[0]);
      }
    } else if (e.key === 'Escape') {
      setShowSuggestions(false);
      setActiveSuggestionIndex(-1);
    }
  };

  return (
    <>
      <Col
        md={2}
        className='mb-2 mb-md-0'>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold pt-3'>
          {label}&nbsp;<span className='text-danger'>*</span>
        </label>
      </Col>
      <Col md={10}>
        <div className='position-relative'>
          <Field name={name}>
            {({field, meta}: FieldProps) => (
              <>
                <input
                  {...field}
                  type='text'
                  id={id}
                  placeholder={placeholder}
                  className={`form-control text-dark ${meta.touched && meta.error ? 'is-invalid' : ''}`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  autoComplete='off'
                  maxLength={50}
                  disabled={isDisabled}
                />
                {showSuggestions && filteredSuggestions.length > 0 && (
                  <ul className='suggestions-list'>
                    {filteredSuggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        className={`${index === activeSuggestionIndex ? 'active' : ''}`}
                        onMouseDown={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </Field>
          <ErrorMessage name={name}>
            {msg => <div className='invalid-feedback fs-7'>{msg}</div>}
          </ErrorMessage>
        </div>
        <span
          id='countSymbols'
          className='fs-7 text-grey'>
          {charCount}/50
        </span>
      </Col>
    </>
  );
};

export default TextInputWithCounter;
