import {CategoryInterface, SubcategoryInterface} from '../types';
import {ProductCategoryNewProxy} from '@me-team/host/main/graphql/types';

class CategoryProduct {
  public static categoryModuleCreator = (productCategories : ProductCategoryNewProxy[], categoryId : number | null, subcategoryId : number | null) : CategoryInterface[] => {
    const isActiveCategories = productCategories?.filter((category) => !category?.deleted);

    return  isActiveCategories.map((productCategory) => {
      return {
        id: productCategory.id,
        name: productCategory.name,
        isOpen: !!((productCategory.id === categoryId) && subcategoryId),
        isPicked: !!((productCategory.id === categoryId) && !subcategoryId),
        isSubcategoryPiked : !!((productCategory.id === categoryId) && subcategoryId),
        subcategoryList: this.subcategoryModuleCreator(productCategory.childCategories, subcategoryId)
      }
    })
  }

  public static subcategoryModuleCreator = (subcategories : ProductCategoryNewProxy[], currentSubcategoryId: number) : SubcategoryInterface[] => {
    const isActiveSubcategories = subcategories?.filter((subcategory) => !subcategory?.deleted)
    return isActiveSubcategories.map((subcategory) => {
      return {
        id: subcategory.id,
        name: subcategory.name,
        isPicked: subcategory.id === currentSubcategoryId
      }
    })
  }

}

export default CategoryProduct