import React from 'react';

interface BaseCategory {
  id: number;
  name: string;
  deleted: boolean;
  parentCategory?: BaseCategory;
}

interface RenderCategoryNameProps<T extends BaseCategory> {
  category: T;
  isArchive?: boolean;
}

const RenderCategoryName = <T extends BaseCategory>({ category, isArchive }: RenderCategoryNameProps<T>): JSX.Element | null => {
  if (!category) return null;

  const isSubCategory = !!category?.parentCategory?.name;
  const categoryName = category?.parentCategory?.name ? category?.parentCategory?.name : category?.name;
  const subCategoryName = category?.parentCategory?.name ? category?.name : '';
  const mainTextColor  = isArchive ? 'text-secondary' : 'text-dark';
  const subTextColor  = isArchive ? 'text-secondary' : 'text-grey';
  return (
    <div>
      <span className={`${mainTextColor} d-block`}>
        {isSubCategory ? subCategoryName : categoryName}
      </span>
      {
        isSubCategory
          ?
          <span className={`fs-7 ${subTextColor}`}>
            {`(${categoryName})`}
          </span>
          : null
      }
    </div>
  );
};

export default RenderCategoryName;