import React, {useEffect, useRef, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {
  ModalDelete,
  Pagination,
  RenderCategoryName,
  RenderEmptyList,
  Spinner,
  TooltipCustom
} from '@services/ui-components';
import {
  TechMapProductInput,
  TechMapProductPaginated,
  TechnologicalMapProductRelationProxy
} from '@me-team/host/main/graphql/types';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import {UserRole} from '../../../utils/enums';
import {
  useEditProductTechMapMutation,
  useGetProductsForTechMapQuery,
  useRemoveProductsFromTechMapMutation,
} from '@me-team/host/main/products/graphql/products.hooks';
import ErrorService from '../../../services/ErrorService';
import EditableProductFields, {InitialVariables} from './EditableProductFields';
import ToastComponent from '../../ui-components/ToastComponent/ToastComponent';
import {useToast} from '../../../hooks/useToast';

const ProductsForTechMapTable: React.FC = () => {
  const basePath = process.env.REACT_APP_POS_URL;
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const parentCheckboxRef = useRef<HTMLInputElement>(null);
  const {techMapId} = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [parentChecked, setParentChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [markedForDeletionArray, setMarkedForDeletionArray] = useState<number[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [arrDeleteProductId, setArrDeleteProductId] = useState<number[]>([]);
  const [editRowId, setEditRowId] = useState<number | null>(null);
  const {showToast, toastText, toggleShowToast, setShowToast} = useToast();

  const [editProductTechMap] = useEditProductTechMapMutation();

  const {
    data: productsData,
    loading: productsDataLoading,
    refetch: refetchProducts
  } = useGetProductsForTechMapQuery({
    variables: {
      techMapId: +techMapId,
      page: currentPage,
      itemsPerPage: itemsPerPage,
    },
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
    fetchPolicy: "cache-and-network"
  });

  const [removeProductsFromTechMap] = useRemoveProductsFromTechMapMutation({
    context: {
      errorType: 'local'
    },
    onCompleted: async (data) => {
      if (data) {
        setShowDeleteConfirmation(false);
        setMarkedForDeletionArray([]);
        setArrDeleteProductId([]);
      }
      await refetchProducts();
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    },
  });

  const handleConfirmationClose = () => {
    setArrDeleteProductId([]);
    setShowDeleteConfirmation(false);
  }

  const handleShowModalDelete = async (id: number) => {
    setMarkedForDeletionArray([]);
    setArrDeleteProductId([id]);
    setShowDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    const productIdsToDelete = arrDeleteProductId.length > 0 ? arrDeleteProductId : markedForDeletionArray;
    await removeProductsFromTechMap({
      variables: {relationIds: productIdsToDelete && productIdsToDelete},
    });
  };

  const productsPaginated: TechMapProductPaginated = productsData?.user?.company?.technologicalMaps?.technologicalMaps?.[0].techMapProducts;
  const products: TechnologicalMapProductRelationProxy[] = productsPaginated?.techMapProducts;
  const pageCount = parseInt(productsPaginated?.pageCount || '0');
  const totalItems = productsPaginated?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

  useEffect(() => {
    if (markedForDeletionArray.length === 0) {
      setParentChecked(false);
      setIndeterminate(false);
    } else if (markedForDeletionArray.length === products?.length) {
      setParentChecked(true);
      setIndeterminate(false);
    } else {
      setParentChecked(false);
      setIndeterminate(true);
    }

    if (parentCheckboxRef.current) {
      parentCheckboxRef.current.indeterminate = indeterminate;
    }
  }, [markedForDeletionArray, products?.length, indeterminate]);

  const handleChildCheckboxChange = (id: number) => {
    setMarkedForDeletionArray((prevMarkedForDeletionArray) =>
      prevMarkedForDeletionArray.includes(id)
        ? prevMarkedForDeletionArray.filter((markedId) => markedId !== id)
        : [...prevMarkedForDeletionArray, id]
    );
  };

  const handleParentCheckboxChange = () => {
    if (indeterminate) {
      setMarkedForDeletionArray([]);
      setParentChecked(false);
    } else if (parentChecked) {
      setMarkedForDeletionArray([]);
    } else {
      setMarkedForDeletionArray(products?.map((product) => product.id) || []);
    }
  };

  const handleOpenEdit = (id: number) => {
    if (editRowId === id) {
      setEditRowId(null);
    } else {
      setEditRowId(id);
    }
  }

  const handleEditProductTechMap = async (values: InitialVariables) => {
    const input: TechMapProductInput = {
      unit: values.input.unit,
      requiredQuantity: values.input.requiredQuantity
    }
    await editProductTechMap({
      variables: {relationId: values.relationId, input},
      context: {
        errorType: 'local',
      },
      onCompleted: (data) => {
        refetchProducts()
        setEditRowId(null);
        toggleShowToast(t('change saved'))
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error));
      },
    })
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (productsDataLoading) return <Spinner/>;

  return (
    <>
      <div className='col-12 table-responsive scrollbar position-relative'>
        {
          !!products?.length ? (
            <table className='table table-borderless'>
              <thead>
              <tr className='fs-7'>
                {
                  !isEmployeeRole ?
                    <th className='py-2 col-1 ps-4 pe-2 align-middle' style={{minWidth: '106px'}}>
                      <div className='d-flex align-items-center gap-3'>
                        <Form.Check
                          type="checkbox"
                          ref={parentCheckboxRef}
                          checked={parentChecked}
                          onChange={handleParentCheckboxChange}
                        />
                        {markedForDeletionArray.length > 0 ?
                          <TooltipCustom placement='right' text={t('Delete Product(s)')}>
                            <Button
                              variant='outline-danger'
                              className='border-0 fs-16 p-0'
                              onClick={() => setShowDeleteConfirmation(true)}
                            >
                              <i className="bi bi-trash3 icon-multi-delete"></i>
                            </Button>
                          </TooltipCustom>
                          : null
                        }
                      </div>
                    </th>
                    : null
                }
                <th className='py-3 col-2 px-2 align-middle line-height-140'>
                  {t('ProductsList.Name')}
                </th>
                <th className='py-3 px-2 col-2 align-middle line-height-140'>
                  {t('ProductsList.Brand')}
                </th>
                <th className='py-3 px-2 col-2 align-middle line-height-140'>
                  {t('ProductsList.Category')}
                </th>
                <th className='py-3 px-2 col-1 align-middle line-height-140'>
                  <span>{t('ProductsList.SKU')}</span>
                </th>
                <th className='py-3 px-2 col-1 align-middle line-height-140'>
                  {t('ProductsList.Barcode')}
                </th>
                <th className='py-1 px-2 col-1 align-middle line-height-140'>
                  {t('Amount')}
                </th>
                <th className='py-1 px-2 col-1 align-middle text-start line-height-140'>
                  <span>{t('ProductsList.Unit')}</span>
                </th>
                {!isEmployeeRole ?
                  <th className='py-3 px-2 pe-0 col-1 align-middle'></th>
                  : null
                }
              </tr>
              </thead>
              <tbody>
              {products.map((product: TechnologicalMapProductRelationProxy) => (
                <tr
                  key={product.id}
                  className='border-bottom'>
                  {!isEmployeeRole ?
                    <td className='align-middle ps-4 py-3 pe-2'>
                      <Form.Check
                        type="checkbox"
                        checked={markedForDeletionArray.includes(product.id)}
                        onChange={() => handleChildCheckboxChange(product.id)}
                      />
                    </td>
                    : null
                  }
                  <td className='align-middle py-3 px-2 line-height-140'>
                    <Link
                      to={`/warehouse/product/${currentCompanyId}/${product.product.id}/edit-product`}
                      className={`text-decoration-none text-primary`}
                    >
                      {product.product.name}
                    </Link>
                  </td>
                  <td className='align-middle py-3 px-2 line-height-140'>
                    {product.product.brand}
                  </td>
                  <td className='align-middle py-3 px-2 line-height-140'>
                    <RenderCategoryName
                      category={product.product.productCategoryNew}
                    />
                  </td>
                  <td className='align-middle py-3 px-2 line-height-140'>
                    {product.product.sku ? product.product.sku : '—'}
                  </td>
                  <td className='align-middle py-3 px-2 line-height-140'>
                    {product.product.barcode ? product.product.barcode : '—'}
                  </td>
                  {!isEmployeeRole && editRowId === product.id ?
                    <EditableProductFields
                      initialValues={{
                        relationId: product.id,
                        input: {
                          unit: product.unit.id,
                          requiredQuantity: product.requiredQuantity
                        }
                      }}
                      product={product}
                      unitGroup={product.unit.unitGroup.name}
                      onEdit={handleEditProductTechMap}
                      onCancel={() => handleOpenEdit(product.id)}
                    />
                    :
                    <>
                      <td className='align-middle py-3 px-2 line-height-140'>
                        {product.requiredQuantity}
                      </td>
                      <td className='align-middle py-3 px-2 line-height-140'>
                        <span>
                          {product.unit?.name}
                        </span>
                      </td>
                    </>
                  }
                  {!isEmployeeRole && !(editRowId === product.id) ?
                    <td className='align-middle ps-2 pe-0 py-3'>
                      <div className='d-flex align-items-center justify-content-end gap-2'>
                        <TooltipCustom
                          placement='bottom'
                          text={t('ProductsList.Edit product')}
                        >
                          <Button
                            variant='outline-secondary'
                            className='btn-square d-flex align-items-center justify-content-center p-1'
                            style={{width: '40px', height: '40px'}}
                            onClick={() => handleOpenEdit(product.id)}
                          >
                            <i className='bi bi-pencil fs-16 text-dark'></i>
                          </Button>
                        </TooltipCustom>
                        <TooltipCustom
                          placement='bottom'
                          text={t('ProductsList.Delete Product')}
                        >
                          <Button
                            variant='outline-secondary'
                            className='btn-square d-flex align-items-center justify-content-center p-1'
                            style={{width: '40px', height: '40px'}}
                            onClick={() => handleShowModalDelete(product?.id)}
                          >
                            <i className='bi bi-trash3 fs-16 text-dark'></i>
                          </Button>
                        </TooltipCustom>
                      </div>
                    </td> : null
                  }
                </tr>
              ))}
              </tbody>
            </table>
          ) : null
        }
      </div>

      {!!totalItems ? (
          <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
            <Pagination
              t={t}
              pages={Number(pageCount)}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              firstItemIndex={firstItemIndex}
              lastItemIndex={lastItemIndex}
              totalItems={totalItems}
            />
          </div>
        ) :
        <RenderEmptyList
          isFilterNotEmpty={false}
          emptyFilterTitle=''
          emptyListTitle='Products not yet created. Add a product.'
          // TODO когда будет готова страница добавления товара в тех карту изменить ссылку
          onClickButton={() => window.location.href = `${basePath}/warehouse/technological-map/${currentCompanyId}/${techMapId}/product/add`}
          buttonName={
            <span>
                <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
              {t('Add Product to Technological Map')}
              </span>
          }
        />
      }

      <ModalDelete
        show={showDeleteConfirmation}
        handleClose={handleConfirmationClose}
        title={t('Deleting of product(s)')}
        body={t('Are you sure you want to delete selected product(s)?')}
        buttons={[
          {
            text: t('Cancel'),
            onClick: handleConfirmationClose,
            variant: 'btn btn-outline-violet border-primary text-primary fw-normal',
            className: 'd-flex justify-content-center align-items-center w-100',
          },
          {
            text: t('delete'),
            onClick: handleDelete,
            variant: 'primary',
            className: 'w-100',
          }
        ]}
      />

      {showToast && <ToastComponent show={showToast} setShow={setShowToast} text={toastText}/>}

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>

  );
};

export default ProductsForTechMapTable;