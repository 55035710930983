import * as Yup from 'yup';
import {TFunction} from 'i18next';
import {ValidationError} from 'yup';

export const createProductFormValidationSchema=(t: TFunction, isArchiveCategory?: boolean) => Yup.object().shape({
  name: Yup.string().trim().required(t('validation.required')),
  brand: Yup.string().trim().required(t('validation.required')),
  category: Yup.number()
    .required(t('validation.required'))
    .test(
      'not-archived-category',
      t('Select a non-archive category'),
      function (value) {
          return !isArchiveCategory;
      }
    ),
  criticalResidue: Yup
    .number()
    .nullable()
    .moreThan(0, t('Numeric value, must be greater than 0')),
  unitGroup : Yup.number().required(t('validation.required')),
  unit : Yup.number().required(t('validation.required')),
  alternativeUnitGroupInput: Yup.object().when('unitGroup', (unitGroupValue: number[] , schema) => {
    if (unitGroupValue[0] === 2) {
      return Yup.object().shape({
        alternativeUnitGroup: Yup.number().required(t('validation.required')),
        alternativeValue: Yup.number().required(t('validation.required')),
        alternativeUnit: Yup.number().required(t('validation.required')),
      });
    } else {
      return schema.nullable().notRequired();
    }
  }),
  productAttributes: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .trim()
          .required(t('validation.required'))
          .max(20, t('Name must be less than 20 characters')),
        property: Yup.string()
          .trim()
          .required(t('validation.required')),
      })
    )
    .test('unique-name', null, function (
      this: Yup.TestContext,
      productAttributes: Array<{ name: string }> | null
    ) {
      if (!productAttributes) return true;

      const errors: ValidationError[] = [];
      const nameIndicesMap: Record<string, number[]> = {};

      productAttributes.forEach((attr, index) => {
        const name = attr.name;
        if (name in nameIndicesMap) {
          nameIndicesMap[name].push(index);
        } else {
          nameIndicesMap[name] = [index];
        }
      });

      Object.keys(nameIndicesMap).forEach(name => {
        const indices = nameIndicesMap[name];
        if (indices.length > 1) {
          indices.slice(1).forEach(index => {
            errors.push(
              this.createError({
                path: `${this.path}[${index}].name`,
                message: t('Name already exists'),
              })
            );
          });
        }
      });

      if (errors.length > 0) {
        throw new ValidationError(errors);
      }
      return true;
    })
    .test('unique-property', null, function (
      this: Yup.TestContext,
      productAttributes: Array<{ property: string }> | null
    ) {
      if (!productAttributes) return true;

      const errors: ValidationError[] = [];
      const propertyIndicesMap: Record<string, number[]> = {};

      productAttributes.forEach((attr, index) => {
        const property = attr.property;
        if (property in propertyIndicesMap) {
          propertyIndicesMap[property].push(index);
        } else {
          propertyIndicesMap[property] = [index];
        }
      });

      Object.keys(propertyIndicesMap).forEach(property => {
        const indices = propertyIndicesMap[property];
        if (indices.length > 1) {
          indices.slice(1).forEach(index => {
            errors.push(
              this.createError({
                path: `${this.path}[${index}].property`,
                message: t('Property already exists'),
              })
            );
          });
        }
      });

      if (errors.length > 0) {
        throw new ValidationError(errors);
      }
      return true;
    }),
})

export const getTextColorAvailabilityClass = (status: string, isArchive: boolean) => {
  if (isArchive) {
    return 'text-secondary';
  }

  switch (status) {
    case 'In stock':
      return 'text-green';
    case 'Out of stock':
      return 'text-grey';
    case 'Not added':
      return 'text-dark';
    default:
      return 'text-secondary';
  }
};
