/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetProductCategoriesDocument = gql`
    query getProductCategories($id: Int, $categoryName: String, $itemsPerPage: Int, $serviceDeleted: Boolean, $page: Int) {
  productCategories(
    id: $id
    categoryName: $categoryName
    itemsPerPage: $itemsPerPage
    page: $page
  ) {
    itemsPerPage
    currentPage
    pageCount
    totalCount
    items {
      id
      name
      comment
      deleted
      company {
        id
        name
        address
        phone
        website
        deleted
        bookingQrLink
        services {
          pageCount
        }
        isPremiumOn
        paidFeaturesPaginated {
          itemsPerPage
        }
      }
      products(deleted: $serviceDeleted) {
        id
        name
        deleted
        cost
        price
      }
      childCategories(name: $categoryName) {
        id
        name
        comment
        deleted
        company {
          id
          name
          address
          phone
          website
          deleted
          bookingQrLink
          isPremiumOn
          services {
            pageCount
          }
          paidFeaturesPaginated {
            itemsPerPage
          }
        }
        products(deleted: $serviceDeleted) {
          id
          name
          deleted
          cost
          price
        }
        parentCategory {
          id
          name
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      categoryName: // value for 'categoryName'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      serviceDeleted: // value for 'serviceDeleted'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, options);
      }
export function useGetProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, options);
        }
export function useGetProductCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, options);
        }
export type GetProductCategoriesQueryHookResult = ReturnType<typeof useGetProductCategoriesQuery>;
export type GetProductCategoriesLazyQueryHookResult = ReturnType<typeof useGetProductCategoriesLazyQuery>;
export type GetProductCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetProductCategoriesSuspenseQuery>;
export type GetProductCategoriesQueryResult = Apollo.QueryResult<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>;
export const UpdateProductCategoryInTableDocument = gql`
    mutation UpdateProductCategoryInTable($id: Int!, $input: ProductCategoryNewInput!) {
  updateProductCategory(id: $id, input: $input) {
    id
    name
    deleted
  }
}
    `;
export type UpdateProductCategoryInTableMutationFn = Apollo.MutationFunction<UpdateProductCategoryInTableMutation, UpdateProductCategoryInTableMutationVariables>;

/**
 * __useUpdateProductCategoryInTableMutation__
 *
 * To run a mutation, you first call `useUpdateProductCategoryInTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCategoryInTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCategoryInTableMutation, { data, loading, error }] = useUpdateProductCategoryInTableMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductCategoryInTableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductCategoryInTableMutation, UpdateProductCategoryInTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductCategoryInTableMutation, UpdateProductCategoryInTableMutationVariables>(UpdateProductCategoryInTableDocument, options);
      }
export type UpdateProductCategoryInTableMutationHookResult = ReturnType<typeof useUpdateProductCategoryInTableMutation>;
export type UpdateProductCategoryInTableMutationResult = Apollo.MutationResult<UpdateProductCategoryInTableMutation>;
export type UpdateProductCategoryInTableMutationOptions = Apollo.BaseMutationOptions<UpdateProductCategoryInTableMutation, UpdateProductCategoryInTableMutationVariables>;
export const CreateProductCategoryInTableDocument = gql`
    mutation CreateProductCategoryInTable($companyId: Int!, $input: ProductCategoryNewInput!) {
  createProductCategoryNew(companyId: $companyId, input: $input) {
    id
    name
    deleted
  }
}
    `;
export type CreateProductCategoryInTableMutationFn = Apollo.MutationFunction<CreateProductCategoryInTableMutation, CreateProductCategoryInTableMutationVariables>;

/**
 * __useCreateProductCategoryInTableMutation__
 *
 * To run a mutation, you first call `useCreateProductCategoryInTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategoryInTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCategoryInTableMutation, { data, loading, error }] = useCreateProductCategoryInTableMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductCategoryInTableMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductCategoryInTableMutation, CreateProductCategoryInTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductCategoryInTableMutation, CreateProductCategoryInTableMutationVariables>(CreateProductCategoryInTableDocument, options);
      }
export type CreateProductCategoryInTableMutationHookResult = ReturnType<typeof useCreateProductCategoryInTableMutation>;
export type CreateProductCategoryInTableMutationResult = Apollo.MutationResult<CreateProductCategoryInTableMutation>;
export type CreateProductCategoryInTableMutationOptions = Apollo.BaseMutationOptions<CreateProductCategoryInTableMutation, CreateProductCategoryInTableMutationVariables>;
export type GetProductCategoriesQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  categoryName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  serviceDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetProductCategoriesQuery = { __typename?: 'Query', productCategories?: { __typename?: 'ProductCategoryNewPaginated', itemsPerPage?: number | null, currentPage?: number | null, pageCount?: string | null, totalCount?: number | null, items?: Array<{ __typename?: 'ProductCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, deleted: boolean, bookingQrLink: string, isPremiumOn: boolean, services?: { __typename?: 'ServicePaginated', pageCount?: string | null } | null, paidFeaturesPaginated: { __typename?: 'PaidFeaturePaginated', itemsPerPage?: number | null } } | null, products?: Array<{ __typename?: 'ProductProxy', id: number, name: string, deleted: boolean, cost: string, price: string }> | null, childCategories?: Array<{ __typename?: 'ProductCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, deleted: boolean, bookingQrLink: string, isPremiumOn: boolean, services?: { __typename?: 'ServicePaginated', pageCount?: string | null } | null, paidFeaturesPaginated: { __typename?: 'PaidFeaturePaginated', itemsPerPage?: number | null } } | null, products?: Array<{ __typename?: 'ProductProxy', id: number, name: string, deleted: boolean, cost: string, price: string }> | null, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null> | null }> | null } | null };

export type UpdateProductCategoryInTableMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.ProductCategoryNewInput;
}>;


export type UpdateProductCategoryInTableMutation = { __typename?: 'Mutation', updateProductCategory: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } };

export type CreateProductCategoryInTableMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.ProductCategoryNewInput;
}>;


export type CreateProductCategoryInTableMutation = { __typename?: 'Mutation', createProductCategoryNew: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } };
