import  React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col,Button, Row } from "react-bootstrap";
import {FiltersButton, TableHeader} from '@services/ui-components';
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import {ProductsFilterI} from "./types";
import WarehousesGoodsFilter from "./components/WarehousesGoodsFilter";
import WarehousesGoodsTable from "./components/WarehousesGoodsTable";
import {useNavigate, useParams} from "react-router-dom";

const WarehousesGoods: React.FC = () => {
    const { t } = useTranslation()
    const {storageId} = useParams();
    const navigate = useNavigate();

    const [address, setAddress] = useState(null)
    const [showFilters, setShowFilters] = useState(false);
    const [filtersApplied, setFiltersApplied] = useState(false)
    const initialValueFilter: ProductsFilterI = {
        nameSkuBarcode: null,
        categoryId: null,
        brand: null,
        stockStatus: null,
    }
    const [filters, setFilters] = useState<ProductsFilterI>(initialValueFilter);
    const toggleFilters = () => setShowFilters(!showFilters);

    return (
        <>
            <VioletBadge
                text={t('Manage warehouse products: arrival, write-off, transfer, deletion.')}
            />
            <TableHeader
                title={address}
                paths={[
                    { path: location?.pathname, label: t('Warehouse accounting') },
                    { path: "/warehouses", label: t('Warehouses') },
                    { path: location?.pathname, label: address },
                ]}
                colSize={9}
                btnColSize={3}
                buttons={
                    <Row
                        className='gx-1 w-100  d-flex flex-lg-nowrap justify-content-lg-end gap-3'
                    >
                        <Col
                            xs={12}
                            md={6}
                            lg={8}
                            // className='px-0 position-relative'
                        >
                            <Button
                                variant='primary'
                                className='w-100 text-white mb-3 mb-lg-0  mx-0 position-relative'
                                onClick={()=> navigate('/warehouses/add-product/' + storageId)}
                            >
                                <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                                {t('Add products to the warehouse')}
                            </Button>
                        </Col>
                        <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className='px-0'
                        >
                            <FiltersButton
                                filter={filters}
                                showFilters={showFilters}
                                onClick={toggleFilters}
                                classes="mb-3 mb-lg-0"
                            />
                        </Col>
                    </Row>
                }

                showFilters={showFilters}
            >
                <WarehousesGoodsFilter setFilters={setFilters} setFiltersApplied={setFiltersApplied} />
            </TableHeader >
            <WarehousesGoodsTable filters={filters} setAddress={setAddress} filtersApplied={filtersApplied}/>

        </>
    );
};

export default WarehousesGoods;