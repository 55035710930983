import {ServiceCategoryNewProxy} from '@me-team/host/main/graphql/types';

export interface CategoryInterface {
  id: number;
  name: string;
  isOpen: boolean;
  isPicked: boolean;
  isSubcategoryPiked: boolean;
  subcategoryList: SubcategoryInterface[];
}

export interface SubcategoryInterface {
  id: number;
  name: string;
  isPicked: boolean;
}

class CategoryService {
  public static categoryModuleCreator = (
    serviceCategories:  ServiceCategoryNewProxy[],
    categoryId: number | null,
    subcategoryId: number | null
  ): CategoryInterface[] => {
    return serviceCategories.map((serviceCategory) => {
      return {
        id: serviceCategory.id,
        name: serviceCategory.name,
        isOpen: !!(serviceCategory.id === categoryId && subcategoryId),
        isPicked: !!(serviceCategory.id === categoryId && !subcategoryId),
        isSubcategoryPiked: !!(serviceCategory.id === categoryId && subcategoryId),
        subcategoryList: this.subcategoryModuleCreator(
          serviceCategory.childCategories,
          subcategoryId
        ),
      };
    });
  };

  public static subcategoryModuleCreator = (
    subcategories:  ServiceCategoryNewProxy[],
    currentSubcategoryId: number
  ): SubcategoryInterface[] => {
    return subcategories.map((subcategory) => {
      return {
        id: subcategory.id,
        name: subcategory.name,
        isPicked: subcategory.id === currentSubcategoryId,
      };
    });
  };
}

export default CategoryService;
