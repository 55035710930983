import React from "react";
import {TFunction} from "i18next";
import {
    CategoryTableInterface,
    ChildCategoriesTableInterface,
    delModalCategoryContentType,
    delModalSubcategoryContentType
} from "../../interfaces/interfaces";

class CategoryValidationService {
    public static deleteCategoryValidator = (currentCategory: CategoryTableInterface,
                                             setDelModalContent: React.Dispatch<React.SetStateAction<delModalCategoryContentType>>,
                                             translate: TFunction<"translation", undefined>,
                                             delErrorWithCategoryServices : string) => {

        let subcatHasActiveService: boolean = false

        if (currentCategory.childCategories.length !== 0) {
            currentCategory.childCategories.forEach((subcategory: ChildCategoriesTableInterface) => {
                if (subcategory.categoryServices.length !== 0) {
                    subcatHasActiveService = true
                }
            })
        }


        if (currentCategory.childCategories.length === 0 && currentCategory.categoryServices.length === 0) {
            setDelModalContent({
                title: `${translate('servicesPage.deleteModal.delCategoryTitle')} ${currentCategory.name}`,
                description: translate('servicesPage.deleteModal.delDescription.withoutSubcatAndServices'),
                withoutSubcatAndServices: true,
                withServiceInSubcat: false,
                withServiceInCategory: false,
                withSubcat: false
            })
        } else if (currentCategory.categoryServices.length !== 0) {
            setDelModalContent({
                title: `${translate('servicesPage.deleteModal.cantDelCategoryTitle')}`,
                description: translate(delErrorWithCategoryServices),
                withoutSubcatAndServices: false,
                withServiceInSubcat: false,
                withServiceInCategory: true,
                withSubcat: false
            })
        } else if (currentCategory.categoryServices.length === 0 && currentCategory.childCategories.length !== 0 && !subcatHasActiveService) {
            setDelModalContent({
                title: `${translate('servicesPage.deleteModal.delCategoryTitle')} ${currentCategory.name}`,
                description: translate('servicesPage.deleteModal.delDescription.withSubcat'),
                withoutSubcatAndServices: false,
                withServiceInSubcat: false,
                withServiceInCategory: false,
                withSubcat: true
            })
        } else if (currentCategory.categoryServices.length === 0 && currentCategory.childCategories.length !== 0 && subcatHasActiveService) {
            setDelModalContent({
                title: `${translate('servicesPage.deleteModal.cantDelCategoryTitle')}`,
                description: translate('servicesPage.deleteModal.delDescription.withServiceInSubcat'),
                withoutSubcatAndServices: false,
                withServiceInSubcat: true,
                withServiceInCategory: false,
                withSubcat: false
            })
        }

    }

    public static delSubcategoryValidator = (currentSubcategory: ChildCategoriesTableInterface,
                                             setDelModalContent: React.Dispatch<React.SetStateAction<delModalSubcategoryContentType>>,
                                             translate: TFunction<"translation", undefined>, delErrorWithCategoryServices : string) => {
        if (currentSubcategory.categoryServices.length === 0) {
            setDelModalContent({
                title: `${translate('servicesPage.deleteModal.delCategoryTitle')} ${currentSubcategory.name}`,
                description: translate('servicesPage.deleteModal.delDescription.withoutSubcatAndServices'),
                withServiceInSubcat: false,
            })
        } else {
            setDelModalContent({
                title: `${translate('servicesPage.deleteModal.cantDelCategoryTitle')}`,
                description: translate(delErrorWithCategoryServices),
                withServiceInSubcat: true,

            })
        }

    }
}

export default CategoryValidationService