import {components} from 'react-select';
import React from 'react';

export const CustomControlIconError = (props: any) => (
  <components.Control {...props}>
    {props.children}
    {props.selectProps.error && (
      <i
        className="bi bi-exclamation-circle fs-20 position-absolute top-50 end-0 translate-middle-y text-danger p-1 pe-3 me-4 fw-bold"
        style={{backgroundColor: 'inherit'}}
      >
      </i>
    )}
  </components.Control>
);