import React from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";

interface AddEditModalProps {
  isModalOpened: boolean;
  setIsModalOpened: (isOpened: boolean) => void;
  title: string;
  children: React.ReactNode;
  contentClassName?: string;
}


const AddEditModal: React.FC<AddEditModalProps> = ({
                                                     isModalOpened,
                                                     setIsModalOpened,
                                                     children,
                                                     title,
                                                     contentClassName
                                                   }) => {
  const {t} = useTranslation();

  const handleModalClose = () => {
    setIsModalOpened(false);
  };

  return (
    <Modal
      show={isModalOpened}
      size="lg"
      onHide={handleModalClose}
      centered
      contentClassName={contentClassName}
    >

      <Modal.Header
        closeButton
        className='border-0'>
        <Modal.Title className="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  )
}

export default AddEditModal;