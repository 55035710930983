import {StorageProductRelationProxy} from "@me-team/host/main/graphql/types";

export enum StockStatus {
    InStock = 'In stock',
    OutOfStock = 'Out of stock',
    NotAdded = 'Not added',
}

export interface ProductsFilterI {
    nameSkuBarcode : string,
    categoryId: number,
    brand: string,
    stockStatus : string,
}

export interface Option {
    value: number;
    label: string;
}
export interface AddGoodsWarehouseModalProps {
    show: boolean;
    handleModal: () => void;
    refetch: () => void;
    toggleShowToast?: (text: string) => void;
    chosenProduct?: StorageProductRelationProxy;
    warehouse?: any;
    storageId?: number
}