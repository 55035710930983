import React, {useCallback, useEffect, useState} from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col,Container, Row , Navbar, Offcanvas, CloseButton } from "react-bootstrap";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import {useReactiveVar} from '@apollo/client';
import { currentBranchIdVar, userIdVar, currentCompanyIdVar, currentUserRoleVar} from '../../apollo/globalVar/state';
import { useUpdateUserMutation } from '../../apollo/globalVar/graphql/globalVar.hooks';
import AccessService from "../../../services/AccessService";
import SidebarHeader from "../Sidebar/SidebarHeader";
import SidebarSubheader from "../Sidebar/SidebarSubheader";
import SidebarBranchList from "../Sidebar/SidebarBranchList";
import SidebarMenu from "../Sidebar/SidebarMenu";
import { refetchUserDataMenu } from "../../apollo/apollo-client";
import SocketConnection from "../../../../common/src/services/SocketConnection";
import {LoadingStatus} from "@me-team/common/src/components/LoadingStatus/LoadingStatus";
import {EntityType, UserRole} from "@me-team/common/src/utils/enums";
import { branchesLength, isRefetchBranches } from '../../../main/branches/state';
import { useGetEmployeesQuery } from '../../../main/employeeList/graphql/employeeList.hooks';
import { useGetBranchesQuery } from '../../../main/branches/graphql/branches.hooks';
import { useGetCompanyModulesQuery } from '../../../main/company/graphql/company.hooks';
import { JobContainerProxy, ModuleProxy } from '../../../main/graphql/types';
import { useGetJobsQuery } from '../../../main/jobs/graphql/jobs.hooks';
import PaymentExpired from "@me-team/common/src/components/PaymentExpired/PaymentExpired";
import {Debounce} from "@me-team/common/src/services/Debounce";
import '../../../services/joyride';


export const App = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [updateUser] = useUpdateUserMutation();
    const basePath = process.env.REACT_APP_POS_URL;
    const [isShowNavbar, setShowNavbar] = useState<boolean>(false);
    const isRefetch = useReactiveVar(isRefetchBranches);
    const accessService = AccessService.getInstance().isPremium; // Працює раз через раз, хто писав хай гляне
    const userRole = useReactiveVar(currentUserRoleVar);
    const { data: userData, loading: loadingUserData } = useGetEmployeesQuery({
        variables: { page: 1, itemsPerPage: 8 },
        context: {
            errorType: 'global',
        },
    });
    const company = userData?.user?.company
    const role = userData?.user?.role?.id
    if (company && role) {
        AccessService.getInstance().init(company?.paidFeatures)
        AccessService.getInstance().setUserRole(role);
    }
    const { data, loading, refetch } = useGetBranchesQuery({
        variables: {
            itemsPerPage: 30,
            page: 1,
            branchTypeId: null,
            name: null,
        },
        context: {
            errorType: 'global',
        },
        onCompleted: (data) => {
            const filteredBranches = data?.user?.company?.branchesPaginated?.branches?.filter(branch => !branch.deleted);
            branchesLength(filteredBranches?.length);
        },
    });

    const { data: modules } = useGetCompanyModulesQuery({
        context: {
            errorType: 'global',
            },
        }
    );
    const hasWarehouseModule = modules?.user?.company?.modules?.some((module: ModuleProxy) => module.name === "Warehouses");

    useEffect(() => {
        refetch();
        refetchUserDataMenu();
        isRefetchBranches(false);
    }, [isRefetch]);


    const branches = data?.user?.company?.branchesPaginated?.branches || [];
    const activeBranches = branches?.filter(branch => !branch.deleted) || [];
    const companyMain = data?.user?.company;
    const userId = useReactiveVar(userIdVar);
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
    const isAdminRole = +userRole === UserRole.ADMINISTRATOR;
    const [jobList, setJobList] = useState<JobContainerProxy>(null);
    const [isLoadingStatusOpen, setIsLoadingStatusOpen] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(() => {
        const storedIsVisible = localStorage.getItem('isVisible');
        return storedIsVisible !== null ? storedIsVisible === 'true' : true;
    });

    const {refetch : refetchJobs} = useGetJobsQuery({ variables : {companyId: parseInt(currentCompanyId)},
        skip : !currentCompanyId,
        onCompleted: (data) => {
            setJobList(data.job);
        }
    })

    useEffect(() => {
        localStorage.setItem('isVisible', isVisible.toString());
    }, [isVisible]);

    const debouncedRefetchJobs = useCallback(
        Debounce(() => {
            refetchJobs().then((data) => {
                setJobList(data.data.job);
                setIsVisible(true);
                setIsLoadingStatusOpen(true);
            });
        }, 500),
        [refetchJobs]
    );


    useEffect(() => {
        if (currentCompanyId) {
            new SocketConnection(`${process.env.REACT_APP_SOCKET}/?companyId=${currentCompanyId}`, (data: any) => {
                if (data.entity === EntityType.IMPORT) {
                    debouncedRefetchJobs()
                }
                if (data.entity === EntityType.EXPORT) {
                    debouncedRefetchJobs()
                }
            });
        }
    }, [currentCompanyId, debouncedRefetchJobs]);


    const accordionsData = [
        ...(
            hasWarehouseModule
                ? [{
                    title: t("Warehouse accounting"),
                    items: [
                        { label: t('Warehouses'), to: `warehouses` },
                        {
                            label: t('Products'),
                            to: `warehouse/product/${currentCompanyId}`
                        },
                        userRole !== 5 && { label: t('Add product'), to: `warehouse/${currentCompanyId}/new-product` },
                        {
                            label: t('Technological maps'),
                            to: `warehouse/technological-maps/${currentCompanyId}`
                        },
                        { label: t('Reports'), to: `${basePath}/warehouse/reports/branch/${currentBranchId}` },
                        { label: t('Product categories'), to: `warehouse/product-categories/${currentCompanyId}` },
                        {
                            label: t('Technological map categories'),
                            to: `warehouse/technological-map-categories/${currentCompanyId}` }
                    ],
                    icon: "bi bi-shop-window"
                }]
                : []
        ),
        {
            title: t("Personnel management"),
            items: [
                { label: t('Employees'), to: `employees-list` },
                userRole !== 5 && {
                    label: t('Add an employee'),
                    to: `employees-list/add-employee`
                },
                { label: t('Positions'), to: `positions` },
                {
                    label: t('Work schedule'),
                    to: `work-schedule`
                },
                { label: t('Reviews'), to: `booking/review/company/${currentCompanyId}` },
                {label: t('Salary'), to: `personnel/${currentCompanyId}/salary`},
                !isEmployeeRole && {label: t('History of Payments'), to: `personnel/${currentCompanyId}/payment-history`}
            ],
            icon: "bi-layout-text-window-reverse"
        },
        {
            title: t('Services'),
            items: [
                { label: t('Service list'), to: `services/service/${currentCompanyId}/service-list` },
                userRole !== 5 && { label: t('Add a service'), to: `services/service/${currentCompanyId}/new` },
                { label: t('Service Categories'), to: 'service-categories' },
            ],
            icon: "bi-clipboard"
        },
        {
            title: t('Logbook'),
            items: [
                { label: t('Logbook'), to: `logbook/journal/${currentBranchId}` },
                { label: t('Add record'), to: 'logbook/add-record' },
                { label: t('Booking Statistic'), to: `${basePath}/booking/statistic/branch/${currentBranchId}` }
            ],
            icon: "bi-journals"
        },
        {
            title: t('Clients'),
            items: [
                { label: t('List of Clients'), to: `clients/${currentCompanyId}/list` },
                userRole !== 5 && { label: t('Add client'), to: `clients/${currentCompanyId}/new` },
                userRole !== 5 && { label: t('History of visits'), to: 'clients-history-visit' }
            ],
            icon: "bi-people"
        },
        {
            title: t('Online Booking'),
            items: [
                { label: t('Online Booking Config'), to: `booking/company/${currentCompanyId}/config` }
            ],
            icon: "bi-calendar4-event"
        },
        ...(
            !(userRole === 5 || isAdminRole)
                ? [{
                    title: t('Management of Tariffs'),
                    items: [
                        { label: t('Tariff Plans'), to: `payment/company/${currentCompanyId}/tariff` },
                        { label: t('Modules'), to: `${basePath}/payment/module/company/${currentCompanyId}` },
                        { label: t('Payment History'), to: `payment/history/company/${currentCompanyId}` },
                    ],
                    icon: "bi-card-checklist"
                }]
                : []
        ),
    ];

    const handleChangeBranch = async (branchId: number) => {
        try {
            await updateUser({
                variables: {
                    companyId: +currentCompanyId,
                    userId: +userId,
                    input: {
                        currentBranch: branchId
                    }
                }
            });
            currentBranchIdVar(branchId);
            navigate(`/branches/main/branch/${branchId}`);
        } catch (e) {
            console.error('Error changing branch:', e);
        }
    };

    const isRegistrationOrCreateOwner = location.pathname === "/registration" || location.pathname === "/create-owner";
    const hasAbilityToCreateBranch = userRole !== 5 && (accessService || activeBranches.length < 2);

    const handleCloseNavbar = () => setShowNavbar(false);
    const handleShowNavbar = () => setShowNavbar(true);

    if (loading || loadingUserData) return <></>

    const onCloseLoadingImportHandler = () => {
        const newValue = !isVisible;
        setIsVisible(newValue);
        localStorage.setItem('isVisible', newValue.toString());
    }

    return (
            <div className='pt-4'>
                <Row className='gx-0'>
                    { userId ? (
                        <Col xs={12} md={4} lg={3} xl={2} className='px-3'>
                            <div className="d-md-none">
                                <Navbar fixed="top" expand={false} className="bg-white position-fixed border-bottom">
                                    <Container fluid>
                                        {isRegistrationOrCreateOwner ? (
                                            <>
                                                <Navbar.Toggle className="border-0 px-1" onClick={handleShowNavbar}>
                                                    <i className="bi bi-list fs-3"></i>
                                                </Navbar.Toggle>
                                                <Navbar.Brand href="#" className="nav-item d-flex align-items-center flex-grow-1 justify-content-center py-0">
                                                    <SidebarHeader isMobile />
                                                </Navbar.Brand>
                                            </>
                                        ) : (
                                            <>
                                                <Navbar.Toggle className="border-0 px-1" onClick={handleShowNavbar}>
                                                    <i className="bi bi-list fs-3"></i>
                                                </Navbar.Toggle>
                                                <Navbar.Brand href="#" className="nav-item d-flex align-items-center flex-grow-1 justify-content-center py-0">
                                                    <SidebarSubheader company={companyMain} isMobile />
                                                </Navbar.Brand>
                                                <LanguageDropdown isMobile/>
                                            </>
                                        )}

                                        <Offcanvas show={isShowNavbar} onHide={handleCloseNavbar}>
                                            <Offcanvas.Header onHide className="border-bottom py-2">
                                                <CloseButton aria-label="Hide" onClick={handleCloseNavbar} />
                                                <Offcanvas.Title className="d-flex align-items-center flex-grow-1 justify-content-center">
                                                    {isRegistrationOrCreateOwner ? <SidebarHeader isMobile /> : <SidebarSubheader company={companyMain} handleCloseNavbar={handleCloseNavbar} isMobile />}
                                                </Offcanvas.Title>
                                                {!isRegistrationOrCreateOwner && <LanguageDropdown handleCloseNavbar={handleCloseNavbar} isMobile/>}
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>
                                                {hasAbilityToCreateBranch && (
                                                    <div className="nav-item-menu rounded-1 bg-white py-0 mb-2">
                                                        <Link className={`nav-link-menu text-primary ${isRegistrationOrCreateOwner ? "pe-none opacity-25" : ""}`} to={'create-branch'} onClick={handleCloseNavbar}><i
                                                            className='bi bi-plus-lg me-2'></i>{t("Add branch")}</Link>
                                                    </div>
                                                )}
                                                {!isRegistrationOrCreateOwner && <SidebarBranchList branches={branches} handleCloseNavbar={handleCloseNavbar} handleChangeBranch={handleChangeBranch}/>}
                                                <SidebarMenu accordionsData={accordionsData} handleCloseNavbar={handleCloseNavbar} isRegistrationOrCreateOwner={isRegistrationOrCreateOwner}/>
                                                <PaymentExpired/>
                                            </Offcanvas.Body>
                                        </Offcanvas>
                                    </Container>
                                </Navbar>
                            </div>
                            <div className="d-none d-md-block menu-container">
                                <SidebarHeader />
                                {!isRegistrationOrCreateOwner && (
                                    <div className="d-flex justify-content-between mb-3 pe-3">
                                        <SidebarSubheader company={companyMain} />
                                    </div>
                                )}
                                {hasAbilityToCreateBranch && (
                                    <div className="nav-item-menu rounded-1 bg-white py-1">
                                        <Link className={`nav-link-menu text-primary ${isRegistrationOrCreateOwner ? "pe-none opacity-25" : ""}`} to={'create-branch'}><i
                                            className='bi bi-plus-lg me-2'></i>{t("Add branch")}</Link>
                                    </div>
                                )}
                                {!isRegistrationOrCreateOwner && (
                                    <SidebarBranchList branches={branches} handleChangeBranch={handleChangeBranch}/>
                                )}
                                <SidebarMenu accordionsData={accordionsData} isRegistrationOrCreateOwner={isRegistrationOrCreateOwner}/>

                                <PaymentExpired/>
                            </div>
                        </Col>
                    ) : null }
                    <Col xs={12} md={8} lg={9} xl={10} className='pt-5 pt-md-0 mx-auto'>
                        <Container fluid className="px-3 px-xxl-1">
                            <Col xs={12} xl={10} className="offset-xl-1">
                                <Outlet />
                            </Col>
                        </Container>
                    </Col>
                    {jobList && (jobList.importJobs.length !== 0 || jobList.exportJobs.length !== 0) &&
                        <div className={"position-fixed bottom-0 end-0 loading-wrapper"}>
                            <LoadingStatus jobList={jobList} isOpen={isLoadingStatusOpen} isVisible={isVisible} onClose={onCloseLoadingImportHandler}/>
                        </div>
                    }
                </Row>
            </div>
    );
};

