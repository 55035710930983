import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {
  BadgeAndButtonForArchive,
  ModalDelete,
  Pagination,
  RenderEmptyList,
  Spinner,
  TooltipCustom
} from '@services/ui-components';
import ErrorService from '../../../services/ErrorService';
import {Button, Form} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import {ActionUpdateType, UserRole} from '../../../utils/enums';
import RenderProductCategoryName from '../../Products/ProductsList/RenderProductCategoryName';
import {calculateTechMapConnectServiceCount, getProductCountByTechMap} from '../constants';
import UpdateRecoveryTechMap from '../UpdateRecoveryTechMap/UpdateRecoveryTechMap';
import {TechnologicalMapProxy} from '@me-team/host/main/graphql/types';
import {
  useDeleteTechMapsMutation,
  useGetTechnologicalMapsLazyQuery,
  useGetTechnologicalMapsQuery
} from '@me-team/host/main/technologicalMaps/graphql/technologicalMaps.hooks';

interface TechnologicalMapsTableProps {
  filters: {
    search: string,
    categoryId: number
  };
  showCreateTechMapModal: boolean;
  handleCreateTechMap: () => void;
  setRefetchTechMaps: (refetchTechMaps: any) => void;
}

const TechnologicalMapsTable: React.FC<TechnologicalMapsTableProps> = ({
                                                                         filters,
                                                                         showCreateTechMapModal,
                                                                         handleCreateTechMap,
                                                                         setRefetchTechMaps
                                                                       }) => {
  const {t} = useTranslation();
  const basePath = process.env.REACT_APP_POS_URL;
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const parentCheckboxRef = useRef<HTMLInputElement>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [showUpdateRecoveryTechMapModal, setShowUpdateRecoveryTechMapModal] = useState(false);
  const [currentAction, setCurrentAction] = useState<ActionUpdateType | null>(null);
  const [currentUpdateTechMap, setCurrentUpdateTechMap] = useState(null)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [parentChecked, setParentChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [markedForDeletionArray, setMarkedForDeletionArray] = useState<number[]>([]);
  const [showCannotDelete, setShowCannotDelete] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [arrDeleteTechMapId, setArrDeleteTechMapId] = useState<number[]>([]);
  const [techMapCounterByAvailability, setTechMapCounterByAvailability] = useState({
    unavailableCount: 0,
    availableCount: 0,
    totalCount: 0,
  })

  const hasDeleteTechMapId = arrDeleteTechMapId?.length > 0

  const variantTextTitle = techMapCounterByAvailability.unavailableCount > 1 ?
    t('Deletion of Technological Maps')
    :
    t('Deletion of a Technological Map')

  const modalDeleteBody = techMapCounterByAvailability.availableCount > 0
    ?
    t('multiple_delete_techMaps_message', {
      unavailableCount: techMapCounterByAvailability.unavailableCount,
      availableCount: techMapCounterByAvailability.availableCount,
      totalCount: techMapCounterByAvailability.totalCount,
    })
    : techMapCounterByAvailability.unavailableCount > 1 ?
      t('confirmDeleteMultipleTechMapsText', {
        totalCount: techMapCounterByAvailability.totalCount,
      })
      :
      t('Are you sure you want to delete this technological map?')

  const toggleUpdateTechMapModal = () => setShowUpdateRecoveryTechMapModal(prevState => !prevState);

  const handleOpenEditTechMap = (action: ActionUpdateType, techMap: TechnologicalMapProxy) => {
    if (action && techMap) {
      setCurrentAction(action);
      setCurrentUpdateTechMap(techMap);
      toggleUpdateTechMapModal()
    }
  };

  const handleOpenRestoreTechMap = (action: ActionUpdateType, techMap: TechnologicalMapProxy) => {
    setCurrentAction(action);
    setCurrentUpdateTechMap(techMap);
    toggleUpdateTechMapModal()
  };

  const isFilterNotEmpty = Object.values(filters).some(value => value !== '' && value !== null)

  const resetCurrentPage = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    resetCurrentPage();
  }, [filters?.search, filters?.categoryId]);

  const {
    data: techMapsData,
    loading: techMapsLoading,
    refetch: refetchTechMaps
  } = useGetTechnologicalMapsQuery({
    variables: {
      page: currentPage,
      itemsPerPage: itemsPerPage,
      search: filters?.search ? filters?.search : null,
      categoryId: filters?.categoryId ? filters?.categoryId : null,
      pageTechMapProducts: 1,
      itemsPerPageTechMapProducts: 1000,
    },
    fetchPolicy: 'network-only',
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  useEffect(() => {
    if (setRefetchTechMaps) {
      setRefetchTechMaps(refetchTechMaps);
    }
  }, [refetchTechMaps, setRefetchTechMaps]);

  const [getTechMap, {loading, data: oneTechMapData}] = useGetTechnologicalMapsLazyQuery();

  const [deleteTechMaps] = useDeleteTechMapsMutation({
    refetchQueries: ['GetTechMap'],
    awaitRefetchQueries: true,
    onCompleted: async (data) => {
      if (data) {
        setShowDeleteConfirmation(false);
        setMarkedForDeletionArray([]);
        setArrDeleteTechMapId([]);
      }
      await refetchTechMaps();
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    },
  });

  const handleConfirmationClose = () => {
    setArrDeleteTechMapId([]);
    setShowDeleteConfirmation(false);
    setTechMapCounterByAvailability({
      unavailableCount: 0,
      availableCount: 0,
      totalCount: 0
    });
  }
  const handleCannotDeleteClose = () => {
    setArrDeleteTechMapId([]);
    setShowCannotDelete(false);
    setTechMapCounterByAvailability({
      unavailableCount: 0,
      availableCount: 0,
      totalCount: 0
    });
  }

  const handleNavigateToServices = () => {
    setTechMapCounterByAvailability({
      unavailableCount: 0,
      availableCount: 0,
      totalCount: 0
    });
    setShowCannotDelete(false);
    if (arrDeleteTechMapId[0]) {
      navigate(`/warehouse/technological-map/${currentCompanyId}/${arrDeleteTechMapId[0]}/services`)
    }
    setArrDeleteTechMapId([]);
  }

  const handleShowModalDelete = async (id: number) => {
    setMarkedForDeletionArray([]);
    setArrDeleteTechMapId([id]);
    const {data} = await getTechMap({
      variables: {
        techMapId: id,
        page: 1,
        itemsPerPage: 1000
      },
    });

    const isConnectServices = data && !!data?.user?.company?.technologicalMaps?.technologicalMaps[0]?.technologicalMapServiceRelations?.length

    if (isConnectServices) {
      setShowCannotDelete(true);
    } else {
      setShowDeleteConfirmation(true);
    }
  };

  const handleShowModalMultipleDelete = async () => {
    const techMapDataArray = await Promise.all(
      markedForDeletionArray?.map(async (id) => {
        const {data} = await getTechMap({
          variables: {
            techMapId: id,
            page: 1,
            itemsPerPage: 1000
          },
          context: {
            errorType: 'local'
          },
          onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
          },
        });
        return data;
      })
    );

    const unavailableCount = techMapDataArray?.filter(techMapData => techMapData?.user?.company?.technologicalMaps?.technologicalMaps[0]?.technologicalMapServiceRelations?.length === 0).length;

    const availableCount = markedForDeletionArray.length - unavailableCount;

    setTechMapCounterByAvailability({
      unavailableCount: unavailableCount,
      availableCount: availableCount,
      totalCount: markedForDeletionArray.length
    });
    if (unavailableCount === 0) {
      setShowCannotDelete(true);
    } else {
      setShowDeleteConfirmation(true);
    }
  };

  const handleDelete = async () => {
    const techMapIdsToDelete = arrDeleteTechMapId.length > 0 ? arrDeleteTechMapId : markedForDeletionArray;
    await deleteTechMaps({
      variables: {techMapIds: techMapIdsToDelete && techMapIdsToDelete},
      context: {
        errorType: 'local'
      }
    });
  };

  const company = techMapsData?.user?.company
  const techMaps: TechnologicalMapProxy[] = company?.technologicalMaps?.technologicalMaps
  const pageCount = parseInt(company?.technologicalMaps?.pageCount || '0');
  const totalItems = company?.technologicalMaps?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

  useEffect(() => {
    if (markedForDeletionArray.length === 0) {
      setParentChecked(false);
      setIndeterminate(false);
    } else if (markedForDeletionArray.length === techMaps?.length) {
      setParentChecked(true);
      setIndeterminate(false);
    } else {
      setParentChecked(false);
      setIndeterminate(true);
    }

    if (parentCheckboxRef.current) {
      parentCheckboxRef.current.indeterminate = indeterminate;
    }
  }, [markedForDeletionArray, techMaps?.length, indeterminate]);

  const handleChildCheckboxChange = (id: number) => {
    setMarkedForDeletionArray((prevMarkedForDeletionArray) =>
      prevMarkedForDeletionArray.includes(id)
        ? prevMarkedForDeletionArray.filter((markedId) => markedId !== id)
        : [...prevMarkedForDeletionArray, id]
    );
  };

  const handleParentCheckboxChange = () => {
    if (indeterminate) {
      setMarkedForDeletionArray([]);
      setParentChecked(false);
    } else if (parentChecked) {
      setMarkedForDeletionArray([]);
    } else {
      setMarkedForDeletionArray(techMaps?.filter((techMap) => !techMap.deleted).map((techMap) => techMap.id) || []);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (techMapsLoading) return <Spinner/>;

  return (
    <>
      <div className='col-12 table-responsive scrollbar position-relative'>
        {
          !!techMaps?.length ? (
            <table className='table table-borderless'>
              <thead>
              <tr className='fs-7'>
                {
                  !isEmployeeRole ?
                    <th className='py-2 col-1 ps-4 pe-2 align-middle' style={{minWidth: '106px'}}>
                      <div className='d-flex align-items-center gap-3'>
                        <Form.Check
                          type="checkbox"
                          ref={parentCheckboxRef}
                          checked={parentChecked}
                          onChange={handleParentCheckboxChange}
                        />
                        {markedForDeletionArray.length > 0 ?
                          <TooltipCustom placement='right' text={t('Delete Technological Map(s)')}>
                            <Button
                              variant='outline-danger'
                              className='border-0 fs-16 p-0'
                              onClick={handleShowModalMultipleDelete}
                            >
                              <i className="bi bi-trash3 icon-multi-delete"></i>
                            </Button>
                          </TooltipCustom>
                          : null
                        }
                      </div>
                    </th>
                    : null
                }
                <th className='py-3 col-3 px-2 align-middle line-height-140'>
                  {t('ProductsList.Name')}
                </th>
                <th className='py-3 px-2 col-2 align-middle line-height-140'>
                  {t('ProductsList.Category')}
                </th>
                <th className='py-3 px-2 col-3 align-middle line-height-140'>
                  {t('Description')}
                </th>
                <th className='py-1 px-2 col-1 align-middle line-height-140'>
                  <span>{t('Related Services')}</span>
                </th>
                <th className='py-1 px-2 col-1 align-middle line-height-140'>
                  {t('Related Products')}
                </th>
                <th className='py-3 px-2 pe-0 col-1 align-middle'></th>
              </tr>
              </thead>
              <tbody>
                {techMaps.map((techMap) => {
                  const isArchive = techMap?.deleted;
                  const colorForArchive = isArchive ? 'text-secondary' : '';
                  return (
                    <tr
                      key={techMap?.id}
                      className='border-bottom'
                    >
                      {!isEmployeeRole ?
                        <td className='align-middle ps-4 py-3 pe-2 line-height-140'>
                          <Form.Check
                            type="checkbox"
                            checked={markedForDeletionArray.includes(techMap.id)}
                            onChange={() => handleChildCheckboxChange(techMap.id)}
                            disabled={isArchive}
                          />
                        </td>
                        : null
                      }
                      <td className='align-middle py-3 px-2'>
                        {
                          !isArchive ?
                            <Button
                              variant='link'
                              onClick={() => handleOpenEditTechMap(ActionUpdateType.Edit, techMap)}
                              className={`text-decoration-none text-primary p-0 text-start line-height-140`}
                            >
                              {techMap?.name}
                            </Button>
                            :
                            <span className={`text-decoration-none text-secondary line-height-140`}>
                              {techMap?.name}
                            </span>
                        }
                      </td>
                      <td className='align-middle py-3 px-2 line-height-140'>
                        <RenderProductCategoryName
                          category={techMap?.technologicalMapNewCategory}
                          isArchive={isArchive}
                        />
                      </td>
                      <td className='align-middle py-3 px-2 line-height-140'>
                        <span className={colorForArchive}>
                          {techMap?.description ? techMap?.description : '—'}
                        </span>
                      </td>
                      <td className='align-middle py-3 px-2 line-height-140'>
                        {
                          !isArchive && !isEmployeeRole ?
                            <Link
                              to={`/warehouse/technological-map/${currentCompanyId}/${techMap?.id}/services`}
                              className={`text-decoration-none text-primary`}
                            >
                              {calculateTechMapConnectServiceCount(techMap?.technologicalMapServiceRelations)}
                            </Link>
                            :
                            <span className={`text-decoration-none ${isArchive ? 'text-secondary' : 'text-dark'}`}>
                              {calculateTechMapConnectServiceCount(techMap?.technologicalMapServiceRelations)}
                            </span>
                        }
                      </td>
                      <td className='align-middle py-3 px-2 line-height-140'>
                        {
                          !isArchive ?
                            <Link
                              to={`/warehouse/technological-map/${currentCompanyId}/${techMap?.id}/products`}
                              className={`text-decoration-none text-primary`}
                            >
                              {getProductCountByTechMap(techMap?.techMapProducts?.techMapProducts?.length)}
                            </Link>
                            :
                            <span className={`text-decoration-none text-secondary`}>
                              {getProductCountByTechMap(techMap?.techMapProducts?.techMapProducts?.length)}
                            </span>
                        }
                      </td>

                      <td className='align-middle ps-2 pe-0 py-3'>
                        <div className='d-flex align-items-center justify-content-end gap-2'>
                          {
                            !isArchive
                              ?
                              <>
                                {!isEmployeeRole ?
                                  <TooltipCustom placement='bottom' text={t('ProductsList.Edit product')}>
                                    <Button
                                      variant='outline-secondary'
                                      className='btn-square d-flex align-items-center justify-content-center p-1'
                                      style={{width: '40px', height: '40px'}}
                                      onClick={() => handleOpenEditTechMap(ActionUpdateType.Edit, techMap)}
                                    >
                                      <i className='bi bi-pencil fs-16 text-dark'></i>
                                    </Button>
                                  </TooltipCustom>
                                  : null
                                }
                                <TooltipCustom placement='bottom' text={t('Related services')}>
                                  <Button
                                    variant='outline-secondary'
                                    className='btn-square d-flex align-items-center justify-content-center p-1'
                                    style={{width: '40px', height: '40px'}}
                                    onClick={() => navigate(`/warehouse/technological-map/${currentCompanyId}/${techMap?.id}/services`)}
                                  >
                                    <i className='bi bi-bookmark fs-16 text-dark'></i>
                                  </Button>
                                </TooltipCustom>
                                <TooltipCustom placement='bottom' text={t('Technological map products')}>
                                  <Button
                                    variant='outline-secondary'
                                    className='btn-square d-flex align-items-center justify-content-center p-1'
                                    style={{width: '40px', height: '40px'}}
                                    onClick={() => navigate(`/warehouse/technological-map/${currentCompanyId}/${techMap?.id}/products`)}
                                  >
                                    <i className='bi bi-basket fs-16 text-dark'></i>
                                  </Button>
                                </TooltipCustom>
                                {!isEmployeeRole
                                  ?
                                  <TooltipCustom placement='bottom'
                                                 text={t('Delete Technological Map')}>
                                    <Button
                                      variant='outline-secondary'
                                      className='btn-square d-flex align-items-center justify-content-center p-1'
                                      style={{width: '40px', height: '40px'}}
                                      onClick={() => handleShowModalDelete(techMap?.id)}
                                    >
                                      <i className='bi bi-trash3 fs-16 text-dark'></i>
                                    </Button>
                                  </TooltipCustom>
                                  : null
                                }
                              </>
                              :
                              <BadgeAndButtonForArchive
                                hideButton={isEmployeeRole}
                                onButtonClick={() => handleOpenRestoreTechMap(ActionUpdateType.Restore, techMap)}
                              />
                          }
                        </div>
                      </td>
                    </tr>
                  )
              })}
              </tbody>
            </table>
          ) : null
        }
      </div>

      {!!totalItems ? (
          <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
            <Pagination
              t={t}
              pages={Number(pageCount)}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              firstItemIndex={firstItemIndex}
              lastItemIndex={lastItemIndex}
              totalItems={totalItems}
            />
          </div>
        ) :
        <RenderEmptyList
          isFilterNotEmpty={isFilterNotEmpty}
          visibilityBtn={!isFilterNotEmpty && !isEmployeeRole}
          emptyFilterTitle='No technological maps were found for the specified values in the filters or search.'
          emptyListTitle='No technological maps have been created yet. Add a technological map.'
          onClickButton={handleCreateTechMap}
          buttonName={
            <span>
              <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
              {t('Create New Technological Map')}
            </span>
          }
        />
      }

      <UpdateRecoveryTechMap
        show={showUpdateRecoveryTechMapModal}
        onClose={toggleUpdateTechMapModal}
        currentAction={currentAction}
        currentUpdateTechMap={currentUpdateTechMap}
        refetchTechMaps={refetchTechMaps}
      />

      <ModalDelete
        show={showDeleteConfirmation}
        handleClose={handleConfirmationClose}
        title={variantTextTitle}
        body={modalDeleteBody}
        buttons={[
          {
            text: t('Cancel'),
            onClick: handleConfirmationClose,
            variant: 'btn btn-outline-violet border-primary text-primary fw-normal',
            className: 'd-flex justify-content-center align-items-center w-100',
          },
          {
            text: t('delete'),
            onClick: handleDelete,
            variant: 'primary',
            className: 'w-100',
          }
        ]}
      />
      <ModalDelete
        show={showCannotDelete}
        handleClose={handleCannotDeleteClose}
        title={t('Deleting a Technology Map')}
        variantTitle='text-danger'
        body={t(
          'This Technological Map is connected to services. Please remove the connections before deleting the map.'
        )}
        buttons={hasDeleteTechMapId ?
          [
            {
              text: t('Cancel'),
              onClick: handleCannotDeleteClose,
              variant: 'outline-danger',
              className: 'w-100'
            },
            {
              text: t('To Services'),
              onClick: handleNavigateToServices,
              variant: 'danger',
              className: 'w-100'
            }
          ]
            :
          [
            {
              text: t('okBtn'),
              onClick: handleCannotDeleteClose,
              variant: 'danger',
              className: 'w-100',
            }
          ]
        }
      />

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default TechnologicalMapsTable;