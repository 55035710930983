import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import React, { ReactElement } from 'react';

type PlacementType = 'top' | 'right' | 'bottom' | 'left';

interface ToolTipProps {
  text: string | ReactElement;
  placement: PlacementType;
  children: ReactElement;
}

const TooltipCustom: React.FC<ToolTipProps> = ({
                                                 text,
                                                 placement,
                                                 children,
                                               }) => {
  const renderTooltip = (text:string | ReactElement) => <Tooltip
    id='tooltip'
    className='pointer-events-none position-fixed'
    >
    <span className='text-start d-inline-block'>{text}</span>
  </Tooltip>;
  return (
    <OverlayTrigger
      placement={placement}
      overlay={renderTooltip(text)}
      container={document.body}
    >
      {children}
    </OverlayTrigger>
  );
};

export default TooltipCustom;