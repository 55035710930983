import * as Yup from "yup";
import {StorageProductRelationProxy, StorageProxy} from '@me-team/host/main/graphql/types';


interface Option {
    value: string | number;
    label: string;
}

export interface WearhousesModalProps {
    show?: boolean;
    handleModal: () => void;
    branchId?: number;
    refetch: () => void;
    toggleShowToast?: (text: string) => void;
    warehouse?: StorageProxy
    storages?: Option[]
    storageProductRelationId?: number
    chosenProduct?: StorageProductRelationProxy;
    warehouses?: any;
    storageId?: number;
    destinationStorage?: number;
    writeOffReasons?: Option[]
    productsForDeleting?: number[]
    modalState?: {
        text?: string
        type?: string
    } | null


}

export const warehouseValidationSchema = (t: (key: string) => string) =>
    Yup.object().shape({
        name: Yup.string()
            .trim()
            .max(50, t('The maximum length of the Job Title field is 50 characters'))
            .required(t('Fill this field')),
        storageType: Yup.number().required(t('Fill this field')),
        description: Yup.string().max(400, t('The maximum length of the Description field is 400 characters'))
    });
export const validationSchemaAddProduct = (t: (key: string) => string) =>
    Yup.object().shape({
        amount: Yup.number()
            .max(50, t('The maximum value is 50'))
            .typeError(t('Amount must be a number'))
            .required(t('Fill this field')),
        unit: Yup.string().required(t('This field is required'))
    });
