import React, {useCallback, useEffect, useState} from 'react';
import {FiltersButton, InfoBanner, Pagination, Spinner, TableHeader} from "@services/ui-components";
import {Button, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useReactiveVar} from "@apollo/client";
import {currentUserRoleVar} from "@me-team/host/src/apollo/globalVar/state";
import {UserRole} from "../../../utils/enums";
import {useParams} from "react-router-dom";
import CategoryTable from "../../ui-components/CategoryTable/CategoryTable";
import {CreateEditModalComponent, RequestInputService} from "@me-pos/service-modal";
import i18next from "i18next";
import ToastComponent from "../../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../../hooks/useToast";
import CategoryTableInnerService from "../../../services/CategoryTableServices/CategoryTableInnerService";
import {CategoryVariables} from "../../../interfaces/interfaces";
import {SearchComponent} from "../../Services/SearchComponent/SearchComponent";
import {
    useCreateTechMapCategoryInTableMutation,
    useGetTechMapCategoriesQuery,
    useUpdateTechMapCategoryInTableMutation
} from "@me-team/host/main/techMapCategories/graphql/techMapCategories.hooks";
import {TechMapCategoryNewProxy} from "@me-team/host/main/graphql/types";

export const TechMapCategories: React.FC = () => {
    const {t: translate} = useTranslation();
    const {currentCompanyId} = useParams();
    const [hasAccess, setHasAccess] = useState<boolean>(null);
    const role = useReactiveVar(currentUserRoleVar);
    const [searchValue, setSearchValue] = useState<string>(null);
    const [showFilters, setShowFilters] = useState(false);
    const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] = useState<boolean>(false);
    const {showToast, toastText, toggleShowToast, setShowToast} = useToast();
    const [categoryListPerPage, setCategoryListPerPage] = useState<TechMapCategoryNewProxy[]>([]);
    const [categoryList, setCategoryList] = useState<TechMapCategoryNewProxy[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [totalItems, setTotalItems] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const {refetch: refetchCategoryListPerPage, loading: loadingProductPerPage} = useGetTechMapCategoriesQuery({
        variables: {
            id: null,
            itemsPerPage: itemsPerPage,
            serviceDeleted: false,
            categoryName: searchValue ? searchValue.toLowerCase() : null,
            page: currentPage
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setCategoryListPerPage(data.TechMapCategories.items);
            setPageCount(parseInt(data.TechMapCategories.pageCount || '0'));
            setTotalItems(data.TechMapCategories.totalCount || 0);
        }
    });

    const {refetch: refetchCategoryList, loading: loadingProductList} = useGetTechMapCategoriesQuery({
        variables: {id: null, itemsPerPage: 1000, serviceDeleted: false, categoryName: null, page: 1},
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setCategoryList(data.TechMapCategories.items);
        }
    });

    const refetchData = useCallback(async (testToShow: string) => {
        try {
            const categoryListResponse = await refetchCategoryList();
            setCategoryList(categoryListResponse.data.TechMapCategories.items);

            const categoryListPerPageResponse = await refetchCategoryListPerPage();
            setCategoryListPerPage(categoryListPerPageResponse.data.TechMapCategories.items);
            setPageCount(parseInt(categoryListPerPageResponse.data.TechMapCategories.pageCount || '0'));
            setTotalItems(categoryListPerPageResponse.data.TechMapCategories.totalCount || 0);
            toggleShowToast(testToShow);
        } catch (error) {
            console.error(error);
        }
    }, [refetchCategoryList, refetchCategoryListPerPage, toggleShowToast]);

    const [createCategory] = useCreateTechMapCategoryInTableMutation();
    const [updateCategory] = useUpdateTechMapCategoryInTableMutation();

    const handlePageChange = useCallback((pageNumber: number) => {
        setCurrentPage(pageNumber);
    }, []);

    useEffect(() => {
        if (role === UserRole.OWNER || role === UserRole.ADMINISTRATOR) {
            setHasAccess(true);
        } else {
            setHasAccess(false);
        }
    }, [role]);

    const HandleToggleFilters = useCallback(() => setShowFilters((prev) => !prev), []);

    const searchHandling = useCallback((currentValue: string) => {
        setSearchValue(currentValue || null);
    }, []);

    const openCreateCategoryModalHandler = useCallback(() => {
        setIsCreateCategoryModalOpen(true);
    }, []);

    const onCreateHandler = useCallback(async (categoryVariables: CategoryVariables) => {
        await createCategory({
            variables: {companyId: parseInt(currentCompanyId), input: categoryVariables.categoryInput},
            onCompleted: () => {
                refetchData(translate('Created'));
            }
        });
    }, [createCategory, currentCompanyId, refetchData, translate]);

    const onUpdateHandler = useCallback(async (categoryVariables: CategoryVariables, isRestored?: boolean) => {
        await updateCategory({
            variables: {id: categoryVariables.categoryId, input: categoryVariables.categoryInput},
            onCompleted: () => {
                refetchData(translate(isRestored ? 'Restored' : 'Saved'));
            }
        });
    }, [updateCategory, refetchData, translate]);

    const onDeleteHandler = useCallback(async (categoryVariables: CategoryVariables) => {
        await updateCategory({
            variables: {id: categoryVariables.categoryId, input: categoryVariables.categoryInput},
            onCompleted: () => {
                refetchData(translate('Deleted'));
            }
        });
    }, [updateCategory, refetchData, translate]);

    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    if (loadingProductList || loadingProductPerPage) return <Spinner/>;

    return (
        <div className='mb-9 px-2 px-lg-0'>
            <InfoBanner>
                <p className='fs-7 mb-0'>{translate('Create and edit technological map categories and subcategories.')}</p>
            </InfoBanner>
            <TableHeader
                title={translate('Technological Map Categories')}
                paths={[
                    {
                        path: `/warehouse/technological-map-categories/${currentCompanyId}`,
                        label: translate('Warehouse accounting')
                    },
                    {path: `/warehouse/technological-maps/${currentCompanyId}`, label: translate('Technological maps')},
                    {
                        path: `/warehouse/technological-map-categories/${currentCompanyId}`,
                        label: translate('Technological Map Categories')
                    },
                ]}
                buttons={
                    <Row
                        className='gx-1 w-100 mt-4 mt-lg-0  d-flex flex-lg-nowrap justify-content-lg-end gap-3'>
                        {
                            hasAccess &&
                            <Col md={12} lg={5} xl={4}>
                                <Button
                                    variant='primary'
                                    className='w-100 text-white mb-3 mb-lg-0 fw-bold text-truncate'
                                    onClick={openCreateCategoryModalHandler}
                                >
                                    <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                                    {translate('Add Category')}
                                </Button>
                            </Col>
                        }
                        <Col md={12} lg={3} xl={2} className='px-0'>
                            <FiltersButton filter={{searchValue}} showFilters={showFilters}
                                           onClick={HandleToggleFilters}
                                           classesText={`${hasAccess ? "d-none d-xxl-inline-block" : "d-inline-block"}`}/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
            >
                <SearchComponent value={searchValue} placeholder={translate("Search by category/subcategory name")}
                                 searchHandling={searchHandling}/>
            </TableHeader>

            {categoryListPerPage && categoryListPerPage.length !== 0 && <>
                <CategoryTable
                    hasAccess={hasAccess}
                    categoriesToShow={CategoryTableInnerService.TechMapCategoryTableInner(categoryListPerPage)}
                    categoryList={CategoryTableInnerService.TechMapCategoryTableInner(categoryList)}
                    onCreate={onCreateHandler}
                    onDelete={onDeleteHandler}
                    onUpdate={onUpdateHandler}
                    nameOfQtyColum={translate('Quantity of Technological maps')}
                    searchParamBool={!!searchValue}
                    uniqueInfo={{
                        delModalBtnName: translate('Technological Map Directory'),
                        delErrorWithCategoryServices:
                            translate('This category is associated with technological maps. Please edit the technological map linked to this category before deletion.'),
                        delModalRefToService : `/warehouse/technological-maps/${currentCompanyId}`
                    }}
                />
                <div className="d-flex justify-content-between align-items-center my-4">
                    <Pagination
                        t={translate}
                        pages={Number(pageCount)}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        firstItemIndex={firstItemIndex}
                        lastItemIndex={lastItemIndex}
                        totalItems={totalItems}
                    />
                </div>
            </>}

            {categoryListPerPage && categoryListPerPage.length === 0 &&
                <p className={"mb-0 mt-5 pt-5 text-center fw-bold fs-3"}>{translate("NoDataFoundForTheGivenValues")}</p>
            }

            {isCreateCategoryModalOpen &&
                <CreateEditModalComponent
                    i18n={i18next}
                    categoryId={null}
                    subcategoryId={null}
                    isOpen={isCreateCategoryModalOpen}
                    categoryInput={RequestInputService.createServiceCategoryInput(null, null, [])}
                    categoryList={categoryList}
                    isCreateAction={isCreateCategoryModalOpen}
                    onClose={() => {
                        setIsCreateCategoryModalOpen(false);
                    }}
                    onSubmit={() => {
                    }}
                    onSubmitCreate={async (inputVariables) => {
                        await createCategory({
                            variables: {companyId: parseInt(currentCompanyId), input: inputVariables.input},
                            onCompleted: () => {
                                setIsCreateCategoryModalOpen(false);
                                refetchData(translate('Created'));
                            }
                        });
                    }}
                    requestInputService={RequestInputService}
                    onSubmitUpdate={async () => {
                    }}
                    currentCategory={null}
                    categoryService={null}/>
            }

            {showToast && <ToastComponent show={showToast} setShow={setShowToast} text={toastText}/>}
        </div>
    );
}