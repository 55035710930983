import React, {useState} from 'react';
import {
  InfoBanner,
  Spinner,
  TableHeader
} from '@services/ui-components';
import {Button} from 'react-bootstrap';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import {UserRole} from '../../../utils/enums';
import {useGetOneTechMapQuery} from '@me-team/host/main/technologicalMaps/graphql/technologicalMaps.hooks';
import ErrorService from '../../../services/ErrorService';
import ProductsForTechMapTable from './ProductsForTechMapTable';

const ProductsForTechMap: React.FC = () => {
  const basePath = process.env.REACT_APP_POS_URL;
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const {techMapId} = useParams();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const {
    data: oneTechMapData,
    loading: oneTechMapLoading
  } = useGetOneTechMapQuery({
    variables: {
      techMapId: +techMapId,
    },
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });
  const techMapName: string = oneTechMapData?.user?.company?.technologicalMaps?.technologicalMaps?.[0].name

  if (oneTechMapLoading || !currentCompanyId) return <Spinner/>;

  return (
    <div className='mb-9 px-2 px-lg-0'>
      <InfoBanner>
        <p className='fs-7 mb-0'>
          {t('Add products to the card, specify quantity via')}
          <i className='bi bi-pencil text-dark px-1'></i>
          {t('“Edit”. Products will be written off after the related service.')}
        </p>
      </InfoBanner>
      <TableHeader
        title={`${t('Products')} ${techMapName}`}
        paths={[
          {
            path: `/warehouse/technological-map/${currentCompanyId}/${techMapId}/products`,
            label: t('Warehouse accounting')
          },
          {
            path: `/warehouse/technological-maps/${currentCompanyId}`, label: t('List of' +
              ' Technological Maps')
          },
          {
            path: `/warehouse/technological-map/${currentCompanyId}/${techMapId}/products`,
            label: `${t('Products')} ${techMapName}`
          },
        ]}
        colSize={9}
        btnColSize={3}
        buttons={
          <div
            className='w-100  d-flex justify-content-lg-end'>
            {
              !isEmployeeRole
                ?
                <Button
                  variant='primary'
                  className='text-white mb-3 mb-lg-0 '
                  //TODO изменить ссылку, когда будет готова страница добавление товаров в тех карту
                  onClick={() => window.location.href = `${basePath}/warehouse/technological-map/${currentCompanyId}/${techMapId}/product/add`}
                >
                  <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                  {t('Add Product to Technological Map')}
                </Button>
                : null
            }
          </div>
        }
        showFilters={false}
      >
      </TableHeader>
      <ProductsForTechMapTable/>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default ProductsForTechMap;