import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';

const ScrollToTopBtn: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    useEffect(() => {
      const toggleVisibility = () => {
        if (window.scrollY > 200) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };

      window.addEventListener('scroll', toggleVisibility);

      return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

  return (
    <>
      {isVisible ?
        <Button
          variant='violet'
          className='scrollToTopBtn p-0 position-fixed'
          onClick={scrollToTop}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18"
               fill="none">
            <path
              d="M8 18C8.62132 18 9.125 17.4963 9.125 16.875L9.125 3.84099L13.9545 8.6705C14.3938 9.10984 15.1062 9.10984 15.5455 8.6705C15.9848 8.23116 15.9848 7.51884 15.5455 7.0795L8.7955 0.329504C8.35616 -0.109835 7.64384 -0.109835 7.2045 0.329504L0.454505 7.0795C0.015165 7.51884 0.015165 8.23116 0.454505 8.6705C0.893845 9.10984 1.60616 9.10984 2.0455 8.6705L6.875 3.84099L6.875 16.875C6.875 17.4963 7.37868 18 8 18Z"
              fill="#5842BE">
            </path>
          </svg>
        </Button>
        : null
      }
    </>

  );
};

export default ScrollToTopBtn;