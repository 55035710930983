import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {SimpleModal} from "@services/ui-components/src/SimpleModal/SimpleModal";
import {useTranslation} from "react-i18next";
import {
    CategoryTableInterface,
    CategoryVariables, ChildCategoriesTableInterface,
    delModalSubcategoryContentType, UniqueCategoryTableInfo
} from "../../../../interfaces/interfaces";
import {CreateEditModalComponent, RequestInputService} from "@me-pos/service-modal";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import i18next from "i18next";
import {Link} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {currentCompanyIdVar} from "@me-team/host/src/apollo/globalVar/state";
import { ServiceCategoryNewInput} from '@me-team/host/main/graphql/types';
import {MoveModalComponent} from "../../../Services/MoveModalComponent/MoveModalComponent";
import CategoryValidationService from "../../../../services/CategoryTableServices/CategoryValidationService";

type SubcategoryComponent = {
    subcategory: ChildCategoriesTableInterface
    categoryList: CategoryTableInterface[]
    parentCategory : CategoryTableInterface
    hasAccess : boolean
    onCreate : (categoryVariables : CategoryVariables) => Promise<void>
    onUpdate: (categoryVariables: CategoryVariables, isRestored?: boolean) => Promise<void>
    onDelete : (categoryVariables : CategoryVariables) => Promise<void>
    uniqueInfo : UniqueCategoryTableInfo
}

const SubcategoryComponent: React.FC<SubcategoryComponent> = (props) => {
    const {t: translate} = useTranslation();
    const [isDelModalOpen, setIsDelModalOpen] = useState(false);
    const [isUpdateCategoryModalOpen, setIsUpdateCategoryModalOpen] = useState<boolean>(false)
    const [delModalContent, setDelModalContent] = useState<delModalSubcategoryContentType>(
        {
            title: '',
            description: '',
            withServiceInSubcat: false
        })
    const [isMoveModalOpen, setIsMoveModalOpen] = useState<boolean>(false);
    const [isCreateAction, setIsCreateAction] = useState<boolean>(false);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    const handleOpenDelModal = () => {
        CategoryValidationService.delSubcategoryValidator(props.subcategory, setDelModalContent, translate, props.uniqueInfo.delErrorWithCategoryServices)
        setIsDelModalOpen(true)
    }
    const handleCloseDelModal = () => {
        setIsDelModalOpen(false)
    }

    const deleteSubcategoryHandler = async () => {
        const input = RequestInputService.createServiceCategoryInput(props.subcategory.parentCategory.id, props.subcategory.id, props.categoryList)
        input.deleted = true;
        await props.onDelete({categoryId : props.subcategory.id, categoryInput : input}).finally(() => {
            setIsDelModalOpen(false);
            setIsMoveModalOpen(false);
        })
    }

    const onUpdateCategoryHandler = (isCreateAction : boolean) => {
        setIsCreateAction(isCreateAction);
        setIsUpdateCategoryModalOpen(true);
    }

    const onOpenMoveModal = () => {
        setIsMoveModalOpen(true)
    }

    const onCloseMoveModal = () => {
        setIsMoveModalOpen(false)
    }

    const moveSubcategoryHandler = async (inputForReq: ServiceCategoryNewInput) => {
        await props.onUpdate({categoryId : props.subcategory.id, categoryInput : inputForReq});
        onCloseMoveModal();
    }

    return (
        <>
            <Container className="table-category-width">
                <Row className={`gx-3 ms-3 ${props.subcategory.deleted && 'text-secondary'}`}>
                    <Col xs={3} className={`d-flex align-items-center ps-0 border-bottom ${props.hasAccess ? 'py-3' : 'py-4'}`}>
                        <span className={'ps-5 ms-2'}>{props.subcategory.name}</span>
                    </Col>
                    <Col xs={5} className={`d-flex align-items-center border-bottom ${props.hasAccess ? 'py-3' : 'py-4'}`}>
                        {props.subcategory.comment ? props.subcategory.comment : '—'}
                    </Col>
                    <Col xs={2} className={`d-flex align-items-center border-bottom ${props.hasAccess ? 'py-3' : 'py-4'}`}>
                        {props.subcategory.categoryServices.length !== 0 ?
                            <Link to={{
                                pathname: `/warehouse/product/${currentCompanyId}`,
                                search: `?id=${encodeURIComponent(props.subcategory.id)}`}}
                                  className={"text-decoration-none"}>{props.subcategory.categoryServices.length}</Link>
                            : 0}
                    </Col>
                    {props.hasAccess && <Col xs={2} className={`d-flex align-items-center justify-content-end border-bottom ${props.hasAccess ? 'py-3' : 'py-4'} pe-0`}>
                        {props.subcategory.deleted ?

                            <>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="tooltip-top">{translate('servicesPage.subcategoryTooltip')}</Tooltip>}
                                >
                                    <Button
                                        variant="secondary"
                                        className="px-3 py-2 disabled-btn fw-normal rounded-1"
                                    >
                                        {translate('servicesPage.archive')}
                                    </Button>
                                </OverlayTrigger>
                                {!props.parentCategory.deleted &&
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="tooltip-top">{translate('Restore')}</Tooltip>}
                                    >
                                        <Button className={"btn rounded border ms-2 text-dark bg-white rounded-1"} onClick={() => {onUpdateCategoryHandler(false)}}>
                                            <i className="bi bi-arrow-counterclockwise d-flex fs-normal"></i>
                                        </Button>
                                    </OverlayTrigger>


                                }
                            </>
                            :
                            <>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="tooltip-top">{translate('Move category')}</Tooltip>}
                                >
                                    <button className={"btn rounded border me-2"} onClick={onOpenMoveModal}>
                                        <i className="bi bi-box-arrow-right d-flex fs-normal"></i>
                                    </button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="tooltip-top">{translate('Edit category')}</Tooltip>}
                                >
                                    <button className={"btn rounded-1 border me-2"} onClick={() => {onUpdateCategoryHandler(false)}}>
                                        <i className="bi bi-pencil d-flex fs-normal"></i>
                                    </button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="tooltip-top">{translate('Delete category')}</Tooltip>}
                                >
                                    <button className={"btn rounded-1 border"} onClick={handleOpenDelModal}>
                                        <i className="bi bi-trash3 d-flex fs-normal"></i>
                                    </button>
                                </OverlayTrigger>
                            </>
                        }
                    </Col>}
                </Row>
            </Container>
            <SimpleModal isOpen={isDelModalOpen} onClose={handleCloseDelModal} title={delModalContent.title}
                         description={delModalContent.description}>
                <Container>
                    <Row>
                        {!delModalContent.withServiceInSubcat &&
                            <>
                                <Col xs={6} className={"ps-0"}>
                                    <Button variant="outline-dark" className={"w-100 fw-normal"}
                                            onClick={handleCloseDelModal}>
                                        {translate('servicesPage.deleteModal.cancelBtn')}
                                    </Button>
                                </Col>
                                <Col xs={6} className={"pe-0"}>
                                    <Button variant="primary" className={"w-100 fw-normal"}
                                            onClick={deleteSubcategoryHandler}>
                                        {translate('servicesPage.deleteModal.applyBtn')}
                                    </Button>
                                </Col>
                            </>
                        }
                        {delModalContent.withServiceInSubcat &&
                            <Col xs={12} className={"px-0"}>
                                <Link
                                    to={{
                                        pathname: `${props.uniqueInfo.delModalRefToService}`,
                                        search: `?id=${encodeURIComponent(props.subcategory.id)}`
                                    }}
                                    className={"btn btn-primary w-100 fw-normal"}
                                >
                                    {translate(`${props.uniqueInfo.delModalBtnName}`)}
                                </Link>
                            </Col>
                        }
                    </Row>
                </Container>
            </SimpleModal>
            {isUpdateCategoryModalOpen && <CreateEditModalComponent
                i18n={i18next}
                categoryId={props.subcategory.parentCategory.id}
                subcategoryId={props.subcategory.id}
                isOpen={isUpdateCategoryModalOpen}
                categoryInput={RequestInputService.createServiceCategoryInput(props.subcategory.parentCategory.id, props.subcategory.id, props.categoryList)}
                categoryList={props.categoryList}
                isCreateAction={isCreateAction}
                onClose={() => {
                    setIsUpdateCategoryModalOpen(false);
                }}
                onSubmit={() => {
                }}
                onSubmitCreate={async (inputVariables) => {
                    await props.onCreate({
                        categoryId: props.subcategory.id,
                        categoryInput: inputVariables
                    }).finally(() => {
                        setIsDelModalOpen(false);
                        setIsMoveModalOpen(false);
                    });

                }}
                onSubmitUpdate={async (inputVariables) => {
                    await props.onUpdate({
                        categoryId: props.subcategory.id,
                        categoryInput: inputVariables.input,
                    }, props.subcategory.deleted).finally(() => {
                        setIsDelModalOpen(false);
                        setIsMoveModalOpen(false);
                    });
                }}
                requestInputService={RequestInputService}
                currentCategory={null}
                categoryService={null}
            />}
            {isMoveModalOpen && <MoveModalComponent isMoveModalOpen={isMoveModalOpen} onCloseMoveModal={onCloseMoveModal}
                                                    moveCategoryHandler={moveSubcategoryHandler} currentCategory={props.subcategory}
                                                    categoryList={props.categoryList}/>}
        </>
    )
}

export default React.memo(SubcategoryComponent)
