import React, {useState} from 'react';
import { useParams, Link } from 'react-router-dom';
import { TableHeader, Spinner } from '@services/ui-components';
import { useTranslation } from 'react-i18next';
import { Col, Card, ListGroup, Button } from "react-bootstrap";
import { currentBranchIdVar, currentCompanyIdVar, currentUserRoleVar } from '@me-team/host/src/apollo/globalVar/state';
import { useReactiveVar } from "@apollo/client";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { getSections } from "./constans";
import { useGetBranchQuery } from '@me-team/host/main/branches/graphql/branches.hooks';
import { useGetCompanyModulesQuery } from '@me-team/host/main/company/graphql/company.hooks';
import { ModuleProxy } from '@me-team/host/main/graphql/types';
import ConnectModal from "../Joyride/modals/ConnectModal";

const MainMenuBeautySalon = () => {
    const {t} = useTranslation();
    const [openConnectModal, setOpenConnectModal] = useState<boolean>(false);
    const basePath = process.env.REACT_APP_POS_URL;
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const userRole = useReactiveVar(currentUserRoleVar);

    const { data: branchData, loading, error } = useGetBranchQuery({
        variables: { id: Number(currentBranchId) },
        context: {
            errorType: 'global',
        }
    });
    const { data: modules } = useGetCompanyModulesQuery({
            context: {
                errorType: 'global',
            },
        }
    );

    const hasWarehouseModule = modules?.user?.company?.modules?.some((module: ModuleProxy) => module.name === "Warehouses");

    const currentBranchName = branchData?.user?.company?.branchesPaginated?.branches[0]?.name;
    useDocumentTitle(currentBranchName);

    const sections = getSections({
        t,
        userRole,
        basePath,
        currentBranchId,
        currentCompanyId,
        hasWarehouseModule,
    });

    const sortedSections = sections.sort((a, b) => {
        if (a.hasWarehouseModule && !b.hasWarehouseModule) return -1;
        if (!a.hasWarehouseModule && b.hasWarehouseModule) return 1;
        return 0;
    })
    if (loading) return <Spinner/>;

    return (
        <>
            <TableHeader
                title={currentBranchName}
                paths={[{ path: "/branches", label: t("branches")},
                    { path: `/branches/main/branch/${currentBranchId}`, label: currentBranchName }
                ]}
                buttons={
                <Col className="d-flex justify-content-end">
                    <Button variant={"outline-primary"}
                            className="fw-normal px-4 connect-online-booking"
                            onClick={()=> setOpenConnectModal(true)} >
                        {t("How to connect Online Booking?")}
                    </Button>
                </Col>
                }
                colSize={6}
            />

            <div className="row justify-content mt-5">
                {sortedSections.map((section, index) => (
                    <Col key={index} lg={6} xxl={4} className="mb-4">
                        <Card className={`d-flex flex-column h-100 border rounded-3 p-4 ${!section.hasWarehouseModule && section.id === 7 ? "pe-none opacity-50" : "opacity-100"}`}
                        >
                            <Card.Header className="mb-4">
                                <h5 className="fs-4 mb-0">{section.title}</h5>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex justify-content-center mb-4">
                                    <img src={section.image} alt={section.title}/>
                                </div>
                                <ListGroup as="ul" className="list-grid list-unstyled mb-4">
                                    {section.items.filter(Boolean).map((item, index) => {
                                        return (<ListGroup.Item as="li" className="py-2">
                                            <Link key={index} to={item.to} className="text-violet-hover text-decoration-none">{item.label}</Link>
                                        </ListGroup.Item>)
                                    })}
                                </ListGroup>
                            </Card.Body>
                            {
                                !section.isHidden && (
                                    <Card.Footer className="bg-white border-top-0 mt-auto">
                                        <Link to={section.navigateTo} className="btn btn-outline-primary btn-menu-card w-100 fw-normal rounded-1">
                                            {section.linkLabel}
                                        </Link>
                                    </Card.Footer>
                                )
                            }
                        </Card>
                    </Col>
                ))}
            </div>
            <ConnectModal show={openConnectModal} setOpenConnectModal={setOpenConnectModal} />

        </>
    );
};

export default MainMenuBeautySalon;
